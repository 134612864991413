/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */
const data = {
    mother_trending_flower: {
        trending_flower_title: 'Top 10 Trending Flowers',
        trending_flower_description: '<span class="Description">Have you ever wondered what the most popular flower types are? Whether you have a favorite flower type, or if you stay on top of the hottest trends, here is a list of flowers by type that are currently peaking to help you with your <a href="/all-flower-arrangements">gifting research</a>! Our list of the best flowers is sure to have something special, whether you’re looking to send these flowers on their own, or in a beautiful mixed bouquet!!</span>',
    },
    mother_trending_flower_list: [
        {
            image_content: 'Rose Elegance™ Premium Long Stem Red Roses',
            title: 'Roses',
            description: '<p><a href="/roses">Roses</a> are a favorite flower year-round. With their timeless beauty, our delicate red roses are the perfect gift to let your special someone know how much they are loved.</p>',
            shop_link: '/florist-delivered-long-stem-red-roses-161776',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/161776sx.jpg?height=430&width=393',
        },
        {
            image_content: 'Hydrangea in Mosaic Planter',
            title: 'Hydrangeas',
            description: '<p>Fill your senses with the sweet fragrance and rich beauty of our blooming <a href="/purple-hydrangea-in-mosaic-planter-18116" title="Purple Hydrangea">hydrangea</a>. This lush plant comes in your choice of purple or white. You can add a matching tealight holder and candle for a unique gift set that will be sure to brighten someone’s day!</p>',
            shop_link: '/purple-hydrangea-in-mosaic-planter-18116',
            image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/catalog/18116lcnx.jpg?height=282&width=259',
        },
        {
            image_content: 'Bonfire Warmth Orchid',
            title: 'Orchids',
            description: '<p><a href="/orchids">Orchids</a> are an elegant flower, ideal for the style guru who is always on top of the latest trends! Available in a wide variety of colors, there&apos;s an orchid flower for every trendsetter!</p>',
            shop_link: '/plants-bonfire-warmth-orchid-157180',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/157180sx.jpg?height=430&width=393',
        },
        {
            image_content: 'Vibrant Blooms Bouquet',
            title: 'Daisies',
            description: '<p><a href="/daisies">Daisies</a> are the type of flower guaranteed to make you smile. This garden-inspired gift brings together bunches of color all in a beautiful bouquet! Send a refreshing boquet of daisies to say Happy Birthday, Happy Wednesday, or for any other reason!</p>',
            shop_link: '/vibrant-blooms-bouquet-107308',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/107308mgv9verx.jpg?height=430&width=393',
        },
        {
            image_content: 'Sophisticated White Calla Lily',
            title: 'Calla Lilies',
            description: '<p>The <a href="/callaliliesflower">Calla Lilies</a> are for the women who bring a touch of elegance to everything they do. These stunning lilies are available in a variety of colors and designs. Bring a smile to someone’s face with these beautiful bouquets!</p>',
            shop_link: '/white-calla-black-ceramic-101198',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/101198lx.jpg?height=430&width=393',
        },
        {
            image_content: 'Grand Gardenia',
            title: 'Gardenias',
            description: '<p>The <a href="/gardeniaplants">Gardenias</a> are the ideal simple and classic gift. These fragrant white flowers and glossy leaves are sure to be a hit! You can even enhance the gift with a gardenia-scented Yankee Candle®!</p>',
            shop_link: '/grand-gardenia-18053',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/18053lcx.jpg?height=430&width=393',
        },
        {
            image_content: 'Vibrant Summer Lily Bouquet',
            title: 'Lilies',
            description: '<p>These summer <a href="/vibrant-summer-lily-bouquet-92926">lilies</a> are sure to capture everything you love about your special someone. These fresh, fragrant blooms come in delicate pink, white, orange and yellow and are a delightful bouquet!</p>',
            shop_link: '/vibrant-summer-lily-bouquet-92926',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/92926mtv4ck10x.jpg?height=430&width=393',
        },
        {
            image_content: 'Fields of Europe™ Summer',
            title: 'Sunflowers',
            description: '<p>We couldn’t get through this list without adding <a href="/sunflowers">sunflowers</a>! Who can resist these bright yellow blooms that scream sunshine and happiness?</p>',
            shop_link: '/florist-delivered-fields-of-europe-summer-148682',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148682xlx.jpg?height=430&width=393',
        },
        {
            image_content: 'Happy Gerbera Daisies, 12-24 Stems',
            title: 'Gerbera Daisy',
            description: '<p>Yes, we know, we mentioned daisies already, but we felt that <a href="/happy-gerbera-daisy-bouquet-90618">Gerbera daisies</a> deserved their own spot on this list. Colorful Gerbera Daisies bring cheer for any occasion, and these fantastic flowers make the top 10 list of favorite flowers year-round. So, if you’re stumped on what to get, just know that you can’t go wrong with a colorful bouquet of Gerbera daisies.</p>',
            shop_link: '/happy-gerbera-daisy-bouquet-90618',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/90618mtv4x.jpg?height=430&width=393',
        },
        {
            image_content: 'Splendid & Sweet™',
            title: 'Carnations',
            description: '<p>Carnations</a> are an excellent gift that symbolize love and devotion. Send a bouquet with carnations to someone special in your life to brighten their day! You can never go wrong with these beautiful flowers.</p>',
            shop_link: '/florist-delivered-splendid-sweet-161275',
            image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/161275localartisanx.jpg?height=430&width=393',
        },
    ],
};

export default data;
