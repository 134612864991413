/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'Help Videos',
    subTitle: 'Check out these videos for helpful tips',
    list: [
        {
            name: 'What to Expect After Ordering Flowers Online',
            imageLink: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/help/vid-delivery.jpg',
            videoLink: 'https://www.youtube.com/embed/kFl_eTIPq9w',
        },
        {
            name: 'What to Expect After Flowers Arrive',
            imageLink: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/help/vid-productfaq.jpg',
            videoLink: 'https://www.youtube.com/embed/ae0gX4r-7bQ',
        },
        {
            name: 'Flower Delivery &amp; Shipping Charge FAQs',
            imageLink: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/help/vid-passport.jpg',
            videoLink: 'https://www.youtube.com/embed/ivRhCERu4p8',
        },
        {
            name: 'Celebrations Passport&reg; Membership',
            imageLink: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/help/vid-productfaq.jpg',
            videoLink: 'https://www.youtube.com/embed/U1vrIX6HN6I',
        },
    ],
};

export default data;
