/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import FlowersColors from './FlowersColors';

const SympathyFlowers = ({ classes, data }) => {
    const { rightSidebar: { hopeBanner, serviceBanner }, leftSidebar: { funeralFlowersColor } } = data;
    const seed = useUIDSeed();
    return (
        <Grid container className={`${classes.container} ${classes.marginTopAndBottom}`}>
            <Grid item xs={6}>
                <div className={classes.paddingLeft}>
                    <Grid container>
                        <Grid item xs={12}>
                            <h2 className={classes.linkWithShop}>
                                <Link to="/allfuneralarrangements">Shop Funeral Flowers by Color</Link>
                            </h2>
                        </Grid>
                        { funeralFlowersColor.map((block) => (
                            <FlowersColors data={block} key={seed(block)} />
                        ))}
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Link className={classes.hopeBanner} to={hopeBanner.link}>
                                <img src={`${hopeBanner.img}`} alt="Give Love. Inspire Hope" />
                            </Link>
                        </Grid>
                        <Grid className={classes.serviceBlock} item xs={12}>
                            <div className={classes.left}>
                                <h3 className={classes.h3}>{ReactHtmlParser(serviceBanner.heading)}</h3>
                                <p className={classes.p}>{ReactHtmlParser(serviceBanner.content)}</p>
                            </div>
                            <div className={classes.right}>
                                <img className={classes.image} src={`${serviceBanner.img}?height=162&width=141`} alt="Customer Service" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

SympathyFlowers.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
};

export default SympathyFlowers;
