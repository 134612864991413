/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { object, func, string } from 'prop-types';
import { compose } from 'recompose';

import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { actions as tagManagerActions } from '../../../../../state/ducks/TagManager';
import { trackData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import GraphqlUNTOrderTracking from './GraphqlUNTOrderTracking';
import EmailSubscribeBox from '../../../ContentTypeComponents/EmailSubscrube/EmailSubscribeBox';
import { selectors } from '../../../../../state/ducks/App';

const styles = (theme) => ({
    siteContainer: {
        maxWidth: '1400px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down(768)]: {
            display: 'block',
        },
    },
    unt: {
        padding: '7px 15px',
        [theme.breakpoints.down(768)]: {
            margin: '0 auto 5px',
        },
        [theme.breakpoints.up(768)]: {
            padding: '10px 15px',
        },
        [theme.breakpoints.up(1025)]: {
            // !important is used to avoid the component from picking mobile colors on desktop
            backgroundColor: `${theme.palette.cms?.underNavWidgetDesktopBackground || '#f4f2f7'} !important`,
        },
    },
    unt_p: {
        fontWeight: 'bold',
        margin: '5px 0',
    },
    unt_link: {
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontSize: '11px',
        letterSpacing: '0.42px',
        [theme.breakpoints.up(768)]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up(1025)]: {
            // !important is used to avoid the component from picking mobile colors on desktop
            color: `${theme.palette.cms?.underNavWidgetDesktopText || theme.palette.bgHeader} !important`,
            fontSize: '15px',
        },
    },
    radio_link: {
        display: 'none',
        [theme.breakpoints.up(1025)]: {
            display: 'block',
            textDecoration: 'none',
            padding: '4px',
            backgroundColor: '#FFF',
            border: '1px solid #e5e5e5',
            borderRadius: '6px',
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'none',
            },
        },
    },
    radioContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',

    },
    radioIcon: {
        height: '29px',
        width: '29px',
        borderRadius: '60px',
        marginRight: '6px',
        backgroundColor: theme.palette.bgHeader,
    },
    radioIconSvg: {
        pointerEvents: 'none',
        display: 'block',
        width: '100%',
        height: '100%',
    },
    subscribeBoxMobile: {
        [theme.breakpoints.up(1025)]: {
            display: 'none',
        },
    },
    subscribeBoxDesktop: {
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
    radioText: {
        color: theme.palette.bgHeader,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '16px',
        margin: 0,
    },
    radioArrow: {
        height: '25px',
        width: '25px',
        paddingBottom: '1px',
    },
    radioArrowSvg: {
        fill: theme.palette.bgHeader,
        pointerEvents: 'none',
        display: 'block',
        width: '100%',
        height: '100%',
    },
    untBlock: {
        [theme.breakpoints.down(768)]: {
            margin: '8px 15px 4px',
            borderRadius: '5px',
            padding: '3px 15px',
        },
    },
});

const determineLocale = () => {
    if (typeof window !== 'undefined' && window?.location?.href?.includes('canada')) {
        return false;
    }
    return true;
};

const renderOnDevice = (platform, ssrDeviceType) => {
    // @intention: determine if banner is intended to render on user device
    // @param {obj} - data from CMS "show_on_platform"
    // @param {string} ssrDeviceType
    // @return: {bool}
    const device = platform?.toLowerCase() || 'desktop';
    if (device === ssrDeviceType || device === 'both') {
        return true;
    }
    return false;
};

export const GraphqlUnderNavTextWidget = ({
    classes, data, trackEvent, contentType, presentationFamily, featureFlags, countryName, ssrDeviceType,
}) => {
    // Persist data layer
    trackData({
        untData: data,
        action: 'track_unt_data',
    });
    const  loadCss = () => {
        if (contentType === 'homepage' && presentationFamily === 'flower') {
            return classes.untBlock;
        } return null;
    };
    if ((countryName !== '' && countryName !== 'united states'  && countryName !== 'canada') && presentationFamily !== 'food') {
        return null;
    }
    const orderTracking = data?.order_tracking || {};

    const subscribeBoxViewport = data?.subscribe_box?.viewport || 'desktop';

    if (!renderOnDevice(data.show_on_platform, ssrDeviceType)) {
        return <></>;
    }

    return (
        <div id="unt" className={`${classes.unt} ${loadCss()}`} style={{ backgroundColor: data.background_color }}>
            <div className={classes.siteContainer}>
                <p className={classes.unt_p}>
                    <Link
                        className={classes.unt_link}
                        style={{ color: data.font_color }}
                        to={data.link.href}
                        onClick={() => {
                            trackEvent({
                                eventName: 'feature_interaction', eventCategory: 'UNT', eventAction: data.link.title, eventLabel: '<<pageType>>',
                            });
                        }}
                    >
                        {data.link.title}
                    </Link>
                </p>
                {orderTracking && orderTracking?.show ? <GraphqlUNTOrderTracking orderTracking={orderTracking} /> : null}
                {data.subscribe_box?.is_enabled ? (
                    <div
                        className={`${subscribeBoxViewport === 'Desktop' || subscribeBoxViewport === 'Mobile' ? classes[`subscribeBox${subscribeBoxViewport}`] : ''}`}
                    >
                        <EmailSubscribeBox data={data.subscribe_box} />
                    </div>
                ) : (determineLocale() && featureFlags['is-radio-offers-button-enabled'] && (
                    <Link
                        className={classes.radio_link}
                        to="/radio-interstitial-form"
                        onClick={() => trackEvent({
                            eventCategory: 'UNT', eventAction: 'Radio Button', eventLabel: '<<pageType>>',
                        })}
                    >
                        <div className={classes.radioContainer}>
                            <div className={classes.radioIcon}>
                                <svg
                                    viewBox="0 0 33 33"
                                    preserveAspectRatio="xMidYMid meet"
                                    focusable="false"
                                    className={classes.radioIconSvg}
                                >
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
                                        <g id="Group" transform="translate(8, 4)" stroke="#FFF">
                                            <path d="M14.5714286,12.547619 C14.5714286,16.0128532 11.7154286,18.8214286 8.19642857,18.8214286 C4.67530357,18.8214286 1.82142857,16.0128532 1.82142857,12.547619 L1.82142857,6.27380952 C1.82142857,2.8085754 4.67530357,0 8.19642857,0 C11.7154286,0 14.5714286,2.8085754 14.5714286,6.27380952 L14.5714286,12.547619 L14.5714286,12.547619 Z" />
                                            <path d="M0,10.3214286 L0,12.3857143 C0,17.0035214 3.63515625,20.6428571 8.25,20.6428571 C12.8627813,20.6428571 16.5,17.0035214 16.5,12.3857143 L16.5,10.3214286" strokeLinecap="round" />
                                            <path d="M8.5,21.25 L8.5,24.2857143" strokeLinecap="round" />
                                            <path d="M8.5,0 L8.5,3.03571429" strokeLinecap="round" />
                                            <path d="M10.9297857,1 L10.9285714,4.03571429" strokeLinecap="round" />
                                            <path d="M6.07142857,1 L6.07142857,4.03571429" strokeLinecap="round" />
                                            <path d="M0,12.75 L16.5,12.75" />
                                            <path d="M1.82142857,6.07142857 L6.07142857,6.07142857" strokeLinecap="round" />
                                            <path d="M1.82142857,8.5 L6.07142857,8.5" strokeLinecap="round" />
                                            <path d="M14.1785714,6.07142857 L9.92857143,6.07142857" strokeLinecap="round" />
                                            <path d="M14.1785714,8.5 L9.92857143,8.5" strokeLinecap="round" />
                                            <path d="M1.82142857,10.3214286 L6.07142857,10.3214286" strokeLinecap="round" />
                                            <path d="M14.1785714,10.3214286 L9.92857143,10.3214286" strokeLinecap="round" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p className={classes.radioText}>Radio offers | Click Here</p>
                            <div className={classes.radioArrow}>
                                <svg
                                    viewBox="0 0 24 24"
                                    preserveAspectRatio="xMidYMid meet"
                                    focusable="false"
                                    className={classes.radioArrowSvg}
                                >
                                    <g>
                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </Link>
                )
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    countryName: selectors.getAppCountry(state),
    ssrDeviceType: getSSRDeviceType(state),
});

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};
GraphqlUnderNavTextWidget.defaultProps = {
    countryName: '',
    ssrDeviceType: 'both',
};
GraphqlUnderNavTextWidget.propTypes = {
    data: object.isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
    contentType: string.isRequired,
    presentationFamily: string.isRequired,
    featureFlags: object.isRequired,
    countryName: string,
    ssrDeviceType: string,
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(GraphqlUnderNavTextWidget);
