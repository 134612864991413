/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import mbpLogger from 'mbp-logger';
import { any, string, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';

/**
 *
 * @param {String} boundaryName The name of the boundary that will be used in error reporting
 * @param {String?} errorMessage The message, if any, to be displayed when the component fails. If no message is included, nothing will show.
 * @param {Function?} fallback A fallback component that will render if an error occurs. If defined, errorMessage will not be displayed
 */
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        const { boundaryName } = this.props;

        this.state = {
            hasError: false,
        };
        if (!boundaryName) {
            throw new Error('No boundaryName was provided to ErrorBoundary. This is mandatory.');
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, componentStack) {
        const { boundaryName } = this.props;
        mbpLogger.logError({
            function: boundaryName,
            appName: process.env.npm_package_name,
            module: 'ErrorBoundary',
            message: `An error originated from ${boundaryName}`,
            jsError: error,
            componentStack,
        });
    }

    render() {
        const {
            children, fallback, errorMessage,
        } = this.props;
        const { hasError } = this.state;
        // eslint-disable-next-line react/jsx-props-no-spreading
        let component = children;
        if (hasError) {
            if (fallback && typeof fallback === 'function') {
                component = fallback();
            } else if (errorMessage) {
                component = (
                    <Typography align="center" variant="h4">
                        {errorMessage}
                    </Typography>
                );
            } else { // render empty div if nothing is passed (will help maintain page structure)
                component = <div />;
            }
        }
        return component;
    }
}

ErrorBoundary.propTypes = {
    boundaryName: string.isRequired,
    children: any.isRequired,
    fallback: func,
    errorMessage: string,
};

ErrorBoundary.defaultProps = {
    fallback: null,
    errorMessage: null,
};

export default ErrorBoundary;
