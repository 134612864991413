/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Libraries
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { string } from 'prop-types';

function RedirectWithStatus({ to, status, productUnavailableName }) {
    return (
        <Route
            render={({ staticContext }) => {
                // there is no `staticContext` on the client, so
                // we need to guard against that here
                if (staticContext) {
                    staticContext.status = status;
                    staticContext.productUnavailableName = productUnavailableName;
                }
                return <Redirect to={to} />;
            }}
        />
    );
}

RedirectWithStatus.propTypes = {
    to: string.isRequired,
    status: string.isRequired,
    productUnavailableName: string,
};

RedirectWithStatus.defaultProps = {
    productUnavailableName: '',
};

export default RedirectWithStatus;
