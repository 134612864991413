/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, object, fun,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Dialog, Grid, Typography } from '@material-ui/core';
// eslint-disable-next-line
import WesaluteBrandConnections from '../WesaluteBrandConnections';

const styles = (theme) => ({
    modalBrackdropProps: {
        backgroundColor: theme.palette.bgPopup,
    },
    modelOverflow: {
        overflow: 'auto',
    },
    modalContainer: {
        backgroundColor: theme.palette.grey1,
        padding: '34px 4px',
    },
    headingText: {
        color: theme.palette.white,
        textTransform: 'uppercase',
        fontSize: '16px',
        lineHeight: '48px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: '0',
        padding: '0 8px',
        fontWeight: 'normal',
    },
    right: {
        right: '10px',
        position: 'absolute',
        fontSize: '20px',
        fontWeight: '100',
        top: '-7px',
        padding: '0px 10px',
        textAlign: 'center',
    },
    modalBox: {
        maxHeight: 'calc(100% - 96px)',
        minHeight: '150px',
        maxWidth: '350px',
        minWidth: '350px',
    },
    dialogContent: {
        overflowY: 'auto',
        padding: '5px',
    },
    buttonActionSection: {
        textAlign: 'center',
    },
});

// eslint-disable-next-line react/prefer-stateless-function
class SignInModal extends React.Component {
    render() {
        const {
            classes, modalStatus, closeModal,
        } = this.props;

        return (
            <Grid>
                <Dialog
                    classes={{
                        paper: classes.modalBox,
                    }}
                    open={modalStatus}
                    onClose={closeModal}
                >
                    <Grid className={classes.dialogContent}>
                        <Grid className={classes.buttonActionSection}>
                            <div>
                                <img src="https://images.contentstack.io/v3/assets/blt4454dc0093319d35/bltfa06efddd0b0f77a/65e1f36ac7f05b63b2868d46/we-salute.png" className="dHero" alt="Veterans Advantage" />
                            </div>
                            <Typography className={classes.header}>
                                You can please click below sign in link!
                            </Typography>
                            { modalStatus && <div><WesaluteBrandConnections modalStatus={modalStatus} /></div> }
                        </Grid>
                    </Grid>
                </Dialog>
            </Grid>
        );
    }
}

SignInModal.propTypes = {
    classes: object.isRequired,
    modalStatus: bool.isRequired,
    closeModal: fun,
};

SignInModal.defaultProps = {
    closeModal: null,
};

export default (withStyles(styles)(SignInModal));
