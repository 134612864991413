/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import {
    object,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReactHtmlParser from 'react-html-parser';
import Data from './ViewData';
import ContactUsBannerContainer from '../ContactUsBanner/ContactUsBannerContainer';
import toggleWebChat from '../../../../helpers/toggleWebChat';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
    title: {
        textAlign: 'center',
        '& h2': {
            fontSize: 28,
        },
        '& p': {
            fontSize: 15,
            margin: '30px 0',
            padding: '0px 30px',
            textAlign: 'left',
        },
    },
    orderOptions: {
        margin: '25px auto',
        padding: '0 16px',
        '& p': {
            color: theme.palette.contentColor,
            padding: '0 10px',
            fontSize: 14,
            marginBottom: 0,
        },
        '& a': {
            color: theme.palette.common.black,
            margin: '0 10px',
            padding: '20px 0',
            display: 'flex',
            fontSize: '16px',
            fontWeight: '700',
            borderBottom: `1px solid ${theme.palette.common.black}`,
            letterSpacing: '0.47px',
            textDecoration: 'none',
            justifyContent: 'space-between',
        },
    },
    breadCrumb: {
        marginTop: 10,
        '& a': {
            color: theme.palette.contentColor,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: 15,
            outline: 'none',
        },
    },
    arrowSign: {
        fontSize: 16,
        verticalAlign: 'middle',
    },
    steps: {
        fontSize: 15,
        padding: '0 30px',
        '& span': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        '& ul': {
            fontSize: 15,
            '& li': {
                paddingBottom: 10,
            },
        },
    },
});

const CustomerServiceMyOrderPage = ({ classes, page, brand }) => {
    const {
        title, orderDateDescription, orderOptions, steps,
    } = Data;
    const seed = useUIDSeed();
    return (
        <>
            <Grid container className={classes.container}>
                <div className={classes.breadCrumb}><Link to="/customer-service"><ArrowBackIosIcon className={classes.arrowSign} />Help Center / Order Issue</Link></div>
                <Grid className={classes.title} item xs={12}>
                    <h2>{title}</h2>
                    <p>{ReactHtmlParser(orderDateDescription)}</p>
                </Grid>
                <Grid item xs={12}>
                    { steps.map((step) => (
                        <div key={seed(step)} className={classes.steps}>
                            <p>{ReactHtmlParser(step.title)} {step.link && <span role="presentation" onClick={() => toggleWebChat()}>{ReactHtmlParser(step.link)} </span>}</p>
                            <ul> {step.deliveryInfo.map((data) => <li key={seed(data)}>{ReactHtmlParser(data.title)} {data.link && <Link to={data.link}>read more</Link>}</li>)} </ul>
                        </div>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    { orderOptions.map((option) => (
                        <div key={seed(option)} className={classes.orderOptions}>
                            <p>{option.title}</p>
                            {option.option.map((data) => <Link key={seed(data)} to={data.link}><div>{data.title}</div> &#x3E;</Link>)}
                        </div>
                    ))}
                </Grid>
            </Grid>
            <ContactUsBannerContainer page={page} brand={brand} />
        </>
    );
};

CustomerServiceMyOrderPage.propTypes = {
    classes: object.isRequired,
    brand: object.isRequired,
    page: object.isRequired,
};

export default withStyles(styles)(CustomerServiceMyOrderPage);
