/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useLazyQuery } from 'react-apollo';
import {
    object, string, func, bool,
} from 'prop-types';
import { compose } from 'recompose';
import mbpLogger from 'mbp-logger';
// components
import GraphqlSearchBuilder from './GraphqlSearchBuilder';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
// helpers, redux, hooks
import findProductsBySearchTerm from '../../../gql/queries/findProductsBySearchTerm';
import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';
import { GRAPHQL_ENV } from '../../../gql';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlags, getPresentationFamily, getDisplayDeliveryMethod } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import CategoryPageSkeleton from '../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';

const determineProductOptions = ({ lowToHighBtn, highToLowBtn, facets }, pageNumber, pageSize = 12, isBloomreachBrand) => {
    let productOptions = {
        orderBy: 'BEST_SELLERS',
        pageSize,
        page: pageNumber,
    };
    if (highToLowBtn) {
        productOptions = { ...productOptions, orderBy: 'PRICE_DESC' };
    }
    if (lowToHighBtn) {
        productOptions = { ...productOptions, orderBy: 'PRICE_ASC' };
    }
    if (facets?.length) {
        productOptions = { ...productOptions, facets: [facets] };
    }
    if (isBloomreachBrand) {
        // bloomreach brands cannot paginate at the moment.
        // so the pagination product options are removed
        delete productOptions.page;
        delete productOptions.pageSize;
    }
    return productOptions;
};

const GraphqlSearchContainer = ({
    brand, searchTerm, featureFlags, trackEvent, presentationFamily, displayDeliveryMethod,
}) => {
    const [filterOptions, setFilterOptions] = useState({});
    const pageCount = useRef(1);
    const SEARCH_PAGE_QUERY = findProductsBySearchTerm();
    const [getSearchProducts, {
        loading, error, data, fetchMore,
    }] = useLazyQuery(SEARCH_PAGE_QUERY);
    const isBloomreachBrand = !!brand?.['bloomreach-account-id'];
    const fetchMoreResults = () => {
        if (fetchMore) {
            fetchMore({
                variables: {
                    brand: brand['domain-name'],
                    term: searchTerm,
                    productOptions: determineProductOptions(filterOptions, pageCount.current, 12, isBloomreachBrand),
                    useES: featureFlags['is-elastic-search-enabled'],
                    environment: GRAPHQL_ENV,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    const prevProductFeed = prev?.findProductsBySearchTerm;
                    const nextProductFeed = fetchMoreResult?.findProductsBySearchTerm;
                    return {
                        ...prev,
                        findProductsBySearchTerm: {
                            products: [
                                ...prevProductFeed.products,
                                ...nextProductFeed.products,
                            ],
                            /* eslint-disable-next-line no-underscore-dangle */
                            __typename: fetchMoreResult?.findProductsBySearchTerm?.__typename,
                        },
                    };
                },
            });
            pageCount.current += 1;
        }
    };
    useEffect(() => {
        pageCount.current = 1;
        getSearchProducts({
            variables: {
                brand: brand['domain-name'],
                term: searchTerm,
                productOptions: determineProductOptions(filterOptions, pageCount.current, 12, isBloomreachBrand),
                useES: featureFlags['is-elastic-search-enabled'],
                environment: GRAPHQL_ENV,
            },
        });
        pageCount.current += 1;
    }, [filterOptions, searchTerm]);
    if (loading) {
        // return <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
        return <CategoryPageSkeleton />;
    }

    if (error) {
        mbpLogger.logError({
            query: SEARCH_PAGE_QUERY,
            component: 'GraphqlSearchContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            query: SEARCH_PAGE_QUERY,
            component: 'GraphqlSearchContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    const searchResults = data.findProductsBySearchTerm || '';
    if (searchResults) {
        trackEvent({
            eventName: 'feature_interaction', eventCategory: 'Test Impression', eventAction: featureFlags['is-elastic-search-enabled'] ? 'Elastic' : 'SOLR', eventLabel: searchTerm,
        });
    }

    return (
        <GraphqlSearchBuilder
            brand={brand}
            pageData={searchResults}
            searchTerm={searchTerm}
            featureFlags={featureFlags}
            presentationFamily={presentationFamily}
            displayDeliveryMethod={displayDeliveryMethod}
            pageCount={pageCount.current}
            setFilterOptions={setFilterOptions}
            fetchMoreResults={fetchMoreResults}
        />
    );
};

GraphqlSearchContainer.propTypes = {
    brand: object.isRequired,
    featureFlags: object.isRequired,
    searchTerm: string.isRequired,
    trackEvent: func.isRequired,
    presentationFamily: string.isRequired,
    displayDeliveryMethod: bool.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    featureFlags: getFeatureFlags(state),
    presentationFamily: getPresentationFamily(state),
    displayDeliveryMethod: getDisplayDeliveryMethod(state),
});

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

const enhance = compose(
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(GraphqlSearchContainer);
