/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import { useUIDSeed } from 'react-uid';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import Data from './ViewData';
import customBreakpoints from '../../../helpers/customBreakpoints';

const styles = (theme)  => ({
    container: {
        width: '100%',
        margin: 'auto',
        maxWidth: 1440,
        marginBottom: 50,
        marginTop: 25,
    },
    containerInner: {
        width: '100%',
        margin: 'auto',
        maxWidth: 980,
    },
    ceoImage: {
        textAlign: 'center',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    titleContainer: {
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            maxWidth: '100%',
            flexBasis: '100%',
            marginTop: '20px',
        },
    },
    ceoInformation: {
        background: theme.palette.colorNeutral20,
        padding: '60px 40px 40px',
        margin: '15px 0',
    },
    ceoTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    description: {
        marginTop: 15,
        '& p': {
            lineHeight: 'normal',
            margin: '10px 0',
        },
    },
    option: {
        marginLeft: 15,
        '& ul': {
            margin: 0,
            '& li': {
                margin: '7px 0',
            },
        },
        '& p': {
            paddingLeft: 24,
        },
    },
    signature: {
        width: 135,
        marginTop: 10,
    },
    title: {
        fontWeight: 'bold',
    },
    healthDescription: {
        paddingTop: 10,
    },
    firstSection: {
        '& ul': {
            paddingLeft: 15,
            '& li': {
                '&:first-of-type': {
                    marginLeft: 0,
                },
                marginTop: 10,
                marginLeft: 30,
                lineHeight: 'normal',
                '& a': {
                    textDecoration: 'none',
                    color: theme.palette.colorPrimary,
                },
            },
        },
    },
    secondSection: {
        '& ul': {
            '& li': {
                marginTop: 10,
            },
        },
    },
    topBanner: {
        '& img': {
            width: '100%',
        },
    },
});

const MessageFromCeoPage = ({ classes }) => {
    const {
        customer_banner_image, ceo_information: {
            ceo_image, ceo_title, ceo_description, ceo_description_list, ceo_description_bottom, ceo_signature, ceo_name,
        },
        health_and_safety: {
            health_title, health_description, section_one: { section_one_title, section_one_description_list },
            section_two: { section_two_title, section_two_description_list }, bottom_content,
        },
    } = Data;
    const seo = {
        page_title: 'Message to Our Customers | 1800Flowers',
        meta_description: 'Staying Connected Is More Important Than Ever',
        location: { pathname: '/a-message-to-our-customers-400216497' },
    };
    const seed = useUIDSeed();
    return (
        <>
            <GraphqlSeoHeadTags seoData={seo} />
            <div className={classes.container}>
                <div className={classes.topBanner}>
                    <img src={customer_banner_image} alt="Banner" />
                </div>
                <div className={classes.containerInner}>
                    <div className={classes.ceoInformation}>
                        <Grid container>
                            <Grid className={classes.ceoImage} item xs={4}>
                                <img src={ceo_image} alt="Ceo" />
                            </Grid>
                            <Grid className={classes.titleContainer} item xs={8}>
                                <div className={classes.ceoTitle}> {ceo_title} </div>
                            </Grid>

                            <Grid className={classes.description} item xs={12}>
                                {ceo_description.map((des) => {
                                    const { ceo_description_section } = des;
                                    return (<p key={seed(des)}> {ceo_description_section} </p>);
                                })}
                                <div className={classes.option}>
                                    <ul>
                                        {ceo_description_list.map((options) => {
                                            const { option } = options;
                                            return (<li key={seed(options)}>{option} </li>);
                                        })}
                                    </ul>
                                    <p>{ceo_description_bottom}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12}>Sincerely,</Grid>
                            <div className={classes.signature}>
                                <img src={ceo_signature} alt="Ceo Signature" />
                            </div>
                            <Grid item xs={12}>{ReactHtmlParser(ceo_name)}</Grid>
                        </Grid>
                    </div>
                    <div className={classes.healthSafety}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.title}>{health_title}</div>
                                <div className={classes.healthDescription}><p>{health_description}</p></div>
                                <div className={classes.firstSection}>
                                    <div className={classes.title}>{section_one_title} </div>
                                    <ul>
                                        {section_one_description_list.map((list) => {
                                            const { description } = list;
                                            return (<li key={seed(list)}>{ReactHtmlParser(description)}</li>);
                                        })}
                                    </ul>
                                </div>
                                <div className={classes.secondSection}>
                                    <div className={classes.title}>{section_two_title} </div>
                                    <ul>
                                        {section_two_description_list.map((list) => {
                                            const { description } = list;
                                            return (<li key={seed(list)}>{ReactHtmlParser(description)}</li>);
                                        })}
                                    </ul>
                                </div>
                                <div>{ReactHtmlParser(bottom_content)}</div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};

MessageFromCeoPage.propTypes = {
    classes: object.isRequired,
};
export default withStyles(styles)(MessageFromCeoPage);
