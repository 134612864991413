/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import {
    bool, func, arrayOf, object, shape, string,
} from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import { useLocation } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ProductFacetsView from '../../GraphqlProductFacets/ProductFacetsView';
import { getFeatureFlags } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    sortAndFilter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'colomn',
        position: 'relative',
    },
    selectContainer: {
        color: theme.palette.text?.primary || 'rgba(0, 0, 0, 0.87)',
        cursor: 'text',
        display: 'inline-flex',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        lineHeight: '1.1875em',
        alignItems: 'center',
        position: 'relative',
        marginLeft: '10px',
        '&:before': {
            left: '0',
            right: '0',
            bottom: '0',
            content: '\\/00a0',
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: `1px solid ${theme.palette?.pdp?.cardBorder}`,
            pointerEvents: 'none',
        },
        '&:after': {
            left: '0',
            right: '0',
            bottom: '0',
            content: '',
            position: 'absolute',
            transform: 'scaleX(0)',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            borderBottom: '2px solid rgb(62, 62, 62)',
            pointerEvents: 'none',
        },
    },
    select: {
        font: 'inherit',
        color: 'currentColor',
        width: '100%',
        border: '0',
        margin: '0',
        padding: '6px 0 7px',
        display: 'block',
        minWidth: '0',
        boxSizing: 'content-box',
        background: 'none',
        '& not([multiple]) option': {
            backgroundColor: theme.palette.white,
        },
        '& not([multiple]) optgroup': {
            backgroundColor: theme.palette.white,
        },
        '& .MuiSelect-select.MuiSelect-select': {
            fontWeight: 'normal',
            display: 'block',
            whiteSpace: 'pre',
            minHeight: '1.2em',
            padding: '0px 2px 1px',
            minWidth: '150px',
        },
        '& svg': {
            marginTop: '-4px',
        },
    },
    selectImage: {
        top: 'calc(50% - 13px)',
        right: '-5px',
        color: theme.palette.text?.secondary || 'rgba(0, 0, 0, 0.54)',
        position: 'absolute',
        pointerEvents: 'none',
        fill: 'currentColor',
        width: '1.1em',
        height: '1.1em',
        display: 'inline-block',
        fontSize: '24px',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: '0',
    },
    filter: {
        flex: 1,
    },
    filterText: {
        color: theme.palette.black,
        float: 'right',
        margin: '4px 12px 12px',
        border: '2px solid #1f1f1b',
        padding: '3px 6px',
        display: 'flex',
        fontSize: '0.9em',
        alignItems: 'center',
        borderRadius: '2px',
    },
    filterImage: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '24px',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: '0',
    },
    dialogTitle: {
        padding: '24px 24px 20px',
        fontSize: '1.2em',
        flex: '0 0 auto',
    },
    modalBox: {
        margin: '12px !important',
        maxHeight: 'calc(100% - 26px)',
        width: '100%',
        maxWidth: '600px',
        boxShadow: '0px 0px 20px 6px rgba(0,0,0,0.5)',
    },
    fixedHeader: {
        position: 'fixed',
    },
    dialogContent: {
        marginTop: '50px',
        display: 'flex',
        position: 'relative',
        overflowY: 'auto',
        flexDirection: 'column',
    },
    filterList: {
        flex: '1 1 auto',
        padding: '0 24px 24px',
        overflowY: 'auto',
        '& nav > div': {
            padding: '0 24px 0 24px',
            transition: 'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        },
        '& nav > div li': {
            paddingLeft: '0',
            paddingRight: '0',
        },
        '& nav > div li span': {
            fontWeight: 'bold',
        },
        '& nav > div .MuiCollapse-container li span': {
            fontWeight: 'normal',
        },
    },
    filterButtonGroup: {
        flex: '0 0 auto',
        margin: '8px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    filterButton: {
        flex: '1 0 0',
        fontSize: '0.9em',
        fontWeight: 'bold',
        margin: '0 4px',
        padding: '6px 8px',
        minWidth: '64px',
        lineHeight: '1.75',
        textTransform: 'uppercase',
        display: 'inline-flex',
        cursor: 'pointer',
        justifyContent: 'center',
        '&:first-child': {
            flex: '2 0 0',
            margin: '0 15px 0',
            justifyContent: 'left',
        },
    },
    sortLabel: {
        whiteSpace: 'nowrap',
    },
});

const SortAndFilterFacets = ({
    classes, toggleFilter, toggleFilterFunc, applyFilter, productFacets, setFacetsOptions, featureFlags,
}) => {
    const seed = useUIDSeed();
    const location = useLocation();
    const locationData = location?.search?.includes('?facet=') ? location.search.replace('?facet=', '') : '';
    const redirectLocationData = locationData?.split('&');
    const checkedFacetsData = redirectLocationData.length && redirectLocationData?.[0] !== '' ? redirectLocationData : [];

    const [checkedOptions, setCheckedOptions] = useState(checkedFacetsData);
    const [sortValue, setSortValue] = useState('sellingBtn');

    const isFacetFiltersEnabled = featureFlags['is-facet-filters-enabled'];

    useEffect(() => {
        setFacetsOptions(checkedFacetsData);
    }, []);

    const handleChange = (event) => {
        setSortValue(event.target.value);
        applyFilter(event.target.value);
    };

    const updateFilterDataInCategoryBuilder = () => {
        setFacetsOptions(checkedOptions);
        toggleFilterFunc();
    };

    const resetChecked = () => {
        setCheckedOptions([]);
        setFacetsOptions();
        toggleFilterFunc();
    };
    return (
        <div className={classes.sortAndFilter}>
            <div className={classes.sort}>
                <span className={classes.sortLabel}>Sort By:</span>
                <div
                    className={classes.selectContainer}
                    data-test="collection-select-sort"
                >
                    <Select
                        labelId="collection-select-sort-label"
                        id="collection-select-sort"
                        value={sortValue}
                        onChange={(e) => handleChange(e)}
                        className={classes.select}
                    >
                        <MenuItem value="sellingBtn">
                            Best Selling
                        </MenuItem>
                        <MenuItem value="lowToHighBtn">
                            Price Low to High
                        </MenuItem>
                        <MenuItem value="highToLowBtn">
                            Price High to Low
                        </MenuItem>
                    </Select>
                </div>
            </div>
            {isFacetFiltersEnabled ? (
                <div className={classes.filter}>
                    <div role="presentation" onClick={toggleFilterFunc}>
                        <span data-testid="mobile-filter-button" className={classes.filterText}>
                            <svg
                                className={classes.filterImage}
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                                role="presentation"
                            >
                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                <path fill="none" d="M0 0h24v24H0z" />
                            </svg>
                            FILTER
                        </span>
                    </div>
                </div>
            ) : null}
            <Dialog
                classes={{
                    paper: classes.modalBox,
                }}
                data-testid="mobile-filter-dialog"
                open={toggleFilter}
                onClose={toggleFilterFunc}
            >
                <Grid className={classes.fixedHeader}>
                    <div className={classes.dialogTitle}>
                        Filter
                    </div>
                </Grid>
                <Grid className={classes.dialogContent}>
                    <div className={classes.filterList}>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className={classes.root}
                        >
                            {productFacets ? productFacets.map((facets) => (
                                <div key={seed(facets)}>
                                    <ProductFacetsView
                                        classes={classes}
                                        facetsView={facets}
                                        setFilterDataInCategoryBuilder={setFacetsOptions}
                                        checkedOptions={checkedOptions}
                                        setCheckedOptions={setCheckedOptions}
                                        autoRedirectLocation={locationData}
                                        iconType="secondary"
                                    />
                                </div>
                            )) : null}
                        </List>
                    </div>
                    <div className={classes.filterButtonGroup}>
                        <span onClick={toggleFilterFunc} role="presentation" className={classes.filterButton}>Cancel</span>
                        <span onClick={resetChecked} data-testid="facets-clear-filters" role="presentation" className={classes.filterButton}>Clear</span>
                        <span onClick={updateFilterDataInCategoryBuilder} role="presentation" className={classes.filterButton}>Submit</span>
                    </div>
                </Grid>
            </Dialog>
        </div>
    );
};

SortAndFilterFacets.propTypes = {
    classes: object.isRequired,
    toggleFilter: bool.isRequired,
    toggleFilterFunc: func.isRequired,
    setFacetsOptions: func.isRequired,
    productFacets: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    applyFilter: func.isRequired,
    featureFlags: object.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps),
);

export default enhance(SortAndFilterFacets);
