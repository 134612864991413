/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import gql from 'graphql-tag';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - flowers or food
 * @param {string} path - path to be fetched
 */

// The uid is used for cache invalidation, but it must be requested to get used as a header

const findURL = (brand, path) => (
    gql`{ findURL(brand: "${brand}", locale: "en-us", environment: "${GRAPHQL_ENV}", path: "${path}") {
            contentType
            type
            commerceURL
            code
            uid
            vanity {
                url
                redirectType
                bannerCode
                __typename
            }
            bannerCode {
                content
                __typename
            }
            redirectTesting {
                matchingParameters
                featureFlagName
                url
                uniqueId
                redirects {
                    url
                    variant
                    contentType
                    code
                    __typename
                }
                __typename
            }
            __typename
        }
    }`
);

export default findURL;
