/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import mbpLogger from 'mbp-logger';
import QueryString from 'qs';
import { Route, Redirect } from 'react-router-dom';

import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';
import { getSCIData } from '../../../../state/ducks/SCI/SCI-Actions';
import { determineCollectionPrefixPath } from '../../../helpers/determineInternationalizationPath';

const brandSelectors = app.ducks.brand.selectors;

const SCIContainer = ({
    brand,
    actionSCIData,
    history,
    location: {
        search,
    },
}) => {
    const urlParams = search || null;
    const mId = QueryString.parse(urlParams).mId;
    const referrer = QueryString.parse(urlParams).referrer;
    const utm_term = QueryString.parse(urlParams).campaign_id;
    const Find_Banner_Code_Query = gql`
        query findBannerCodeQuery {
            findBannerCode(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "en-us", code: "${QueryString.parse(urlParams)['?LocNumber']}") {
                content
            }
        }
    `;

    return (
        <Query query={Find_Banner_Code_Query}>
            {({ loading, data, error }) => {
                if (loading) {
                    return null;
                }

                if (error) {
                    mbpLogger.logError({
                        query: Find_Banner_Code_Query,
                        component: 'SCIContainer.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: Find_Banner_Code_Query,
                        component: 'SCIContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                // check url params & cs data
                // if params missing redirect to homepage
                if (urlParams &&  data) {
                    const bannerCodeData = data.findBannerCode.content.entries[0];
                    if (bannerCodeData) {
                        actionSCIData(mId, referrer, utm_term, history, bannerCodeData);
                    } else {
                        // SCI channel redirect if BannerCode is unavailable in Content Stack
                        return (
                            <Route>
                                <Redirect to={`${determineCollectionPrefixPath('/sympathy-flowers-canada', false)}${history.location.search}`} />
                            </Route>
                        );
                    }
                    return null;
                }

                return (
                    <Route>
                        <Redirect to="/" />
                    </Route>
                );
            }}
        </Query>
    );
};

SCIContainer.propTypes = {
    actionSCIData: func.isRequired,
    brand: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});

const mapDispatchToProps = (dispatch) => ({
    actionSCIData: bindActionCreators(getSCIData, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCIContainer));
