/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const Styles = (theme) => {
    const { palette, typography } = theme;
    return {
        alt_image_slider_container: {
            '&.alt-image-slider-container': {
                fontFamily: typography.fontFamily,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: '0px',
                    marginRight: '0px',
                    '& .col': {
                        padding: '0',
                        lineHeight: '0',
                    },
                },
                '& button, span': {
                    height: '30px',
                    width: '30px',
                    textAlign: 'center',
                },
                '& .slick-track': {
                    display: 'flex !important',
                    '& .slick-slide': {
                        '& > div': {
                            width: '100% !important',
                        },
                        display: 'flex !important',
                        height: 'inherit !important',
                        '& *': {
                            '&:not(img)': {
                                display: 'flex !important',
                                height: 'inherit !important',
                            },
                        },
                        '& img, & source': {
                            flex: 'content',
                        },
                    },
                },
                '& .slick-arrow': {
                    cursor: 'pointer',
                    '&:before': {
                        content: '""',
                    },
                },
                '& .slick-prev': {
                    zIndex: '9 !important',
                    left: '0px !important',
                    '&:before': {
                        content: '""',
                    },
                },
                '& .slick-next': {
                    zIndex: '9 !important',
                    right: '0px !important',
                },
                '& .slick-slide': {
                    width: '100%',
                    paddingRight: '1px',
                    paddingLeft: '1px',
                    '& picture': {
                        width: '100% !important',
                    },
                    '& img, source': {
                        width: '100% !important',
                        margin: '0px',
                        height: '100% !important',
                    },
                    '& .slide-text': {
                        color: palette.common.gray_900,
                        marginTop: '8px',
                        marginBottom: '8px',
                        fontSize: '16px',
                        lineHeight: '1.25',
                        fontWeight: 700,
                        wordBreak: 'break-word',
                        [theme.breakpoints.down('sm')]: {
                            fontWeight: 400,
                        },
                    },
                    '&.slick-active': {
                        [theme.breakpoints.down('576')]: {
                            paddingLeft: '0',
                        },
                    },
                },
                '& .text-icon': {
                    color: palette.common.gray_900,
                },
            },
        },
    };
};

export default Styles;
