/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
    object, arrayOf, shape, string, bool, number,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { getCurrencyData } from '../../../../../../../../../state/ducks/App/App-Selectors';
import { getBrand } from '../../../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const VIEWPORT = {
    MOBILE: 'Mobile',
    DESKTOP: 'Desktop',
};

const styles = (theme) => ({
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        color: theme.palette.cms?.['crossedRetailPrice'] || theme.palette.colorAlert,
        fontWeight: 'normal',
    },
    plaCrossedOutRetailPrice: {
        textDecoration: 'line-through',
        color: '#000',
        fontWeight: 700,
    },
    salePrice: {
        color: theme.palette.primaryButton,
    },
    plaSalePrice: {
        color: '#D1040C',
        fontWeight: 700,
    },
    productName: {
        fontWeight: '400',
    },
    wrapedPrice: {
        display: 'inline-block',
    },
    mobileCenteredPrice: {
        display: 'block',
        marginTop: '5px',
        color: theme.palette.cms?.['nonSaleItemPrice'] || theme.palette.colorPrimary,
    },
    plaPriceRange: {
        color: '#000',
        fontWeight: '700',
        letterSpacing: '0.5px',
        fontSize: '0.875rem',
    },
});

const getPrices = (skuPriceRange, type) => {
    if (!skuPriceRange) return {};
    const priceData = skuPriceRange[type];

    return {
        startingPrice: priceData?.[0]?.value,
        endingPrice: priceData?.[1]?.value,
    };
};

const PlantsPriceRange = ({
    priceRangeLayout, skuPriceRange, isMobile, classes, presentationFamily, currency,
}) => {
    const brand = useSelector(getBrand);
    const { currencySymbol } = currency;
    const viewport = isMobile ? VIEWPORT.MOBILE : VIEWPORT.DESKTOP;
    const isPlants = brand?.code === 'PLA';
    const priceLayout = (priceRangeLayout || []).find((layout) => layout.viewport === viewport) || {};
    let { price_layout = '' } = priceLayout;
    let priceRangeStyle = '';
    // Default Layout type
    // All desktop views and food brands are using this format, if not defined in contentstack this will be used
    if (price_layout === '') {
        if (presentationFamily === 'plants' || !isMobile) {
            price_layout = '$xxx.xx - $xxx.xx';
        }
        // else use default case in switch
        if (presentationFamily === 'plants' || !isMobile) {
            priceRangeStyle = classes.plaPriceRange;
        }
    }

    const {
        startingPrice: saleStarting = '',
        endingPrice: saleEnding = '',
    } = (getPrices(skuPriceRange, 'sale'));

    const {
        startingPrice: retailStarting = '',
        endingPrice: retailEnding = '',
    } = (getPrices(skuPriceRange, 'retail'));

    const isSale = saleStarting.toString() !== retailStarting.toString()
                    || saleEnding.toString() !== retailEnding.toString();

    let salePriceText = '';
    let retailPriceText = '';

    switch (price_layout) {
        // Used by food & flowers desktop
        case '$xxx.xx - $xxx.xx':
            retailPriceText = retailStarting !== retailEnding ? `${currencySymbol}${retailStarting} - ${currencySymbol}${retailEnding}` : `${currencySymbol}${retailStarting}`;
            if (isSale) {
                salePriceText = saleStarting !== saleEnding ? `${currencySymbol}${saleStarting} - ${currencySymbol}${saleEnding}` : `${currencySymbol}${saleStarting}`;
                return (
                    <span className={isMobile ? classes.mobileCenteredPrice : ''} data-testid="sale-price">
                        <span className={`${isPlants ? classes.plaSalePrice : classes.crossedOutRetailPrice} ${classes.wrapedPrice}`}>
                            {isPlants ? salePriceText : retailPriceText}
                        </span>
                        {' '}
                        <span className={`${isPlants ? classes.plaCrossedOutRetailPrice : classes.salePrice} ${classes.wrapedPrice}`}>
                            {isPlants ? retailPriceText : `${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                        </span>
                    </span>
                );
            }
            return (
                <span className={`${isMobile ? classes.mobileCenteredPrice : ''} ${priceRangeStyle}`} data-testid="retail-price-range">
                    {retailPriceText}
                </span>
            );

        // Used by 18F
        default:
        case 'from $xxx.xx':
            retailPriceText = `${currencySymbol}${retailStarting}`;
            if (isSale) {
                salePriceText = `${currencySymbol}${saleStarting}`;
                return (
                    <span data-testid="sale-price">
                        <span className={classes.productName}>
                            {'from '}
                            <span className={classes.crossedOutRetailPrice}>
                                {retailPriceText}
                            </span>
                        </span>
                        {' '}
                        <span className={classes.salePrice}>
                            {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                        </span>
                    </span>
                );
            }
            return (
                <span data-testid="retail-price-range">
                    <span className={classes.productName}>
                        {'from '}
                    </span>
                    <span>
                        {retailPriceText}
                    </span>
                </span>
            );
    }
};

const mapStateToProps = (state) => ({
    currency: getCurrencyData(state),
});

PlantsPriceRange.propTypes = {
    presentationFamily: string.isRequired,
    priceRangeLayout: arrayOf(shape({
        price_layout: string,
        sale_text: string,
        viewport: string,
    })),
    skuPriceRange: shape({
        retail: arrayOf(shape({
            value: number,
        })),
        sale: arrayOf(shape({
            value: number,
        })),
    }),
    isMobile: bool,
    classes: object.isRequired,
    currency: shape({
        currencySymbol: string.isRequired,
    }),
};

PlantsPriceRange.defaultProps = {
    priceRangeLayout: [
        {
            price_layout: '',
            sale_text: '',
            viewport: VIEWPORT.DESKTOP,
        },
        {
            price_layout: '',
            sale_text: '',
            viewport: VIEWPORT.MOBILE,
        },
    ],
    skuPriceRange: {},
    isMobile: false,
    currency: {},
};

export default connect(mapStateToProps)(withStyles(styles)(PlantsPriceRange));
