/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState, useEffect  } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import { useUIDSeed } from 'react-uid';
import {
    object, number, shape, string,
} from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import CheckIcon from '@material-ui/icons/Check';
import Data from './ViewData';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
    },
    dialogMain: {
        '& div': {
            '& div': {
                borderRadius: 'initial',
                maxWidth: '960px',
                padding: '7px 17px 11px',
            },
        },
    },
    closeIcon: {
        top: 8,
        right: 5,
        cursor: 'pointer',
        position: 'absolute',
        fontSize: 24,
        color: theme.palette.contentColor,
    },

    serviceBlock: {
        width: '55%',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('md')]: {
            width: '85%',
        },
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '55%',
        },
    },
    header: {
        display: 'block',
        textAlign: 'center',
        '& h1': {
            fontSize: 30,
            margin: '15px 0',
        },
        '& span': {
            color: theme.palette.contentColor,
            fontSize: 17,
        },
    },
    content: {
        color: theme.palette.colorIconHeader,
        width: '45%',
        border: `1px solid ${theme.palette.contentColor}`,
        margin: 16,
        flexBasis: '45%',
        borderRadius: 2,
        textDecoration: 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '37%',
            flexBasis: '45%',
            margin: '16 auto',
        },
        '& p': {
            padding: 16,
            textAlign: 'center',
            borderTop: `1px solid ${theme.palette.contentColor}`,
            fontWeight: 700,
            margin: 0,
            fontSize: 14,
        },
    },
    imageBlock: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
        },
    },
    bannerMain: {
        width: '720px',
        margin: '50px auto',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    bannerInner: {
        alignItems: 'center',
        width: 'auto',
        minWidth: '100px',
        display: 'flex',
        transition: 'opacity 1s ease-in',
        willChange: 'opacity',
        marginBottom: '0',
        justifyContent: 'space-between',
        backgroundColor: 'rgb(246, 246, 249)',
        padding: '13px 15px',
    },
    bannerInnerDiv: {
        minWidth: 170,
    },

    icon: {
        lineHeight: '16px',
        fontSize: '16px',
        margin: '0 8px 0 10px',
        borderRadius: '50%',
        padding: '2px 6px 5px 2px',
        display: 'inline-block',
        backgroundColor: theme.palette.primaryButton,
        '& svg': {
            color: '#fff',
            fontSize: '20px',
            width: '28px',
            height: '24px',
        },
    },
});

const VideoBlock = ({ classes  }) => {
    const seed = useUIDSeed();
    const {
        title, subTitle, list,
    } = Data;
    const [dialogData, openClick] = useState({
        trigger: false,
        data: {},
    });
    const { trigger, data } = dialogData;
    const closeClick = () => {
        openClick({
            trigger: false,
            data: {},
        });
    };
    const [timeInterval, setTime] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            // eslint-disable-next-line no-shadow
            setTime((timeInterval) => timeInterval + 1);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    if (timeInterval > 3) {
        return (
            <>
                <div className={classes.header}><h1>{ReactHTMLParser(title)}</h1><span>{subTitle}</span></div>
                <div className={classes.serviceBlock}>
                    {list.map((item) => (
                        <div key={seed(item)} className={classes.content} onClick={() => openClick({ trigger: true, data: item })} role="presentation">
                            <div className={classes.imageBlock}>
                                <img src={item.imageLink} alt={item.name} />
                            </div>
                            <p>{ReactHTMLParser(item.name)}</p>
                        </div>
                    ))}
                </div>
                <Dialog className={classes.dialogMain} open={trigger} aria-labelledby="form-dialog-title">
                    <DialogTitle className={classes.title} id="dialog-title">
                        <span role="presentation" onClick={() => { closeClick(); }}>
                            <CloseIcon className={classes.closeIcon} />
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <iframe
                            frameBorder="0"
                            width="560"
                            height="315"
                            scrolling="no"
                            className={classes.iFrame}
                            title={data?.name}
                            src={data?.videoLink}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }
    return null;
};
VideoBlock.propTypes = {
    classes: object.isRequired,
};

const Banner = ({ item, index, classes }) => {
    const [timeInterval, setTime] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            // eslint-disable-next-line no-shadow
            setTime((timeInterval) => timeInterval + 1);
            if (index > 2) {
                clearInterval(interval);
            }
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    if (index < timeInterval) {
        return (
            <div className={`${classes.bannerInner} ${classes.bannerShow}`}>
                {item.title}<span className={classes.icon}> <CheckIcon /> </span>
            </div>
        );
    }
    return null;
};

Banner.propTypes = {
    classes: object.isRequired,
    item: shape({
        title: string,
    }),
    index: number,
};
Banner.defaultProps = {
    item: {},
    index: '',
};

const CustomerServiceConfirmPage = ({ classes }) => {
    const seed = useUIDSeed();
    const {
        banner,
    } = Data;
    return (
        <div className={classes.container}>
            <div className={classes.bannerMain}>
                {banner.map((item, i) => (
                    <div key={seed(item)} className={classes.bannerInnerDiv}>
                        <Banner item={item} index={i} classes={classes} />
                    </div>
                ))}
            </div>
            <VideoBlock classes={classes} />
        </div>
    );
};

CustomerServiceConfirmPage.propTypes = {
    classes: object.isRequired,
};
export default withStyles(styles)(CustomerServiceConfirmPage);
