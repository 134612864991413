/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withRouter } from 'react-router';
import { bool, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import ReactHtmlParser from 'react-html-parser';
// Redux
import { trackData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlags, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const GraphqlDesktopSeoHeadTags = ({
    seoData, featureFlags, brand, location: { pathname }, alternativeLang, presentationFamily,
}) => {
    if (seoData) {
        const {
            page_title = '', meta_description = '', keywords = '', og = [], seo_searchable = true, canonicalUrl = '',
        } = seoData;

        // Persist data layer
        trackData({
            seoData,
            action: 'track_seo_data',
        });

        const determinePath = (pathName) => (pathName === '/' ? '' : pathName);
        const location = (typeof window !== 'undefined') ? window.location.origin : '';

        // IF canonicalUrl exsists on PDP return it
        // ELSE return concatenated string
        const setCanonicalUrl = () => {
            if (canonicalUrl) {
                const domain =  `https://www.${brand.domain}.com`;
                const newUrl = canonicalUrl.split('.com/')[1] || '';
                return `${domain}/p/${newUrl}`;
            }
            return `${location}${determinePath(pathname)}`;
        };

        // IF brand is 18F, BRY, or FBQ temporarily we add pwa.www.brand-name.com
        // ELSE pwa.brand-name.com
        const setRelAlternateUrl = () => {
            if (canonicalUrl) {
                const newUrl = canonicalUrl.split('https://www.')[1];
                return (newUrl.indexOf('1800flowers') !== -1 || newUrl.indexOf('fruitbouquets') !== -1 || newUrl.indexOf('berries') !== -1) ? `pwa.www.${newUrl}` : `pwa.${newUrl}`;
            }
            return (`https://pwa.${brand.domain === '1800flowers' || brand.domain === 'fruitbouquets' || brand.domain === 'berries'
                ? `www.${brand.domain}` // pwa.www.brand-name.com
                : `${brand.domain}`}.com${determinePath(pathname)}` // pwa.brand-name.com
            );
        };

        const canonicalUrlIsFromDifferentBrand = () => {
            if (canonicalUrl && !canonicalUrl?.includes(brand['domain-name'])) {
                return true;
            }
            return false;
        };

        // Primary use case is CONTENT/DEPARTMENT pages - look into adding the data at page level
        // add og:url to og array if it does not exist
        if (!og?.some((graph) => (graph.property === 'og:url') && (graph.content?.length > 0))) {
            og.push({
                property: 'og:url',
                content: setCanonicalUrl(),
            });
        }

        const getHrefLanguage = () => {
            const locales = ['en', 'es', 'fr'];
            let urlPathName;
            let domain =  `https://www.${brand.domain}.com`;
            if (!location.match(/www/g)) {
                domain = location;
            }
            const urlPathStack = String(pathname).toLowerCase().split('/');
            if (urlPathStack.length > 1 && locales.includes(urlPathStack[1])) {
                urlPathName = urlPathStack.slice(2).join('/');
            } else {
                urlPathName = urlPathStack.slice(1).join('/');
            }
            const resultLang = locales.map((locale) => <link rel="alternate" href={`${domain}/${locale}/${urlPathName}`} hrefLang={locale} key={locale} />);
            return resultLang;
        };

        return (
            <Helmet>
                <title>{ReactHtmlParser(page_title)}</title>
                {/* some pages we dont want searchable(toggle in CMS), ONLY display meta if not true */}
                {!seo_searchable && <meta name="robots" content="noindex,nofollow" />}
                {canonicalUrlIsFromDifferentBrand() && <meta name="robots" content="noindex,follow" />}
                {keywords && <meta name="keywords" content={keywords} />}
                {meta_description && <meta name="description" content={meta_description} />}
                {brand && pathname && <link rel="canonical" href={setCanonicalUrl()} />}
                {brand && alternativeLang && (presentationFamily === 'growthbrand') && getHrefLanguage()}
                {/* TODO: temporary until all brands desktop/mobile are on 4.2 */}
                {featureFlags['is-alternate-url-enabled'] && brand && pathname && <link rel="alternate" media="only screen and (max-width: 640px)" href={setRelAlternateUrl()} />}
                {og && og.length > 0 && Array.isArray(og) && og.map((d) => (<meta key={d.property} property={d.property} content={d.content} />))}
            </Helmet>
        );
    }
    return null;
};

GraphqlDesktopSeoHeadTags.propTypes = {
    seoData: object.isRequired,
    brand: object.isRequired,
    location: object.isRequired,
    featureFlags: object.isRequired,
    alternativeLang: bool,
    presentationFamily: string.isRequired,
};

GraphqlDesktopSeoHeadTags.defaultProps = {
    alternativeLang: false,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    presentationFamily: getPresentationFamily(state),
    featureFlags: getFeatureFlags(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
);

export default enhance(GraphqlDesktopSeoHeadTags);
