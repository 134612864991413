/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    headerBlock: {
        imageTitle: 'Gift Ideas for Pet Lovers',
        imagePath: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2019/mday/articles/dog-lips-yellow-tulips-gifts-for-pet-lovers-moms-394x376.jpg',
        description: 'Most people would agree that pets are part of the family, so we&#44;ve come up with some unique gift ideas to gift from the family pet(s)&#33; Whether their furry (or not so furry) child is one of many kids, or the only one, getting a gift from your dog, cat, bird, guinea pig, snake, fish, turtle, ferret, rabbit...well, you get the idea... is a great way to deliver an unexpected smile to the pet lover in your family.',
        imageLink: '/mothers-day-flowers',
    },
    dogShapedFlowers: {
        dogDesc: 'Our a&#45;DOG&#45;able arrangements, featuring dog shaped flowers, allows you to send puppy owners flowers that will remind them of their furry friends&#33; This flower pup will be sure to make your pet lover smile. This adorable pup was designed inside a reusable handled basket. This customer favorite floral creation makes a great gift for pet lovers or anyone who could use a little puppy love in their life.',
        dogLink: '/a-dog-able-11806',
        dogTitle: 'Adogable or Dog shaped Flowers',
        dogImage: [
            {
                imagePath: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/103911x.jpg',
                imageTitle: 'Love Pup&#8482;',
                imageLink: '/lucky-love-her-103911?categoryId=400067504',

            }, {
                imagePath: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/167692x.jpg',
                imageTitle: 'Another Year Rover Birthday Bouquet',
                imageLink: '/florist-delivered-another-year-rover-167692?categoryId=400067504',

            },
        ],
    },
    catShapedFlowers: {
        catDesc: 'Our floral felines are the perfect gift for all cat&#45;lovers&#33; This truly original kitten floral arrangement is sure to leave them smitten!',
        catLink: '/fabulous-feline',
        catTitle: 'Purrrfect Cat Shaped Flowers',
        catImage: [
            {
                imagePath: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/147205x.jpg',
                imageTitle: 'Fabulous Feline&#8482;',
                imageLink: '/florist-designed-fabulous-feline-147205?categoryId=400192986',

            }, {
                imagePath: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148651x.jpg',
                imageTitle: 'Purrfect Party Cat&#8482;',
                imageLink: '/florist-delivered-purrfect-party-148651',

            },
        ],
    },
    catAndDogPlanters: {
        desc: 'These cat and dog planters make the best décor&#33; These planters can be used outdoors or indoors. Put them on your porch to display to neighbors and guests or show them proudly in your home as your newest four-legged friend.',
        link: '/my-pet-plant-101054',
        title: 'Cat &#38; Dog Planters',
        image: {
            imagePath: '//cdn4.1800flowers.com/wcsstore/Flowers/images/catalog/101054dga1x.jpg',
            imageTitle: 'My Pet Plant &#45; Dog or Cat',
            imageLink: '/my-pet-plant-101054',

        },
    },
    featheredFriends: {
        desc: 'If they prefer feathered friends or love gardening and the outdoors, a birdhouse for their yard would be a great gift for years to come! Our Home Tweet Home Birdhouse will attract their favorite little feathered friends, which is great for bird watching and garden tranquility.',
        link: '/home-birdhouse-139484',
        title: 'Gifts for Those Who Love Feathered Friends',
        image: {
            imagePath: '//cdn4.1800flowers.com/wcsstore/Flowers/images/catalog/139484x.jpg',
            imageTitle: 'Home Tweet Home Birdhouse',
            imageLink: '/home-birdhouse-139484',

        },
    },
    perfectGifts: {
        desc: 'It&#44;s no secret that pet owners LOVE showing off their fur babies to just about anyone who is interested&#33; Whether it&#44;s posting a pet picture on social media or showing pet images on their phone. A Keepsake Frame in honor of their favorite fur child is the fastest way to their heart. This proud pet owner can show off their fuzzy friend, in their picture frame, while at work or at home&#33; Go the extra mile with our sentimental collectible that your loved one will always cherish&#33;',
        link: '/gifts-furry-friends-frame-155408',
        title: 'Perfect Gifts for the Proud Dog or Cat Owner',
        image: {
            imagePath: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/155408dx.jpg',
            imageTitle: 'Furry Friends Keepsake Frame',
            imageLink: '/gifts-furry-friends-frame-155408',

        },
    },
    stuffed: {
        desc: 'This <a href="/bearington-bear-blue-without-you-plush-139024"> Huggable Bearington&#174; Miss You Bear</a> is an awesome gift to remind your pet lover that their animal misses them too&#33; It is paired with a box of delicious Cheryl&#44;s cookies to show just how sweet you can be&#33;',
        link: '/bearington-bear-blue-without-you-plush-139024',
        title: 'Stuffed Bears and Jewelry&#44; Oh My&#33;',
        image: {
            imagePath: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/139024x.jpg',
            imageTitle: 'Bearington&#174; Miss You Bear with Cheryl&#44;s Cookies',
            imageLink: '/bearington-bear-blue-without-you-plush-139024',

        },
    },
};
export default data;
