/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object,
} from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../gql';
import CustomerServiceBuilder from './CustomerServiceBuilder';
import CustomerServiceSkeleton from '../GraphqlSkeletonComponents/CustomerServiceSkeleton';
import { setLocale } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';

const CustomerServiceContainer = ({ brand, page }) => {
    const CUSTOMER_SERVICE_QUERY = gql`
        query CustomerService {
            findContent(brand:"${brand['domain-name']}", environment:"${GRAPHQL_ENV}", contentType:"${page.contentType}", locale: "${setLocale()}"){
                content
            }
        }
    `;

    return (
        <Query query={CUSTOMER_SERVICE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <CustomerServiceSkeleton />; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        query: CUSTOMER_SERVICE_QUERY,
                        component: 'CustomerService.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: CUSTOMER_SERVICE_QUERY,
                        component: 'CustomerService.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                let customerServiceData = null;
                if (data?.findContent?.content !== null) {
                    if (data?.findContent?.content?.entries?.length > 0) {
                        customerServiceData = data?.findContent?.content?.entries?.[0];
                    }
                }
                return (
                    <>
                        {customerServiceData ? <CustomerServiceBuilder brand={brand} page={page} data={customerServiceData} /> : 'No data returned for query'}
                    </>
                );
            }}
        </Query>
    );
};

CustomerServiceContainer.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
};

export default CustomerServiceContainer;
