/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { object, shape, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import VeteransAdvantageBlock from './VeteransAdvantageBlock';
import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';

const brandSelectors = app.ducks.brand.selectors;

const VeteransAdvantagePage = ({ brand, page }) => {
    const Veterans_Advantage_PAGE_QUERY = gql`
            query VeteransAdvantageQuery {
                findContentPage(brand: "${brand['domain-name']}", url: "${page.path}", contentType: "${page.contentType}", environment: "${GRAPHQL_ENV}", locale: "en-us") {
                    content
                }
            }
        `;

    return (
        <Query query={Veterans_Advantage_PAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return null; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        query: Veterans_Advantage_PAGE_QUERY,
                        component: 'VeteransAdvantage.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: Veterans_Advantage_PAGE_QUERY,
                        component: 'VeteransAdvantage.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                return (
                    <>
                        <VeteransAdvantageBlock data={data} />
                    </>

                );
            }}
        </Query>
    );
};

VeteransAdvantagePage.propTypes = {
    brand: object.isRequired,
    page: shape({
        contentType: string.isRequired,
        path: string.isRequired,
    }).isRequired,
};
const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});

export default withRouter(connect(mapStateToProps)(VeteransAdvantagePage));
