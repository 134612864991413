/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const ErrorLanguages = (error) => {
    const isEmpty = /^"([^"]+)".*empty/;
    const maxLength = /^"[^"]+".*(ust be at least \d+ characters long)/;
    const matchCharsPattern = /^"[^"]+".*(ails to match the .+ pattern)/;
    const invalidEmail = /^"[^"]+".*must be a (valid email)/;
    let errorMessage = error;
    if (isEmpty.test(error)) {
        errorMessage = ''.concat(error.replace(isEmpty, '$1'), ' is a required field');
    } else if (maxLength.test(error)) {
        errorMessage = ''.concat('M', error.replace(maxLength, '$1'));
    } else if (matchCharsPattern.test(error)) {
        errorMessage = ''.concat('F', error.replace(matchCharsPattern, '$1'));
    } else if (invalidEmail.test(error)) {
        errorMessage = ''.concat('In', error.replace(invalidEmail, '$1'));
    }
    return errorMessage;
};

export default ErrorLanguages;
