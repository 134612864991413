/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import Flowers from '../blocks/flowers';

const MobileFuneralFlowers = ({ data, classes }) => {
    const {  rightSidebar: { sympathyFlowers }, leftSidebar: { funeralFlowers, advice: { link, img } }  } = data;
    const seed = useUIDSeed();
    return (
        <Grid container className={`${classes.container} ${classes.marginTopAndBottom}`}>
            <Grid className={classes.borderRight} item xs={12}>
                <Grid container>
                    { sympathyFlowers.map((block) => (<Flowers data={block} key={seed(block)} />))}
                </Grid>
                <Grid container>
                    <Link to={link} className={classes.linkWidth}>
                        <img src={img} className={classes.adviceImage} alt="Looking for Advice" />
                    </Link>
                </Grid>
            </Grid>
            <Link to="/allfuneralarrangements" className={classes.linkWidth}> <h2 className={classes.seperator}>Funeral Flowers for the Service</h2> </Link>
            <p className={classes.sectionHeader}>A selection of arrangements customarily sent to the funeral home.</p>
            <Grid item xs={12}>
                <Grid container>
                    { funeralFlowers.map((value) => (
                        <Flowers data={value} key={seed(value)} />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
MobileFuneralFlowers.propTypes = {
    classes: object.isRequired,
    data: shape({
        arrowImage: string,
        disclaimer: shape({
            copy: arrayOf(
                shape({
                    description: string,
                    heading: string,
                }),
            ),
            mainHeading: string,
            subCopy: arrayOf(
                shape({
                    description: string,
                    heading: string,
                }),
            ),
        }),
        leftSidebar: shape({
            advice: shape({
                img: string.isRequired,
                link: string.isRequired,
            }).isRequired,
            funeralFlowers: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }).isRequired,
            ).isRequired,
            funeralFlowersColor: arrayOf(
                shape({
                    img: string,
                    label: string,
                    link: string,
                }),
            ),
        }).isRequired,
        rightSidebar: shape({
            hopeBanner: shape({
                img: string,
                link: string,
            }),
            serviceBanner: shape({
                content: string,
                heading: string,
                img: string,
            }),
            sympathyFlowers: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
    }).isRequired,
};

export default MobileFuneralFlowers;
