/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { object, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { compose } from 'recompose';

import { trackPage } from '../../../helpers/tracking/common/commonTrackingHelpers';
import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';
import SympathyBlock from './blocks/index';
import SympathyMobileBlock from './mobileblocks/mobileIndex';
import SympathyData from './ViewData'; // This is only for 18F brand.
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';

const brandSelectors = app.ducks.brand.selectors;
const page = {
    path: '/Sympathy',
};
const SympathySEO = (seo) => (
        seo?.seo?.seo_searchable
            ? (
                <GraphqlSeoHeadTags seoData={seo?.seo} />
            ) : null
);

const GraphqlSympathyContainer = ({ brand, presentation_family }) => {
    const SYMPATHY_PAGE_QUERY = gql`
            query SympathyPageQuery {
                findContentPage(brand: "${brand['domain-name']}", contentType: "standard_collections", url: "${page.path}", environment: "${GRAPHQL_ENV}", locale: "en-us") {
                    content
                }
            }
        `;

    return (
        <Query query={SYMPATHY_PAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return null; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        query: SYMPATHY_PAGE_QUERY,
                        component: 'GraphqlSympathyContainer.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: SYMPATHY_PAGE_QUERY,
                        component: 'GraphqlSympathyContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                if (!data.findContentPage.content) {
                    mbpLogger.logWarning({
                        query: SYMPATHY_PAGE_QUERY,
                        component: 'GraphqlSympathyContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                let SEO = {};
                if (typeof data?.findContentPage?.content?.entries?.[0]?.seo !== 'undefined') {
                    SEO = data?.findContentPage?.content?.entries?.[0]?.seo;
                }

                return (
                    <>
                        <SympathySEO seo={SEO} />
                        <>
                            <DesktopMobile
                                desktop={() => <SympathyBlock data={SympathyData} seo={SEO} page={page} presentationFamily={presentation_family} />}
                                mobile={() => <SympathyMobileBlock seo={SEO} data={SympathyData} />}
                            />
                        </>
                    </>
                );
            }}
        </Query>
    );
};

GraphqlSympathyContainer.propTypes = {
    brand: object.isRequired,
    presentation_family: string.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
    presentation_family: getPresentationFamily(state),
});

const enhance = compose(
    trackPage(),
    withRouter,
    connect(mapStateToProps),
);

export default enhance(GraphqlSympathyContainer);
