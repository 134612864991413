/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReactHtmlParser from 'react-html-parser';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { object, bool, func } from 'prop-types';

const styles = () => ({
    closeIcon: {
        top: '0px',
        right: '10px',
        cursor: 'pointer',
        position: 'absolute',
        fontSize: '24px',
    },
    header: {
        fontWeight: '700',
        fontSize: '24px',
        '& p': {
            margin: '0',
        },
    },
    dialogMain: {
        '& div': {
            '& div': {
                borderRadius: 0,
            },
        },
    },
});
const closeClick = (openClick) => {
    openClick({
        trigger: false,
        data: {},
    });
};

const ReadMoreDialog = ({
    classes, show, openClick, data,
}) => (
    <Dialog maxWidth="md" className={classes.dialogMain} open={show}>
        <DialogTitle className={classes.title}>
            <span role="presentation" onClick={() => { closeClick(openClick); }}>
                <span className={classes.closeIcon}>
                    x
                </span>
            </span>
        </DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={6}>
                    <img src={data.pop_image} alt="" />
                </Grid>
                <Grid item xs={6}>
                    {  data.pop_header ? <span className={classes.header}> {ReactHtmlParser(data.pop_header)} </span> : null}
                    {ReactHtmlParser(data.pop_description)}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
);

ReadMoreDialog.propTypes = {
    classes: object.isRequired,
    show: bool.isRequired,
    data: object.isRequired,
    openClick: func.isRequired,
};
export default withStyles(styles)(ReadMoreDialog);
