/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React,  { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, array,
} from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ReadMoreDialog from './ReadMoreDialog';

const styles = (theme)  => ({
    productName: {
        '& a': {
            fontSize: '1em',
            color: theme.palette.common.black,
            letterSpacing: 0.4,
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    productPrice: {
        '& a': {
            fontSize: '1em',
            color: theme.palette.sympathyProductNameColor,
            fontWeight: 'normal',
            letterSpacing: 0.4,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    productShipping: {
        '& a': {
            fontSize: '1em',
            color: theme.palette.colorPrimary,
            letterSpacing: 0.4,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    productImageHover: {
        '&:hover': {
            transform: 'scale(1.015)',
        },
    },
    productRow: {
        margin: '40px 0px',
        padding: '0px 10px',
        position: 'relative',
        '& img': {
            width: '100%',
            '&:hover': {
                transform: 'scale(1.015)',
            },
        },
    },
    simpleProduct: {
        margin: '40px 0px',
        padding: '0px 10px',
        position: 'relative',
        '& img': {
            width: '100%',
        },
        '&:hover': {
            transform: 'scale(1.015)',
        },
    },
    productInner: {
        position: 'absolute',
        bottom: 5,
        width: '85%',
        textAlign: 'center',
        background: theme.palette.common.white,
        padding: '0 10px',
        display: 'block',
        left: '7%',
    },
    productHeader: {
        fontSize: 24,
        fontWeight: 700,
    },
    readMore: {
        fontSize: 16,
        fontWeight: 100,
        cursor: 'pointer',
    },
    productSalePrice: {
        '& span': {
            textDecoration: 'line-through',
        },
        '& a': {
            color: theme.palette.common.red,
            fontWeight: 'bold',
        },
    },
    productBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '0px 10px',
        '&:hover': {
            padding: '0px 2px',
        },
    },
});
const OrchidsDeliveryProducts = ({ productList, classes }) => {
    const [popDataRequest, openClick] = useState({
        trigger: false,
        data: {},
    });
    const { trigger, data } = popDataRequest;
    const seed = useUIDSeed();
    return  (
        <Grid container>
            <ReadMoreDialog show={trigger} openClick={openClick} data={data} />
            {productList.map((product) => {
                const {
                    product_background_image,  product_sale_price,  product_header, product_desc, product_link,  product_image, product_name, product_price, product_Shipping, product_popup_data,
                } = product;
                return (
                    product_popup_data ? (
                        <Grid key={seed(product)} xs={4} item className={classes.simpleProduct}>
                            <img src={product_image} alt={product_header} />
                            <div className={classes.productInner}>
                                <span className={classes.productHeader}> {ReactHtmlParser(product_header)}</span>
                                <span className={classes.productDesc}>{ReactHtmlParser(product_desc)}</span>
                                <span
                                    className={classes.readMore}
                                    role="presentation"
                                    onClick={() => openClick({
                                        trigger: true,
                                        data: product_popup_data,
                                    })}
                                >Read More &#43;
                                </span>
                            </div>
                        </Grid>
                    )
                        : (
                            <Grid key={seed(product)} xs={4} item className={classes.productRow}>
                                {product_background_image
                                    ? (
                                        <Link to={product_link}>
                                            <div className={classes.productImageHover}>
                                                <img className={classes.productBackground} src={product_background_image} alt={product_name} />
                                                <img src={product_image} alt={product_name} />
                                            </div>
                                        </Link>
                                    )
                                    :                                                                       (
                                        <Link to={product_link}>
                                            <img src={product_image} alt={product_name} />
                                        </Link>
                                    )}

                                <div className={classes.productName}>
                                    <Link to={product_link}>  <span>{product_name}</span> </Link>
                                </div>
                                <div className={classes.productPrice}>
                                    {product_sale_price
                                        ? (
                                            <div className={classes.productSalePrice}>
                                                <span>{product_price}</span>
                                                <Link to={product_link}> {ReactHtmlParser(product_sale_price)} </Link>
                                            </div>
                                        )
                                        : <Link to={product_link}>   <span>{product_price}</span> </Link>}

                                </div>
                                <div className={classes.productShipping}>
                                    <Link to={product_link}> <span>{product_Shipping}</span> </Link>
                                </div>
                            </Grid>
                        )
                );
            })}
        </Grid>
    );
};

OrchidsDeliveryProducts.propTypes = {
    classes: object.isRequired,
    productList: array.isRequired,
};
export default withStyles(styles)(OrchidsDeliveryProducts);
