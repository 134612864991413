/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import gql from 'graphql-tag';

/**
 * @param {string} brand - baskets or berries
 * @param {string} searchTerm - find me
 * @param {boolean} isElasticSearchEnabled - am I elastic or SOLR
 */

const findProductsBySearchTerm = () => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`
    query SearchProductQuery($brand: String!, $productOptions: ProductOptions, $environment: String!, $term: String!, $useES: String) {
        findProductsBySearchTerm(brand: $brand, environment: $environment, term: $term, productOptions: $productOptions, useES: $useES) {
            redirectUrl
            products {
                id
                image {
                    altText
                    name
                    path
                    snipe
                    __typename
                }
                partNumber
                baseCode
                prices {
                    currency
                    type
                    value
                    __typename
                }
                skuPriceRange {
                    sale {
                        value
                        __typename
                    }
                    retail {
                        value
                        __typename
                    }
                }
                name
                brand
                seo {
                    url
                    __typename
                }
                availability {
                    availabilityIndicator
                    deliveryDateType
                    displayEndDate
                    displayStartDate
                    earliestShipDate
                    latestShipDate
                    perishable
                    productDeliveryType
                    shipAlone
                    deliveryMessage
                    __typename
                }
                __typename
            }
            totalPages
            totalProducts
        }
    }
`
);

export default findProductsBySearchTerm;
