/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'You&apos;re all set!',
    subTitle: 'An agent will email you soon. You can also check out these questions and videos.',
    banner: [
        {
            title: 'Sending...',
        },
        {
            title: 'Adding to agent queue...',
        },
        {
            title: 'Agent received message...',
        },
    ],
    list: [
        {
            name: 'What to Expect After Ordering Flowers Online',
            imageLink: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/help/vid-delivery.jpg',
            videoLink: 'https://www.youtube.com/embed/kFl_eTIPq9w',
        },
        {
            name: 'Flower Delivery &amp; Shipping Charge FAQs',
            imageLink: '//cdn1.1800flowers.com/wcsstore/Flowers/images/banners/help/vid-passport.jpg',
            videoLink: 'https://www.youtube.com/embed/ivRhCERu4p8',
        },
    ],
};

export default data;
