/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */
const data = {
    customer_banner_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/message_to_customers.png',
    ceo_information: {
        ceo_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/Chris-Image-Desktop.jpg',
        ceo_title: 'Staying Connected is More Important Than Ever',
        ceo_description: [
            {
                ceo_description_section: 'During this time of anxiety and uncertainty, I wanted to reach out to say we are thinking of you. As we navigate the current health crisis together, I also wanted to let you know that our company continues to prioritize the safety and well-being of our team members, their families and our customers.',
            },
            {
                ceo_description_section: 'We value the role we play in helping you express, connect and celebrate with the important people in your life. In this unprecedented moment, being connected with each other is more important than ever. While we may be practicing social distancing, staying healthy means not falling into an unhealthy pattern of social isolation. Taking care of our emotional well-being, prioritizing self-care and staying engaged with the important people in your life are among the most critical things we can do right now.',
            },
            {
                ceo_description_section: 'Whether you are telecommuting, taking care of children home from school, enduring quarantine, or simply being cautious, here are just a few ways you might consider connecting with friends, family, co-workers and others in the days and weeks ahead:',
            },
        ],
        ceo_description_list: [
            {
                option: 'Video chat versus texting - seeing your face will bring a big smile',
            },
            {
                option: 'Send a complimentary e-card - “Just saying ‘hi’”',
            },
            {
                option: 'Make a list of 10 people you’ve been meaning to call – and do it',
            },
            {
                option: 'Call a neighbor or distant relative, just to see how they are doing',
            },
            {
                option: 'Write a hand-written letter to an old friend',
            },
        ],
        ceo_description_bottom: 'Now is a time to think of each other, to connect with each other and to care for each other. We just want you to know that we care about you and your continued well-being, and that all of us at 1-800-FLOWERS.COM, Inc. are thinking of you.',
        ceo_name: '<p>Chris McCann<br>Chief Executive Officer<br>1-800-FLOWERS.COM, Inc.</p>',
        ceo_signature: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/ChrisMcCannSignature.svg',
    },
    health_and_safety: {
        health_title: 'Health & Safety Measures:',
        health_description: 'As we navigate the current health crisis together, we continue to prioritize the safety and well-being of our team members, their families and our customers. As always, we are here to help and support you in sending smiles and expressing yourselves in these unprecedented times.',
        section_one: {
            section_one_title: 'Deliveries:',
            section_one_description_list: [
                {
                    description: 'Across our 1-800-FLOWERS.COM, Inc. family of brands, gifts are delivered by one of our reputable carriers including FedEx, UPS and United States Postal Service, or by a local florist or shop.',

                },
                {
                    description: 'As a measure of social distancing and to minimize any potential risk of exposure for both our carriers and our customers, all deliveries will not require a signature.',
                },
                {
                    description: 'The World Health Organization has <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses?cmp=EMC-1005333-27-1-200-0010000-US-US-EN-FY20FXOCOVID190&ET_CID=765895&ET_RID=67582340&LINK=WHO_CORONAVIRUS">provided guidance</a> that the risk of COVID-19 infection from a package that has moved, travelled, or been exposed to different conditions and temperatures is low.',
                },
                {
                    description: 'We are working closely with our carriers to responsibly manage this evolving situation. You can find detailed information about the steps each of our reputable carriers are taking during this time on the below sites:',
                },
                {
                    description: '<a href="https://www.fedex.com/en-us/coronavirus.html?cmp=EMC-1005333-27-1-200-0010000-US-US-EN-FY20FXOCOVID190&ET_CID=765895&ET_RID=67582340&LINK=FEDEX_CORONAVIRU">FedEx</a>',
                },
                {
                    description: '<a href="https://www.ups.com/us/en/about/news/important-updates.page">UPS</a>',
                },
                {
                    description: '<a href="https://about.usps.com/newsroom/statements/usps-statement-on-coronavirus.htm">USPS</a>',
                },
                {
                    description: 'We have provided our network of local florists with the following delivery protocol guidelines:',
                },
                {
                    description: 'Leaving an arrangement unattended (e.g. on the porch) will be an acceptable form of delivery, with the exception of food items.',
                },
                {
                    description: 'All employees should practice responsible sanitation and hand-washing',
                },
                {
                    description: 'Delivery vehicles should be properly ventilated throughout the day and in clean condition',
                },
                {
                    description: 'Announce arrival by ringing bell or knocking on the door, but practice touchless delivery',
                },
                {
                    description: 'Deliveries to non-residential addresses, like hospitals and schools, may be impacted by health and safety restrictions due to the evolving pandemic. We are monitoring this closely and will be in touch with you if we cannot deliver your gift to this address.',
                },
            ],
        },
        section_two: {
            section_two_title: ' Food & Floral Handling:',
            section_two_description_list: [
                {
                    description: 'Please be assured that the 1-800-FLOWERS.COM, Inc. family of brands has robust hygiene, food handling and storage practices for our products that are intended to maintain their integrity and ensure their safe use. In light of COVID-19, not only are we following those robust practices, they are now receiving an even greater focus. Currently, both the FDA and CDC consider the risk of transmission through food to be very low. In fact, the FDA has stated that they are not aware of any reports of transmission of COVID-19 through food, and the CDC has said that there is no evidence of food supporting transmission of COVID-19. ',
                },
                {
                    description: 'We are in frequent communication with all of our suppliers to confirm they are working in accordance with the federal, state and local food safety laws, as well as recent guidance and precautions issued by the CDC. As a company, we take food safety very seriously and continually monitor to ensure all of our facilities are practicing Good Manufacturing Practices (GMP&apos;s) as defined by the FDA which include, but are not limited to, sanitizing, hand washing/drying, wearing gloves and hair restraints.',
                },
                {
                    description: 'Our floral fulfillment locations follow Good Manufacturing Practices (GMPs) as defined by the FDA. These GMPs include, but are not limited to, social distancing, sanitizing, hand washing/drying, wearing gloves, restrictions against eating or drinking at work stations, instructions to employees that employees should not come to work if they are sick or not feeling well, or employees being sent home if they become ill while working.',
                },
            ],
        },
        bottom_content: 'We continue to monitor the situation closely and hope that you will find this information useful and reassuring. We encourage you to continue to seek out credible sources, such as the CDC, regarding this topic. The CDC web site is located at <a href="https://www.cdc.gov/">www.cdc.gov</a>',
    },
};

export default data;
