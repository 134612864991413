/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';

const PageHeading = ({ classes }) => (
    <Grid container className={classes.container}>
        <Grid item xs={3}>
            <div className={classes.headingBlock}>
                <h1 className={classes.heading}>
                    Sympathy Flowers & Arrangements Delivery
                </h1>
            </div>
        </Grid>

        <Grid item xs={9}>
            <p className={classes.subHeading}>
                <span>Trust us to help you express your condolences, giving comfort and support. We offer <Link to="/allfuneralarrangements"> funeral flowers </Link> for the service, handcrafted by our caring florists, and sympathy & remembrance gifts for the home.</span>
                <span><b><Link to="/sympathy-articles">Click Here  for Additional Sympathy Resources</Link>, Advice and FAQs</b></span>
            </p>
        </Grid>
    </Grid>
);

PageHeading.propTypes = {
    classes: object.isRequired,
};

export default PageHeading;
