/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
  * The data in this file is hardcoded only for 18F brand
  */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import MobilePageHeading from './MobilePageHeading';
import MobileFuneralFlowers from './MobileFuneralFlowers';
import PageSubheading from '../blocks/PageSubheading';
import MobileSympathyFlowers from './MobileSympathyFlowers';
import GraphqlBreadcrumbs from '../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';

const styles = (theme) => ({
    container: {
        fontFamily: 'Roboto',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    heading: {
        fontSize: '16px',
        textTransform: 'uppercase',
        lineHeight: '1.2',
        width: '70%',
    },
    readMore: {
        color: theme.palette.sympathyPage?.linkColor,
        fontSize: '0.8rem',
        fontWeight: 900,
        letterSpacing: '.7px',
        textTransform: 'uppercase',
        width: '20%',
        height: '100%',
        margin: '10px 0',
    },
    seperator: {
        padding: '8px',
        backgroundColor: theme.palette.sympathyPage?.headingColor,
        margin: '8px 0',
        color: 'white',
        fontSize: '16px',
        width: '100%',
    },
    headerImageContainer: {
        display: 'flex',
        height: '125px',
        width: '95%',
        margin: '8px auto',
        justifyContent: 'space-evenly',
        marginBottom: '8px',
    },
    headerImage: {
        width: '30%',
        maxWidth: '150px',
        margin: '0 auto',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
        color: '#65388b',
        position: 'relative',
        '& > img': {
            height: '100%',
        },
        '& > span': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
    },
    subHeading: {
        margin: '15px 0 0 30px',
        minHeight: 0,
        height: 'auto',
        transitionDuration: '302ms',
        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& > span': {
            display: 'block',
            lineHeight: 1.4,
            marginBottom: 5,
        },
        '& a': {
            color: theme.palette.sympathyPage?.linkColor,
        },
    },
    sectionHeader: {
        marginLeft: '2.5%',
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'unset',
    },
    textSameDay: {
        textAlign: 'center',
        width: '100%',
        padding: 15,
        margin: 0,
        '& div': {
            fontSize: 15,
        },
        '& b': {
            color: theme.palette.sympathyPage?.headingColor,
        },
    },
    textRight: {
        textAlign: 'center',
        marginBottom: 25,
    },
    fontWeightSmall: {
        fontWeight: 'normal',
    },
    mainImage: {
        background: theme.palette.sympathyPage?.buttonColor,
        color: theme.palette.white,
        '&:hover': {
            color: theme.palette.white,
            background: theme.palette.sympathyPage?.buttonColor,
        },
        padding: '10px 15px',
        textDecoration: 'none',
        fontWeight: 700,
        '& img': {
            width: '100%',
            paddingLeft: 10,
            maxWidth: 18,
        },
    },
    marginTopAndBottom: {
        margin: '15px auto',
    },
    paddingLeft: {
        padding: '0 10px',
    },
    marginTop: {
        marginTop: 25,
    },
    adviceImage: {
        width: '100%',
        padding: '10px',
    },
    linkWithShop: {
        textAlign: 'center',
        fontWeight: 700,
        '& > a': {
            color: theme.palette.sympathyPage?.buttonColor,
            textDecoration: 'none',
        },
    },
    fixed: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        background: theme.palette.white,
    },
    h3: {
        color: theme.palette.common?.black,
        fontFamily: 'Roboto, san-serif',
        fontWeight: 200,
        fontSize: 24,
        width: '90%',
        margin: '0 auto',
    },
    p: {
        margin: '10px 0',
        fontSize: 13,
        color: theme.palette.common?.black,
    },
    description: {
        display: 'flex',

    },
    disclaimerHeading: {
        fontSize: 16,
        width: '100%',
        margin: '0',
        fontWeight: 500,
        color: theme.palette.sympathyPage?.headingColor,
    },
    disclaimerDesc: {
        margin: 10,
        lineHeight: '17px',
        color: theme.palette.sympathyProuctNameColor,
        fontSize: 14,
        '& a': {
            color: theme.palette.sympathyPage?.linkColor,
        },
    },
    serviceBlock: {
        margin: 3,
        '& h3': {
            textAlign: 'center',
        },
        '& p': {
            margin: '0 auto',
            fontSize: 13,
        },
    },
    hopeBanner: {
        '& img': {
            width: '100%',
            padding: '5px',
        },
    },
    subCopy: {
        backgroundColor: theme.palette.sympathyPage?.subCopyBg,
        border: `1px solid ${theme.palette.sympathyPage?.subCopyBorderColor} `,
        padding: '10px',
        marginTop: 25,
    },
    mainHeading: {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.sympathyPage?.headingColor,
        paddingTop: 15,
        paddingBottom: 15,
    },
    backToTopContainer: {
        width: '100%',
        textAlign: 'center',
        margin: '12px auto',
    },
    backToTop: {
        color: theme.palette.colorPrimary,
        textDecoration: 'underline',
        fontSize: 16,
    },
    link: {
        textDecoration: 'none',
    },
    faqDes: {
        margin: '10px 0',
    },
    normalHeading: {
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    linkWidth: {
        width: '100%',
    },
});
const mdParser = (answer) => answer.replace(/[\][]/gi, '').replace(/<\/?a[^>]*>/g, '');
// ld+json schema
const FaqSeoSchema = ({ faqArray }) => (
    <Helmet>
        <script type="application/ld+json">
            {`{"@context": "https://schema.org","@type": "FAQPage","mainEntity": [${faqArray && faqArray.map((faqData) => (`{"@type": "Question","name": "${faqData.heading}","acceptedAnswer": {"@type": "Answer","text": "${mdParser(faqData.description)}"}}`))}]}`}
        </script>
    </Helmet>
);
FaqSeoSchema.propTypes = {
    faqArray: arrayOf(shape({
        question: string,
        answer: string,
    })).isRequired,
};

const collectionSeoSchema = () => (
    <Helmet>
        <script type="application/ld+json">
            {'{"@context":"http://schema.org","@type":"CollectionPage","name":"Sympathy Flowers","description":"Trust 1800Flowers sympathy flowers & arrangements to express your condolences. Order sympathy flowers for delivery online for the service or to the home.","primaryImageOfPage":{"@type":"ImageObject","url":"//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg"},"image":{"@type":"ImageObject","url":"//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg"}}'}
        </script>
    </Helmet>
);
class SympathyMobileBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = false;
    }

     disclaimer =(data, classes, isFaq) => {
         if (data.length > 0) {
             return (data.map((item) => {
                 const { heading, description } = item;
                 if (isFaq) {
                     return (
                         <div key={heading}>
                             <h3 className={classes.disclaimerHeading}>{heading}</h3>
                             <div className={`${classes.disclaimerDesc} ${classes.faqDes}`}>{ ReactHtmlParser(description)} </div>
                         </div>
                     );
                 }
                 return (
                     <div key={heading}>
                         <div className={classes.seperator}><h2 className={`${classes.disclaimerHeading} ${classes.normalHeading}`}>{heading}</h2></div>
                         <div className={classes.disclaimerDesc}>{ ReactHtmlParser(description)} </div>
                     </div>
                 );
             })
             );
         }
         return null;
     }

     scrollTop = () => {
         if (typeof window !== 'undefined') {
             window.scroll({
                 top: 0,
                 left: 0,
                 behavior: 'smooth',
             });
         }
     };

     render() {
         const { data, classes, seo } = this.props;
         return (
             <>
                 <MobilePageHeading classes={classes} />
                 <div className={classes.seperator} />
                 <div className={classes.headerImageContainer}>
                     <Link className={classes.headerImage} to="/allsympathyflowersgifts"> <img src="//cdn4.1800flowers.com/wcsstore/Flowers/images/catalog/167530lx.jpg" alt="Home &amp; Office" /> <span>Home &amp; Office</span> </Link>
                     <Link className={classes.headerImage} to="/sympathyplants"> <img src={data?.leftSidebar?.funeralFlowers?.[1]?.img} alt="Plants" /> <span>Plants</span> </Link>
                     <Link className={classes.headerImage} to="/allfuneralarrangements"> <img src={data?.leftSidebar?.funeralFlowers?.[4]?.img} alt="Funeral" /> <span>Funeral</span> </Link>
                 </div>
                 <PageSubheading classes={classes} />
                 <Link to="/allsympathyflowersgifts" className={classes.link}> <h2 className={classes.seperator}>Sympathy Flowers & Gifts for the Home or Office</h2> </Link>
                 <p className={classes.sectionHeader}>Flowers, plants, remembrance gifts & comforting gourmet food.</p>
                 <MobileFuneralFlowers data={data} classes={classes} />
                 <Link to="/allfuneralarrangements" className={classes.link}> <h2 className={classes.seperator}>Shop by Funeral Flower Color</h2> </Link>
                 <MobileSympathyFlowers data={data} classes={classes} />
                 <Grid container className={`${classes.container}`}>
                     <Grid item xs={12}>
                         <div className={classes.copy}> {this.disclaimer(data?.disclaimer?.copy, classes, false)}  </div>
                         <div className={classes.subCopy}>
                             <FaqSeoSchema faqArray={data.disclaimer.subCopy} />
                             <h2 className={classes.mainHeading}>{data?.disclaimer?.mainHeading} </h2>
                             {this.disclaimer(data?.disclaimer?.subCopy, classes, true)}
                         </div>
                         {collectionSeoSchema()}
                         <GraphqlBreadcrumbs breadCrumbArray={seo.breadcrumb} />
                         <div className={classes.backToTopContainer}>
                             <span className={classes.backToTop} role="presentation" onClick={() => this.scrollTop()}>Back to top</span>
                         </div>
                     </Grid>
                 </Grid>
             </>
         );
     }
}

SympathyMobileBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        arrowImage: string,
        disclaimer: shape({
            copy: arrayOf(
                shape({
                    description: string.isRequired,
                    heading: string.isRequired,
                }).isRequired,
            ).isRequired,
            mainHeading: string.isRequired,
            subCopy: arrayOf(
                shape({
                    description: string.isRequired,
                    heading: string.isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
        leftSidebar: shape({
            advice: shape({
                img: string.isRequired,
                link: string.isRequired,
            }).isRequired,
            funeralFlowers: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }).isRequired,
            ).isRequired,
            funeralFlowersColor: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
        rightSidebar: shape({
            hopeBanner: shape({
                img: string.isRequired,
                link: string.isRequired,
            }).isRequired,
            serviceBanner: shape({
                content: string.isRequired,
                heading: string.isRequired,
                img: string.isRequired,
            }).isRequired,
            sympathyFlowers: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
    }).isRequired,
    seo: shape({
        title: string.isRequired,
        href: string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(SympathyMobileBlock);
