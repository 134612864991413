/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { determineLanguagePrefixPath } from '../../../../helpers/determineInternationalizationPath';

const styles = (theme)  => ({
    customerOptions: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            marginTop: '15px',
        },
        '&> div': {
            '&:nth-child(odd)': {
                display: 'block',
                '& a': {
                    [theme.breakpoints.up(993)]: {
                        marginLeft: 0,
                    },
                },
            },
            '&:nth-child(even)': {
                '& a': {
                    [theme.breakpoints.up(993)]: {
                        marginRight: 0,
                    },
                },
            },
        },
    },
    customerOption: {
        color: theme.palette.common?.black,
        margin: '0 10px 20px',
        backgroundColor: theme.palette.csh?.backgroundColor,
        padding: '60px',
        height: '364px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'flex',
        '& svg': {
            fill: theme.palette.colorPrimary,
        },
        [theme.breakpoints.down('md')]: {
            padding: '30px',
            height: '154px',
        },
    },
    title: {
        margin: 'auto',
        fontWeight: 600,
        '& img': {
            width: 80,
            height: 80,
            margin: '0 auto',
            [theme.breakpoints.down('md')]: {
                width: 40,
                height: 40,
            },
        },
        '& div:first-of-type': {
            fontSize: '24px',
            fontFamily: 'Roboto',
            marginTop: '10px',
            [theme.breakpoints.down('md')]: {
                fontSize: '13px',
            },
        },
        '& div:last-child': {
            marginTop: '5px',
            fontSize: '15px',
            color: theme.palette.common?.gray,
            [theme.breakpoints.down('md')]: {
                fontSize: '10px',
            },
        },

    },
    subLink: {
        color: theme.palette.contentColor,
        display: 'block',
        fontSize: '13px',
        textAlign: 'left',
        lineHeight: '2em',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const CustomerOptionsBlock = ({  data, classes }) => {
    const seed = useUIDSeed();
    const { options } = data;

    return (
        <>
            <Grid container className={classes.customerOptions}>
                {options.group && options.group.length > 0 ? options.group.map((d) => (
                    <Grid key={seed(d)} item xs={6}>
                        <Link className={classes.customerOption} to={determineLanguagePrefixPath(d?.link?.href)}>
                            <div className={classes.title}>
                                {d?.icon_image?.url ? <img src={d.icon_image.url} alt={d?.link?.title} /> : null}
                                <div>{d?.link?.title}</div>
                                <div>{d?.text}</div>
                            </div>
                            { d.sub_links &&  d.sub_links.length > 0 ? d.sub_links.map((a) => (
                                <Link key={seed(a)} className={classes.subLink} to={a?.href}>{a?.title}</Link>
                            ))  : null}
                        </Link>
                    </Grid>
                )) : null}
            </Grid>
        </>
    );
};

CustomerOptionsBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        options: shape({
            group: arrayOf(shape({
                link: shape({
                    title: string.isRequired,
                }).isRequired,
                icon: string.isRequired,
            })),
        }).isRequired,
    }).isRequired,
};
export default withStyles(styles)(CustomerOptionsBlock);
