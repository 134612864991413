/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'Issue With My Order',
    description: 'Select an option below to report an issue or see more at the',
    link: '/customer-service',
    orderOptions: [
        {
            title: 'Report an Issue',
            option: [
                {
                    title: 'My order is late or missing',
                    link: '/customer-service-my-order',
                },
                {
                    title: 'The item is different than what I ordered',
                    link: '/customer-service-flowers',
                },
                {
                    title: 'I have another issue',
                    link: '/customer-service-different-issue',
                },
            ],

        },
        {
            title: 'Related questions and videos',
            option: [
                {
                    title: '1800flowers COVID-19 Response',
                    link: '/customer-service-faq-covid',
                },
                {
                    title: 'What to expect when flowers arrive',
                    link: '/customer-service-help-videos',
                },
                {
                    title: 'Shipping and delivery',
                    link: '/customer-service-faq?scrollTo=shippingAndDelivery',
                },
            ],

        },

    ],

};
export default data;
