/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import Data from './ViewData';

const styles = (theme)  => ({
    container: {
        [theme?.breakpoints?.down('sm')]: {
            maxWidth: theme?.palette?.tiers?.container?.maxWidth?.downSmall,
            margin: '0 auto',
        },
        [theme?.breakpoints?.down('md')]: {
            maxWidth: theme?.palette?.tiers?.container?.maxWidth?.downMedium,
            margin: '0 auto',
        },
        [theme?.breakpoints?.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme?.breakpoints?.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
    description: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.2,
        padding: '0 15px',
        margin: '60px 15px 15px',
        '& p': {
            '& a': {
                color: theme?.palette?.colorPrimary,
            },
        },
    },
    shopLink: {
        background: theme?.palette?.motherDayColor,
        color: theme?.palette?.common?.white,
        textDecoration: 'none',
        padding: '10px 0px',
        width: '100%',
        display: 'block',
        fontWeight: '600',
        textAlign: 'center',
        fontSize: '20px',
    },
    imgContainer: {
        textAlign: 'center',
        position: 'relative',
        '& img': {
            padding: '0px 30px',
            width: '100%',
        },
    },
    desContainer: {
        padding: '0px 15px',
    },
    title: {
        color: theme?.palette?.motherDayColor,
        padding: '15px 0',
        fontSize: 30,
        textAlign: 'center',
        fontWeight: 'bold',
        overflow: 'hidden',
        '&::before': {
            width: '50%',
            right: '0.5em',
            height: '1px',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            marginLeft: '-50%',
            verticalAlign: 'middle',
            backgroundColor: theme?.palette?.grey8,
        },
        '&::after': {
            left: '.5em',
            width: '50%',
            height: '1px',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            marginRight: '-50%',
            verticalAlign: 'middle',
            backgroundColor: theme?.palette?.grey8,
        },
    },
    mainTitle: {
        width: '100%',
        color: theme?.palette?.motherDayColor,
        fontSize: '45px',
        textAlign: 'center',
        lineHeight: '20px',
        borderBottom: `1px solid ${theme?.palette?.motherDayColor}`,
        paddingBottom: '25px',
    },
    motherGiftDescription: {
        fontSize: 17,
        fontWeight: 500,
        letterSpacing: 0.7,
        margin: '15px 40px',
        lineHeight: 1.3,
        '& a': {
            color: theme?.palette?.colorPrimary,
        },
    },
    bottomDes: {
        margin: 15,
        fontSize: 26,
        marginTop: 20,
        fontWeight: 600,
        lineHeight: 1.4,
        '& .bottomDesSave': {
            color: theme?.palette?.motherDayColor,
        },
        '& .bottomDesPromo': {
            color: theme?.palette?.motherDayColor,
        },
    },

    imageContent: {
        left: '50%',
        color: theme?.palette?.common?.white,
        width: '100%',
        bottom: 5,
        position: 'absolute',
        fontSize: 17,
        transform: 'translateX(-50%)',
        fontWeight: 600,

    },
    imageContentInner: {
        margin: '0 30px',
        background: theme?.palette?.motherDayBgColor,
        padding: '12px 0',
    },
});

const MothersDayArticleTrendingFlowersPage = ({ classes }) => {
    const { mother_trending_flower_list, mother_trending_flower: { trending_flower_title,  trending_flower_description } } = Data;
    const seed = useUIDSeed();
    const seo = {
        page_title: 'Top 10 Trending Flowers | 1800flowers.com',
        meta_description: 'Learn about the top ten most popular & best flower types to send mom this Mother’s Day from hydrangeas to gerbera daisies!',
        location: { pathname: '/articles/mday/top-trending-mother-s-day-flowers' },
    };
    return (
        <>
            <GraphqlSeoHeadTags seoData={seo} />
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12}>
                        <h1 className={classes.mainTitle}> {ReactHtmlParser(trending_flower_title)} </h1>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.motherGiftDescription}>  {ReactHtmlParser(trending_flower_description)} </div>
                    </Grid>
                </Grid>
                {mother_trending_flower_list.map((flower, i) => {
                    const {
                        description, image, shop_link, title, image_content,
                    } = flower;
                    if (i % 2 === 0) {
                        return (
                            <Grid key={seed(flower)} container>
                                <Grid item xs={12}>
                                    <h3 className={classes.title}> {title} </h3>
                                </Grid>
                                <Grid className={classes.desContainer} item xs={6}>
                                    <div className={classes.description}>
                                        {ReactHtmlParser(description)}
                                        <Link className={classes.shopLink} to={shop_link}>SHOP NOW &#62; </Link>
                                    </div>
                                </Grid>
                                <Grid className={classes.imgContainer} item xs={6}>
                                    <img src={image} alt={ReactHtmlParser(image_content)} />
                                    <div className={classes.imageContent}> <div className={classes.imageContentInner}> {ReactHtmlParser(image_content)}</div></div>
                                </Grid>
                            </Grid>
                        );
                    }
                    return (
                        <Grid key={seed(flower)} container>
                            <Grid item xs={12}>
                                <h3 className={classes.title}> {title} </h3>
                            </Grid>
                            <Grid className={classes.imgContainer} item xs={6}>
                                <img src={image} alt={ReactHtmlParser(image_content)} />
                                <div className={classes.imageContent}> <div className={classes.imageContentInner}> {ReactHtmlParser(image_content)}</div></div>
                            </Grid>
                            <Grid className={classes.desContainer} item xs={6}>
                                <div className={classes.description}>
                                    {ReactHtmlParser(description)}
                                    <Link className={classes.shopLink} to={shop_link}>SHOP NOW &#62; </Link>
                                </div>
                            </Grid>
                        </Grid>
                    );
                })}
            </div>
        </>
    );
};

MothersDayArticleTrendingFlowersPage.propTypes = {
    classes: object.isRequired,
};
export default withStyles(styles)(MothersDayArticleTrendingFlowersPage);
