/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { useUIDSeed } from 'react-uid';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactHTMLParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Data from './ViewData';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1024,
            margin: '0 auto',
            padding: '2%',
        },
        padding: 10,
        letterSpacing: '0.03em',
    },

    header: {
        margin: '32px auto',
        textAlign: 'center',
        fontSize: 25,
    },
    subHeader: {
        color: '#404040',
        fontSize: 15,
        fontWeight: 'bold',
    },
    description: {
        color: ' #404040',
        fontSize: 14,
        '& p': {
            margin: '4px 0 24px',
            lineHeight: 1.8,
            '& a': {
                color: '#65388b',
            },
        },
    },
    breadCrumb: {
        '& a': {
            color: ' #404040',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: 15,
            outline: 'none',
        },
    },
    arrowSign: {
        fontSize: 16,
        verticalAlign: 'middle',
    },
});

const CustomerServiceFaqCovid = ({ classes }) => {
    const seed = useUIDSeed();
    const {
        list, header,
    } = Data;
    return (
        <div className={classes.container}>
            <div className={classes.breadCrumb}><Link to="/customer-service"><ArrowBackIosIcon className={classes.arrowSign} />Help Center/ FAQ&#39;s</Link></div>
            <h1 className={classes.header}>{header}</h1>
            {list.map((item) => (
                <div
                    key={seed(item)}
                    className={classes.content}
                >
                    <h2 className={classes.subHeader}> {ReactHTMLParser(item.subHeader)}</h2>
                    <div className={classes.description}> {ReactHTMLParser(item.description)}</div>
                </div>
            ))}

        </div>
    );
};

CustomerServiceFaqCovid.propTypes = {
    classes: object.isRequired,
};
export default withStyles(styles)(CustomerServiceFaqCovid);
