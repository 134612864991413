/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'Cancel my order',
    description: 'If your order has not yet shipped, you can request to cancel your order via the form below.',
    form_header: 'I want to cancel my order',
};
export default data;
