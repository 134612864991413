/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    leftSidebar: {
        funeralFlowers: [
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg',
                label: 'Funeral Vase Arrangements',
                link: '/funeralflowers',
            },
            {
                img: '//cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/161834x.jpg',
                label: 'Plants',
                link: '/funeralplants',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148709x.jpg',
                label: 'Standing Baskets',
                link: '/sympathy/funeralstandingbaskets-12176',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148720lx.jpg',
                label: 'Standing Sprays',
                link: '/funeralsprays',
            },
            {
                img: '//cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/148752lx.jpg',
                label: 'Floor Baskets',
                link: '/sympathy/funeralfloorbaskets-12172',
            },
            {
                img: '//cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/148738x.jpg',
                label: 'Standing Crosses, Hearts & Wreaths',
                link: '/funeralwreathscrosses',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148277lx.jpg',
                label: 'Red, White, & Blue',
                link: '/patriotic-color-sympathy',
            },
            {
                img: '//cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/91225x.jpg',
                label: 'Casket Flowers',
                link: '/funeral-casket-sprays',
            },
        ],
        advice: {
            img: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/john-tesh-expert-to-express-your-sympathy-700x350.jpg?quality=100',
            link: '/sympathy-articles',
        },
        funeralFlowersColor: [
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/all-white.jpg?quality=80',
                link: '/white-funeral-flowers?page=list&collection=400077411',
                label: 'All White',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/blue-white.jpg?quality=80',
                link: '/blue-sympathy-11414?page=list&collection=400077414',
                label: 'Blue & White',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/lavender.jpg?quality=80',
                link: '/lavender-sympathy?page=list&collection=400077415',
                label: 'Lavender',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/pink-white.jpg?quality=80',
                link: '/sympathy-color-pink?page=list&collection=400077419',
                label: 'Pink & White',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/red-white.jpg?quality=80',
                link: '/sympathy-red-white-12652?page=list&collection=400077422',
                label: 'Red & White',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/bright.jpg?quality=80',
                link: '/sympathy-multicolored?page=list&collection=400077417',
                label: 'Bright',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/pastel.jpg?quality=80',
                link: '/pastel-mixed-floral?page=list&collection=400077413',
                label: 'Pastel',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/yellow-white.jpg?quality=80',
                link: '/sympathy-color-yellow?page=list&collection=400077420',
                label: 'Yellow & White',
            },
            {
                img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/red-white-blue.jpg?quality=80',
                link: '/patriotic-color-sympathy?page=list&collection=400077412',
                label: 'Red, White & Blue',
            },
        ],
    },
    rightSidebar: {
        sympathyFlowers: [
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148683lx.jpg',
                label: 'Vase Arrangements',
                link: '/sympathyflowers',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/18569ax.jpg',
                label: 'Plants & Memorial Trees',
                link: '/sympathyplants',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/148697x.jpg',
                label: 'Basket Arrangements',
                link: '/sympathy/flowerbaskets-11875',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/97343x.jpg',
                label: 'Remembrance Gifts',
                link: '/sympathyremembrance',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Baskets/images/catalog/149573lx.jpg',
                label: 'Gourmet Food & Gift Baskets',
                link: '/sympathygiftbasketsfoodgifts',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/161600x.jpg',
                label: 'Fruit Bouquets',
                link: '/sympathy-fruit-bouquets-400174006',
            },
            {
                img: '//cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/139734x.jpg',
                label: 'Faith-Inspired Sympathy Gifts',
                link: '/sympathy-religious-gifts',
            },

            {
                img: '//cdn2.1800flowers.com/wcsstore/Baskets/images/catalog/96275x.jpg',
                label: 'Kosher Sympathy Items',
                link: '/jewish-sympathy-shiva-gifts',
            },
            {
                img: '//cdn2.1800flowers.com/wcsstore/Baskets/images/catalog/162548x.jpg',
                label: 'Heartwarming Gourmet Meals',
                link: '/sympathy-meals',
            },
            {
                img: '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/89971x.jpg',
                label: 'Pet Sympathy',
                link: '/sympathy/pets-11837',
            },
        ],
        hopeBanner: {
            link: '/american-cancer-society-13481',
            img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/acs-banner-v2.jpg?quality=80',
        },
        serviceBanner: {
            img: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2018/sympathy/terryTest.jpg?quality=80',
            heading: 'Customer Service You Can <br /> Always Count On',
            content: '"I got distracted and forgot to order flowers for my uncle’s funeral. Friday night before the 9:30 am Saturday service, I called my regular florist, but they were unable to get my flowers there in time. So I called your company from the link at my uncle\'s funeral home. Even with such late notice, a kind agent named Daniela got on the phone with the local florist to confirm she could do it, and YES!…there was my beautiful arrangement, just like the picture, at 9:15 am Saturday morning! I am SO impressed, and you\'re now my regular florist.</br>-Terry, Houston, TX"',
        },

    },
    disclaimer: {
        copy: [{
            heading: 'Sympathy Flowers and Sympathy Gifts',
            description: 'The passing of a loved one can be one of the most difficult times that your family and friends may ever face. Show your sympathy by sending a <a href="/allfuneralarrangements">funeral flower arrangement</a> or sympathy flower delivery to the service, the office, or home of your loved ones, friends or coworkers. Many of the sympathy flower arrangements include lilies, orchids, roses, carnations and more.<p>A sympathy gift may be just as comforting as a beautiful bouquet of flowers. Sympathy gifts for the home or office include gift baskets, memorial fruit bouquets, and more that help convey remembrance and deepest sympathies.</p>            ',
        },
        {
            heading: 'Sympathy Flower Delivery for the Service',
            description: 'Flowers are an excellent way to express your sympathy while giving the loved ones of the departed a beautiful reminder of the love and support that surrounds them. Whether you decide to send a beautiful <a href="/funeralsprays">standing funeral spray or wreath</a> to the service or a simple and elegant sympathy bouquet to the home, there are many sympathy flower and gift delivery options for every need and budget.',
        },
        {
            heading: 'Sympathy & Remembrance Resources',
            description: 'Get helpful information &amp; resources from our sympathy experts on our <a href="/sympathy-articles">sympathy resources</a> hub. From funeral customs by religion to what you should send to a funeral or wake, get our recommendations to make a difficult time a little easier.',
        },
        ],
        mainHeading: 'Frequently Asked Questions about Sympathy Flowers:',
        subCopy: [
            {
                heading: 'What can you send someone for sympathy?',
                description: 'There are many gifts that are commonly sent for sympathy. For a simple touch you can send a sympathy card with a heartfelt message. Flowers are an elegant way to express sympathy, as well as sending a sympathy basket',
            },
            {
                heading: 'What are sympathy flowers?',
                description: 'Sympathy flowers are flower bouquets and arrangements often sent to funeral homes. They come in a variety of flower types and colors that can convey a message to who you wish to comfort.',
            },
            {
                heading: 'When should you send sympathy flowers?',
                description: 'Flowers should be sent as soon as possible after hearing someone has passed. This helps to ensure that the flowers arrive in time for the funeral. However, sympathy flowers can also be sent on an anniversary of someone’s death to show that you are thinking of them.',
            },
            {
                heading: 'What should I write on a sympathy card with flowers?',
                description: 'Sympathy cards should be heartfelt messages that can put the grieving family at ease. It is best to reflect on good times you have had with the deceased. If you have trouble deciding on the right words to say, we have recommendations of <a href="/sympathy-article-card-message-suggestions" title="what to write in a sympathy card">what to write in a sympathy card</a>.',
            },
            {
                heading: 'What color flowers are best for sympathy?',
                description: 'Traditionally, pink and white are the colors associated with sympathy. These colors symbolize compassion, love, and purity. Other colors can be used as well to convey a message, like yellow for friendship or blue for peace.',
            },
            {
                heading: 'What kind of flowers are appropriate for sympathy?',
                description: 'White lilies are a very popular flower for sympathy bouquets for their associations with purity and innocence that has been bestowed on the soul of the deceased. Other popular flowers are carnations, roses, and orchids',
            },
            {
                heading: 'How much should you spend on sympathy flowers?',
                description: 'It is common to pay between $60 and $80 on a medium sized sympathy flower arrangement. Larger wreaths or casket sprays can cost well over $100.',
            },
        ],
    },
    arrowImage: '//cdn3.1800flowers.com/wcsstore/Flowers/images/2015/sympathy/landing/sym-view-all-arrow.png',
};

export default data;
