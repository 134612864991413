/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactHTMLParser from 'react-html-parser';
import {
    object,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import Data from './ViewData';

const styles = (theme) => ({
    container: {
        [theme?.breakpoints?.down('sm')]: {
            maxWidth: theme?.palette?.tiers?.container?.maxWidth?.downSmall,
            margin: '0 auto',
        },
        [theme?.breakpoints?.down('md')]: {
            maxWidth: theme?.palette?.tiers?.container?.maxWidth?.downMedium,
            margin: '0 auto',
        },
        [theme?.breakpoints?.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme?.breakpoints?.up('lg')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        '& a': {
            textDecoration: 'none',
        },
        fontFamily: 'Roboto,sans-serif',
    },
    list: {
        width: '100%',
        display: 'inline-block',
        backgroundColor: theme?.palette?.motherDayBg,
    },
    productDetail: {
        width: '50%',
        margin: '0 auto',
        display: 'inline-block',
        textAlign: 'center',
        float: 'left',
    },
    shopButton: {
        textAlign: 'center',
        backgroundColor: theme?.palette?.motherDayShopBtn,
        color: theme?.palette?.common?.white,
        textDecoration: 'none',
        fontSize: '21px',
        borderRadius: 25,
        padding: '10px 45px',
        fontWeight: 700,
        textTransform: 'uppercase',
        marginTop: 30,
        display: 'inline-block',
    },
    bannerImage: {
        padding: 15,
        '& img': {
            width: '100%',
            maxWidth: '100%',
        },
    },
    productName: {
        color: theme?.palette?.common?.black,
        margin: '10px 0 20px',
        padding: '15px 0',
        fontSize: 21,
    },
    productBlock: {
        padding: 15,
    },
    productImage: {
        display: 'inline-block',
        float: 'right',
        width: '50%',
        '& img': {
            float: 'right',
            minWidth: 335,
        },
    },
    brandLogo: {
        marginTop: 40,
    },
});
const brandListSection = (data, classes) => data.map((list) => {
    const {
        logo, productName, produLink, productImage,
    } = list;
    const seed = useUIDSeed();
    return (
        <Grid className={classes.productBlock} key={seed(list)} item xs={6}>
            <a
                href={produLink}
                className={classes.list}
            >
                <div className={classes.productDetail}>
                    <img className={classes.brandLogo} src={logo} alt="" />
                    <Typography className={classes.productName}>{ReactHTMLParser(productName)}</Typography>
                    <a className={classes.shopButton} to={produLink}>Shop Now</a>
                </div>
                <div className={classes.productImage}>
                    <div> <img src={productImage} alt={productName} /></div>
                </div>
            </a>
        </Grid>
    );
});
const CelebrationsMothersDayPage = ({ classes }) => {
    const { bannerImage, brandList, bottomBanner: { imagePath, link } } = Data;
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <div className={classes.bannerImage}> <img src={bannerImage} alt="Banner" /></div>
            </Grid>
            <Grid container xs={12}>
                {brandListSection(brandList, classes)}
            </Grid>
            <Grid item xs={12}>
                <div className={classes.bannerImage}><Link to={link}> <img src={imagePath} alt="Bottm Banner" /></Link></div>
            </Grid>
        </Grid>
    );
};

CelebrationsMothersDayPage.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(CelebrationsMothersDayPage);
