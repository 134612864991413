/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toggleWebChat from '../../../../helpers/toggleWebChat';
import { setChatbotOpenState } from '../../../../../state/ducks/App/App-Actions';
import { actions as tagManagerActions } from '../../../../../state/ducks/TagManager';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const ContactUsBannerBuilder = ({
    trackEvent, enableChatBot,
}) => {
    trackEvent({
        eventCategory: 'Customer Service Hub', eventAction: 'Chat', eventLabel: 'path Ex: /customer-service',
    });
    enableChatBot(true);
    toggleWebChat();

    return '';
};

ContactUsBannerBuilder.propTypes = {
    trackEvent: func.isRequired,
    enableChatBot: func.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
    enableChatBot: bindActionCreators(setChatbotOpenState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsBannerBuilder);
