/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactHTMLParser from 'react-html-parser';
import {
    object,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import Data from './ViewData';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        fontFamily: 'Roboto,sans-serif',
        boxSizing: 'content-box',
        '& a': {
            color: theme.palette.bgHeader,
            cursor: 'pointer',
            textDecoration: 'underline',
            outline: 'none',
        },
    },
    title: {
        color: theme.palette.motherDayColor,
        padding: '15px 0',
        fontSize: 25,
        textAlign: 'center',
        fontWeight: 'bold',
        overflow: 'hidden',
        marginBottom: 20,
        '&::before,&::after': {
            width: '72%',
            height: '1px',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.grey8,
        },
        '&::after': {
            marginRight: '-50%',
            left: '.5em',
        },
        '&::before': {
            marginLeft: '-50%',
            right: '0.5em',
        },
    },
    headerTitle: {
        width: '100%',
        color: theme.palette.motherDayColor,
        fontSize: 45,
        textAlign: 'center',
        borderBottom: `1px solid ${theme.palette.motherDayColor}`,
        paddingBottom: 25,
        lineHeight: '20px',
    },
    imageSection: {
        width: '48%',
        margin: 8,
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        '& div': {
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            '& img': {
                width: '100%',
                padding: 15,
            },
        },
    },
    section: {
        width: '100%',
        display: 'inline-block',
    },
    description: {
        width: '100%',
        position: 'relative',
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 400,
        padding: 15,
        letterSpacing: '0.2px',
        display: 'inline-block',
        verticalAlign: 'middle',
        color: theme.palette.colorIconHeader,
    },
    imageLink: {
        width: '50%',
        display: 'inline-block',
        verticalAlign: 'middle',
        position: 'relative',
    },
    imageTitle: {
        color: 'white',
        width: '88%',
        bottom: 20,
        padding: '10px 0',
        position: 'absolute !important',
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 600,
        textDecoration: 'none',
        backgroundColor: theme.palette.bgTextOverlay1,
        right: 15,
    },
    shopNowButton: {
        width: '100%',
        margin: '10px auto 0',
        textAlign: 'center',
        '& a': {
            display: 'inline-block',
            width: '90%',
            background: theme.palette.motherDayColor,
            padding: '10px 0',
            textAlign: 'center',
            textTransform: 'uppercase',
            textDecoration: 'none',
            fontWeight: 700,
            fontSize: 22,
            margin: '0 auto',
            color: theme.palette.white,
        },
    },
    descriptionSection: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '48%',
        margin: 8,
    },
    imageAdjust: {
        width: '94%',
    },
});
const titleBlock = (title, classes) => (
    <div className={classes.title}>
        {ReactHTMLParser(title)}
    </div>
);

const descriptionBlock = (desc, classes) => (
    <Typography className={classes.description}>{ReactHTMLParser(desc)}</Typography>
);
const imageSection = (image, classes) => {
    const { imagePath, imageTitle, imageLink } = image;
    return (
        <div className={classes.imageSection}>
            <Link
                to={imageLink}
            >
                <div>
                    <img src={imagePath} alt={imageTitle} />
                </div>
                <div className={`${classes.imageTitle} ${classes.imageAdjust}`}>{ReactHTMLParser(imageTitle)}</div>
            </Link>
        </div>
    );
};
const imageBlock = (image, classes) => image.map((list) => {
    const { imageTitle, imagePath, imageLink } = list;
    const seed = useUIDSeed();
    return (
        <Link
            key={seed(list)}
            to={imageLink}
            className={classes.imageLink}
        >
            <div> <img src={imagePath} alt={imageTitle} /></div>
            <div className={classes.imageTitle}>{ReactHTMLParser(imageTitle)}</div>
        </Link>
    );
});
const shopNowButton = (link, classes) => (
    <div className={classes.shopNowButton}>
        <Link to={link}>
            <span>Shop Now</span><span> &#62;</span>
        </Link>
    </div>
);
const rightSection = (data, classes) => {
    const {
        title, desc, link, image,
    } = data;
    return (
        <Grid item xs={12}>
            {titleBlock(title, classes)}
            <div className={classes.section}>
                <div className={classes.descriptionSection}>
                    {descriptionBlock(desc, classes)}
                    {shopNowButton(link, classes)}
                </div>
                {imageSection(image, classes)}
            </div>
        </Grid>
    );
};
const leftSection = (data, classes) => {
    const {
        title, desc, link, image,
    } = data;
    return (
        <Grid item xs={12}>
            {titleBlock(title, classes)}
            <div className={classes.section}>
                {imageSection(image, classes)}
                <div className={classes.descriptionSection}>
                    {descriptionBlock(desc, classes)}
                    {shopNowButton(link, classes)}
                </div>
            </div>
        </Grid>
    );
};
const GiftForPetLoverMomPage = ({ classes }) => {
    const {
        headerBlock,
        dogShapedFlowers: {
            dogTitle,
            dogDesc,
            dogLink,
            dogImage,
        },
        catShapedFlowers: {
            catTitle,
            catDesc,
            catLink,
            catImage,
        },
        catAndDogPlanters,
        featheredFriends,
        perfectGifts,
        stuffed,
    } = Data;
    const seo = {
        page_title: 'Gift Ideas for Pet Lovers | 1800flowers.com',
        meta_description: 'Whether your mom is a cat lover, dog lover or just an animal lover, check out our list of top Mother’s Day gifts for pet lovers for something she’ll love!',
        location: { pathname: '/articles/mday/gifts-for-the-pet-lover-mom' },
    };
    return (
        <>
            <GraphqlSeoHeadTags seoData={seo} />
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <div>
                        <h1 className={classes.headerTitle}>{headerBlock.imageTitle}</h1>
                    </div>
                    <div className={classes.section}>
                        {imageSection(headerBlock, classes)}
                        <div className={classes.descriptionSection}>
                            {descriptionBlock(dogDesc, classes)}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {titleBlock(dogTitle, classes)}
                    <div className={classes.section}>
                        <div className={classes.descriptionSection}>
                            {descriptionBlock(dogDesc, classes)}
                        </div>
                        <div className={classes.imageSection}>
                            {imageBlock(dogImage, classes)}
                            {shopNowButton(dogLink, classes)}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {titleBlock(catTitle, classes)}
                    <div className={classes.section}>
                        <div className={classes.imageSection}>
                            {imageBlock(catImage, classes)}
                            {shopNowButton(catLink, classes)}
                        </div>
                        <div className={classes.descriptionSection}>
                            {descriptionBlock(catDesc, classes)}
                        </div>
                    </div>

                </Grid>
                {rightSection(catAndDogPlanters, classes)}
                {leftSection(featheredFriends, classes)}
                {rightSection(perfectGifts, classes)}
                {leftSection(stuffed, classes)}
            </Grid>
        </>
    );
};
GiftForPetLoverMomPage.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(GiftForPetLoverMomPage);
