/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

const VAConfig = Object.freeze({
    WIDGET_URL: mbpUtil.getEnv('APP_VA_WIDGET_URL'),
    TWENTY_PERCENT_PROMO_CODE: mbpUtil.getEnv('APP_VA_TWENTY_PERCENT_PROMO_CODE'),
    TEN_PERCENT_PROMO_CODE: mbpUtil.getEnv('APP_VA_TEN_PERCENT_PROMO_CODE'),
    TWENTY_PERCENT_DEAL_ID: mbpUtil.getEnv('APP_VA_TWENTY_PERCENT_DEAL_ID'),
    TEN_PERCENT_DEAL_ID: mbpUtil.getEnv('APP_VA_TEN_PERCENT_DEAL_ID'),
    PROVIDER_TOKEN: mbpUtil.getEnv('APP_VA_PROVIDER_TOKEN'),
});

export default VAConfig;
