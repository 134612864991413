/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import {
    object,
} from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import Data from './ViewData';
import ContactUsBannerContainer from '../ContactUsBanner/ContactUsBannerContainer';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
            padding: '0 15px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
    title: {
        '& h2': {
            fontSize: 28,
            textAlign: 'center',
        },
        '& p': {
            fontSize: 15,
            margin: 'auto',
            maxWidth: '85%',
            marginBottom: 10,
        },
    },
});

const CustomerServiceDifferentIssuePage = ({ classes, page, brand }) => {
    const { title = '', description = '' } = Data;
    return (
        <>
            <Grid container className={classes.container}>
                <Grid className={classes.title} item xs={12}>
                    <h2>{title}</h2>
                    {ReactHTMLParser(description)}
                </Grid>
            </Grid>
            <ContactUsBannerContainer page={page} brand={brand} />
        </>
    );
};

CustomerServiceDifferentIssuePage.propTypes = {
    classes: object.isRequired,
    page: object.isRequired,
    brand: object.isRequired,
};

export default withStyles(styles)(CustomerServiceDifferentIssuePage);
