/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
  * The data in this file is hardcoded only for 18F brand
  */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import {
    object, shape, arrayOf, string,
} from 'prop-types';
import Typography from '@material-ui/core/Typography';
import PageHeading from './PageHeading';
import PageSubheading from './PageSubheading';
import FuneralFlowers from './FuneralFlowers';
import SympathyFlowers from './SympathyFlowers';
import GraphqlBreadcrumbs from '../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GraphqlCollectionSeoSchema from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlCollectionSeoSchema/GraphqlCollectionSeoSchema';
import GraphqlUnderNavTextWidgetContainer from '../../GraphqlCommonComponents/GraphqlUnderNavTextWidget/GraphqlUnderNavTextWidgetContainer';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downMedium,
            margin: '0 auto',
            padding: '0 10px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '1400px',
            margin: '0 auto',
            padding: '0 10px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1400px',
            margin: '0 auto',
        },
        fontFamily: 'Roboto',
    },
    heading: {
        textAlign: 'center',
        margin: 0,
        color: theme.palette.sympathyPage?.headingColor,
        fontSize: '25px',
    },
    headingBlock: {
        borderRight: `1px solid ${theme.palette.sympathyPage?.borderColors}`,
        margin: '15px 0',
    },
    subHeading: {
        margin: '15px 0 0 30px',

        '& > span': {
            display: 'block',
            lineHeight: 1.4,
            marginBottom: 5,
        },
        '& a': {
            color: theme.palette.sympathyPage?.linkColor,
        },
    },
    textSameDay: {
        textAlign: 'center',
        width: '100%',
        margin: 0,
        '& div': {
            fontSize: 18,
            fontWeight: 'normal',
        },
        '& b': {
            color: theme.palette.sympathyPage?.headingColor,
        },
    },
    textRight: {
        textAlign: 'center',
        marginBottom: 25,
    },
    fontWeightSmall: {
        fontWeight: 'normal',
    },
    button: {
        background: theme.palette.sympathyPage?.buttonColor,
        color: theme.palette.white,
        '&:hover': {
            color: theme.palette.white,
            background: theme.palette.sympathyPage?.buttonColor,
        },
        padding: '10px 15px',
        textDecoration: 'none',
        fontWeight: 700,
        '& img': {
            width: '100%',
            paddingLeft: 10,
            maxWidth: 18,
        },
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.sympathyPage?.borderColors}`,
    },
    marginTopAndBottom: {
        margin: '15px auto',
    },
    paddingLeft: {
        padding: '0 10px',
    },
    marginTop: {
        marginTop: 25,
    },
    adviceImage: {
        width: '100%',
        paddingRight: 10,
    },
    linkWithShop: {
        textAlign: 'center',
        fontWeight: 700,
        '& > a': {
            color: theme.palette.sympathyPage?.buttonColor,
            textDecoration: 'none',
        },
    },
    fixed: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        background: theme.palette.white,
    },
    left: {
        border: `1px solid ${theme.palette.common?.black}`,
        fontFamily: 'Roboto, san-serif',
        fontSize: 13,
        textAlign: 'center',
        padding: 15,
        width: '80%',
    },
    h3: {
        color: theme.palette.common?.black,
        fontFamily: 'Roboto, san-serif',
        fontWeight: 200,
        fontSize: 28,
        width: '80%',
        margin: '0 auto',
    },
    p: {
        margin: '10px 0',
        fontSize: 13,
        color: theme.palette.common?.black,
    },
    description: {
        display: 'flex',

    },

    disclaimerHeading: {
        fontSize: 16,
        width: '100%',
        margin: '1em 0',
        fontWeight: 600,
        marginBottom: '8px',
        color: theme.palette.sympathyPage?.headingColor,
    },
    disclaimerDesc: {
        margin: 0,
        padding: 0,
        lineHeight: '17px',
        color: theme.palette.sympathyProuctNameColor,
        fontSize: 13,
        '& a': {
            color: theme.palette.sympathyPage?.linkColor,
        },
    },
    serviceBlock: {
        marginTop: 25,
        display: 'flex',
        '& img': {
            height: '100%',
            maxHeight: '100%',
            width: '100%',
            border: `1px solid ${theme.palette.common?.black}`,
            borderLeft: 'none',
        },
    },
    hopeBanner: {
        '& img': {
            width: '100%',
        },
    },
    subCopy: {
        backgroundColor: theme.palette.sympathyPage?.subCopyBg,
        border: `1px solid ${theme.palette.sympathyPage?.subCopyBorderColor} `,
        padding: '10px',
        marginTop: 25,
    },
    mainHeading: {
        fontWeight: 700,
        fontSize: 22,
        color: theme.palette.sympathyPage?.headingColor,
        paddingTop: 0,
    },
    backToTop: {
        color: theme.palette.colorPrimary,
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 14,
    },
    subHeaderBlock: {
        padding: 16,
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.sympathyPage?.borderColors}`,
        borderBottom: `1px solid ${theme.palette.sympathyPage?.borderColors}`,
    },
    wisdoImageBlock: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: `1px solid ${theme.palette.sympathyPage?.borderColors}`,
        '& img': {
            width: '87%',
        },
    },
    headingLink: {
        textDecoration: 'none',
        color: theme.palette.common?.black,
    },
});

// helper function strips markdown link - matches [,] and removes brackets - matches (,) and removes () and text inside
const mdParser = (answer) => answer.replace(/[\][]/gi, '').replace(/<\/?a[^>]*>/g, '');
// ld+json schema
const FaqSeoSchema = ({ faqArray }) => (
    <Helmet>
        <script type="application/ld+json">
            {`{"@context": "https://schema.org","@type": "FAQPage","mainEntity": [${faqArray && faqArray.map((faqData) => (`{"@type": "Question","name": "${faqData.heading}","acceptedAnswer": {"@type": "Answer","text": "${mdParser(faqData.description)}"}}`))}]}`}
        </script>
    </Helmet>
);
FaqSeoSchema.propTypes = {
    faqArray: arrayOf(shape({
        question: string,
        answer: string,
    })).isRequired,
};
class SympathyBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = false;
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const el = document.querySelector('#sticky-sympathy');
            if (this.mounted) {
                this.setState({ top: el.offsetTop, height: el.offsetHeight });
                window.addEventListener('scroll', this.handleScroll);
            }
        }
    }

    componentDidUpdate() {
        const { scroll, top, height } = this.state;
        if (typeof document !== 'undefined') {
            if (scroll > top) {
                document.body.style.paddingTop = `${height}px`;
            } else {
                document.body.style.paddingTop = 0;
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        if (typeof document !== 'undefined') {
            document.body.style.paddingTop = 0;
        }
    }

    getPageStickyHeader() {
        const { classes, data } = this.props;
        const { scroll, top } = this.state;
        return (
            <div id="sticky-sympathy" className={` ${scroll > top ? classes.fixed : ''}`}>
                <Grid container className={`${classes.container}`}>
                    <Grid className={classes.textRight} item xs={6}>
                        <h2>
                            <Link className={classes.headingLink} to="/allfuneralarrangements" title="Flowers for the Service"> <span className={classes.fontWeightSmall}>Funeral Flowers for the Service</span></Link>
                        </h2>
                        <Link className={classes.button} to="/allfuneralarrangements" title="View All Funeral Flowers">View All  <img src={`${data?.arrowImage}`} alt="All Funeral Arrangements" /></Link>
                    </Grid>
                    <Grid className={classes.textRight} item xs={6}>
                        <h2>
                            <Link className={classes.headingLink} to="/allsympathyflowersgifts" title="Sympathy for the Home or Office"> <span className={classes.fontWeightSmall}>Sympathy Flowers & Gifts for the Home or Office</span></Link>
                        </h2>
                        <Link className={classes.button} to="/allsympathyflowersgifts" title="View All Sympathy Flowers for the Home or Office">View All <img src={`${data?.arrowImage}`} alt="All Sympathy Flowers Gifts" /></Link>
                    </Grid>
                </Grid>
            </div>
        );
    }

     collectionSeoSchema = () => (
         <Helmet>
             <script type="application/ld+json">
                 {'{"@context":"http://schema.org","@type":"CollectionPage","name":"Sympathy Flowers","description":"Trust 1800Flowers sympathy flowers & arrangements to express your condolences. Order sympathy flowers for delivery online for the service or to the home.","primaryImageOfPage":{"@type":"ImageObject","url":"//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg"},"image":{"@type":"ImageObject","url":"//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg"}}'}
             </script>
         </Helmet>
     )

    disclaimer =(data, classes) => {
        if (data.length > 0) {
            return (data.map((item) => {
                const { heading, description } = item;
                return (
                    <div key={heading}>
                        <h2 className={classes.disclaimerHeading}>{heading}</h2>
                        <div className={classes.disclaimerDesc}>{ ReactHtmlParser(description)} </div>
                    </div>
                );
            })
            );
        }
        return null;
    }

    faqSeoContent =(data, classes) => {
        if (data.length > 0) {
            return (data.map((item) => {
                const { heading, description } = item;
                return (
                    <div key={heading}>
                        <h3 className={classes.disclaimerHeading}>{heading}</h3>
                        <Typography className={classes.disclaimerDesc}>{ ReactHtmlParser(description)} </Typography>
                    </div>
                );
            })
            );
        }
        return null;
    }

    scrollTop = () => {
        if (typeof window !== 'undefined') {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleScroll() {
        if (typeof window !== 'undefined') {
            this.setState({ scroll: window.scrollY });
        }
    }

    render() {
        const {
            data, classes, seo, page, presentationFamily,
        } = this.props;
        return (
            <>
                <GraphqlUnderNavTextWidgetContainer page={page} presentationFamily={presentationFamily} />
                <div className={classes.container}>
                    <GraphqlBreadcrumbs breadCrumbArray={seo.breadcrumb} />
                    {this.collectionSeoSchema()}
                </div>
                <PageHeading classes={classes} />
                <PageSubheading classes={classes} />
                {this.getPageStickyHeader()}
                <GraphqlCollectionSeoSchema categoryData={seo} productData={data[0]} />
                <FuneralFlowers data={data} classes={classes} />
                <SympathyFlowers data={data} classes={classes} />
                <Grid container className={`${classes.container}`}>
                    <Grid item xs={12}>
                        <div className={classes.copy}> {this.disclaimer(data.disclaimer.copy, classes)}  </div>
                        <div className={classes.subCopy}>
                            <FaqSeoSchema faqArray={data.disclaimer.subCopy} />
                            <h2 className={classes.mainHeading}>{data.disclaimer.mainHeading} </h2>
                            {this.faqSeoContent(data.disclaimer.subCopy, classes)}
                        </div>
                        <span className={classes.backToTop} role="presentation" onClick={() => this.scrollTop()}>
                            Back to top
                        </span>
                    </Grid>
                </Grid>
            </>
        );
    }
}

SympathyBlock.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
    presentationFamily: string.isRequired,
    page: shape({
        path: string.isRequired,
    }).isRequired,
    seo: shape({
        title: string,
        href: string,
    }),
};
SympathyBlock.defaultProps = {
    seo: {
        title: '',
        href: '',
    },
};
export default withStyles(styles)(SympathyBlock);
