/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, arrayOf, shape, string,
} from 'prop-types';
import GraphqlBreadcrumbSeoSchema from './Partials/GraphqlBreadcrumbSeoSchema';
import { determineLanguagePrefixPath } from '../../../../helpers/determineInternationalizationPath';

const styles = (theme) => ({
    breadCrumbList: {
        listStyle: 'none',
        fontSize: '14px',
        display: 'inline-flex',
        margin: 0,
        padding: 0,
        flexWrap: 'wrap',
        [theme.breakpoints.down(400)]: {
            textAlign: 'center',
            fontSize: '13px',
            lineHeight: '1.5',
        },
    },
    plabreadCrumbListItem: {
        '& a': {
            color: `${theme.palette.colorPrimary} !important`,
            textDecoration: 'underline !important',
            fontWeight: 'bold !important',
        },
        '&::after': {
            content: '">" !important',
            position: 'relative',
            right: '0',
        },
    },
    breadCrumbListItem: {
        marginRight: '5px',
        '&:last-child': {
            color: theme.palette.common?.gray || '#707070',
            whiteSpace: 'nowrap',
        },
        '&::after': {
            content: '"»"',
            position: 'relative',
            right: '0',
        },
        '&:last-child::after': {
            content: '""',
            display: 'none',
        },
        '& a': {
            textDecoration: 'none',
            paddingRight: '5px',
            whiteSpace: 'nowrap',
            color: theme.palette.colorPrimary,
            '&:hover, &:focus': {
                textDecoration: 'underline',
            },
        },
    },
});

const determinBreadcrumbLink = (url) => {
    const language = determineLanguagePrefixPath(url);
    return language;
};

function GraphqlBreadcrumbs({ classes, breadCrumbArray, brand }) {
    return (
        <>
            <GraphqlBreadcrumbSeoSchema breadCrumbArray={breadCrumbArray} />
            <ol className={classes.breadCrumbList}>
                { breadCrumbArray && breadCrumbArray.length > 0 ? breadCrumbArray.map((d, i) => (
                // if last element remove <Link>
                    i === breadCrumbArray.length - 1
                        ? (
                            <li key={d.title} className={classes.breadCrumbListItem}>
                                {ReactHTMLParser(d.title)}
                            </li>
                        )
                        : (
                            <li key={d.title} className={brand?.code === 'PLA' ? `${classes.breadCrumbListItem} ${classes.plabreadCrumbListItem}` : `${classes.breadCrumbListItem}`}>
                                <Link to={determinBreadcrumbLink(d.href)}>
                                    {ReactHTMLParser(d.title)}
                                </Link>
                            </li>
                        )
                )) : (
                    // default if no breadcrumb data
                    <li className={classes.breadCrumbListItem}>
                        <Link to="/">
                            <span role="img" aria-label="Home icon">🏠</span> Home
                        </Link>
                    </li>
                ) }
            </ol>
        </>
    );
}

GraphqlBreadcrumbs.propTypes = {
    classes: object.isRequired,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ).isRequired,
    brand: object.isRequired,
};

export default withStyles(styles)(GraphqlBreadcrumbs);
