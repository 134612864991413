/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import Flowers from './flowers';

const FuneralFlowers = ({ data, classes }) => {
    const {  rightSidebar: { sympathyFlowers }, leftSidebar: { funeralFlowers, advice: { link, img } }  } = data;
    const seed = useUIDSeed();
    return (
        <Grid container className={`${classes.container} ${classes.marginTopAndBottom}`}>
            <Grid className={classes.borderRight} item xs={6}>
                <div className={classes.paddingLeft}>
                    <Grid container>
                        { funeralFlowers.map((block) => (<Flowers data={block} key={seed(block)} />))}
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className={classes.marginTop}>
                            <Link to={link}>
                                <img src={img} className={classes.adviceImage} alt="Looking for Advice" />
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.paddingLeft}>
                    <Grid container>
                        { sympathyFlowers.map((value) => (
                            <Flowers data={value} key={seed(value)} />
                        ))}
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};
FuneralFlowers.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
};

export default FuneralFlowers;
