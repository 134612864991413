/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import {
    object, string, bool, func,
} from 'prop-types';
import { setMilVetTransactionId, setMilVetDealId } from './helpers/utilities';
import { getOrderId } from '../../../../state/ducks/Checkout/ducks/Common/Common-Selectors';
import VAConfig from './helpers/config';

class MilVetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { milVetIdComponentLoaded: false }; // flag for milVetId Component will eventually be loaded into DOM
    }

    UNSAFE_componentWillMount() {
        if (window !== 'undefined') this.loadMilVetIdScript();
    }

    // dynamic script loading for MilVetId WebSDK
    loadMilVetIdScript = () => {
        const existingMilVetIdScript = document.getElementById('milVetIdHolder');
        if (!existingMilVetIdScript) {
            const script = document.createElement('script');
            script.src = VAConfig.WIDGET_URL;
            script.id = 'milVetIdHolder';
            document.head.appendChild(script);
            script.onload = () => {
                // setInterval for checking is milvetid-component loaded as it required some time to load in window object
                const milVetIdComponentLoadedHandler = setInterval(() => {
                    if (window && window.MilVetComponent) {
                        this.setState({ milVetIdComponentLoaded: true });
                        clearInterval(milVetIdComponentLoadedHandler);
                    }
                }, 100);
            };
        } else {
            this.setState({ milVetIdComponentLoaded: true });
        }
    };

    addCloseButton = (MilVetIDElement) => {
        const { setshowVAWidget } = this.props;
        const closeElement = document.createElement('div');
        closeElement.textContent = 'X';
        closeElement.classList.add('zoid-close');
        closeElement.onclick = () => {
            setshowVAWidget(false);
        };
        MilVetIDElement.appendChild(closeElement);
    };

    launchVAWidget = () => {
        const that = this;
        const launchVAWidgetInterval = setInterval(() => {
            const MilVetIDElement = document.querySelectorAll('[id^=zoid-partner-login]')[0];
            const MilVetIDCloseElement = document.querySelector('.zoid-close');
            if (MilVetIDElement && !MilVetIDCloseElement) {
                that.addCloseButton(MilVetIDElement);
                clearInterval(launchVAWidgetInterval);
            }
        }, 100);
    };

    loadMilVetIdComponent = () => {
        this.launchVAWidget();
        // milvetid-component driver Registration with react and react DOM
        if (window && window.MilVetComponent) {
            const MilVetComponentDriver = window.MilVetComponent.driver('react', {
                React,
                ReactDOM,
            });
            return (
                <MilVetComponentDriver
                    providerToken={VAConfig.PROVIDER_TOKEN}
                    idVerification={false}
                    militaryQuestionsVerification
                    deniedVerificationCallback={this.deniedVerificationCallback}
                    dataPartner={this.verificationCallback}
                />
            );
        }
        return null;
    };

    deniedVerificationCallback = () => {
        const { history } = this.props;
        history.push(window.location.pathname); // redirection after user failed to verify its military status
    };

    verificationCallback = (sessionId, dealId) => {
        const { orderId, history } = this.props;

        setMilVetTransactionId(sessionId); // SessionId (TransactionId) whoes expiration time is 24 Hrs
        setMilVetDealId(dealId); // dealId whoes expiration time is 24 Hrs

        const redirectToRoute = window.location.pathname === '/cart' ? `/checkout/shipping/${orderId}` : '/c/all-plants';
        history.push(redirectToRoute); // redirection after user verified its military status successfully
    };

    render() {
        return this.props.showVAWidget && this.state.milVetIdComponentLoaded ? (
            this.loadMilVetIdComponent()
        ) : (
            <div />
        );
    }
}

MilVetComponent.propTypes = {
    history: object.isRequired,
    orderId: string.isRequired,
    showVAWidget: bool.isRequired,
    setshowVAWidget: func.isRequired,
};

const mapStateToProps = (state) => ({
    orderId: getOrderId(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
);

export default enhance(MilVetComponent);
