/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import * as Joi from 'joi-browser';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReCAPTCHA from 'react-google-recaptcha';
import mbpUtil from 'mbp-api-util';
import { isEmpty } from '../../../../../pages/Account/utils/object';
import ErrorLanguages from '../ErrorLanguage';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errors: {},
        };
    }

    validate = (isBoolean = false) => {
        const options = { abortEarly: false };
        const { data, consoleErrorValidation } = this.state;
        const { error } = Joi.validate(data, this.schema, options);
        if (!error) {
            if (!isBoolean) return {};
            return true;
        }
        if (consoleErrorValidation) {
            console.log(error);
        }
        const errors = {};
        error.details.forEach((element) => {
            if (typeof errors[element.path[0]] === 'undefined') {
                errors[element.path[0]] = element.message;
            }
        });
        if (!isBoolean) return errors;
        if (isEmpty(errors)) return true;
        return false;
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { data, consoleErrorValidation } = this.state;
        // eslint-disable-next-line no-underscore-dangle
        const { _refs } = this.schema[name];
        if (_refs && data[_refs[0]] === value) {
            return null;
        }
        const { error } = Joi.validate(obj, schema);
        if (consoleErrorValidation) {
            console.log('ValidateProperty', error);
        }
        return error ? error.details[0].message : null;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        /* eslint no-underscore-dangle: ["error", { "allow": ["__target__"] }] */
        if (e.__target__) {
            this.doSubmit(e.__target__);
            return;
        }

        const errors = this.validate();
        this.setState({ errors: errors || {} });

        if (!isEmpty(errors)) return;

        this.doSubmit(e);
    };

    handleCheckboxChange = (event) => {
        const { data } = this.state;
        data[event.target.value] = event.target.checked;
        this.setState({ data });
    };

    handleChangeRecap= (value, handleChangeRecap) => {
        const { data, errors } = this.state;
        const newInput = {};
        newInput.name = 'recaptcha';
        newInput.value = value;
        const errorMessage = this.validateProperty(newInput);
        if (errorMessage) errors[newInput.name] = errorMessage;
        else delete errors[newInput.name];
        data[newInput.name] = newInput.value;
        this.setState({ data, errors }, () => {
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
                this.doChange(data);
            } catch (ex) {}
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
                handleChangeRecap(data);
            } catch (ex) {}
        });
    }

    handleChange = (
        { currentTarget: input, __target__ },
        pattern,
        onChangeHandler,
    ) => {
        // eslint-disable-next-line object-curly-newline
        const { data, errors, replaceMask, replaceMaskWith } = this.state;
        const regexPattern = new RegExp(pattern);
        const target =            __target__
            && typeof __target__.value !== 'undefined'
            && typeof __target__.name !== 'undefined'
            ? __target__
            : input;
        if (typeof target === 'undefined') return;

        const newInput = {};
        newInput.name =            typeof target.getAttribute === 'function'
            && target.getAttribute('name')
            ? target.getAttribute('name')
            : target.name;
        newInput.value =            typeof target.getAttribute === 'function'
            && target.getAttribute('data-value')
            ? target.getAttribute('data-value')
            : target.value;
        // Get selectUI name
        const { nextSibling } = target;
        if (nextSibling && nextSibling.tagName === 'INPUT' && nextSibling.type === 'hidden') {
            if (typeof newInput.name === 'undefined') {
                newInput.name = nextSibling.name;
            }
            if (typeof newInput.value === 'undefined') {
                newInput.value = nextSibling.value;
            }
        }
        if (
            typeof newInput.value === 'string'
            && replaceMask
            && replaceMask[newInput.name]
            && replaceMaskWith
            && typeof replaceMaskWith[newInput.name] !== 'undefined'
        ) {
            if (replaceMaskWith[newInput.name] === 'toUpperCase') {
                newInput.value = newInput.value.toUpperCase();
            } else {
                newInput.value = newInput.value.replace(
                    new RegExp(replaceMask[newInput.name], 'g'),
                    replaceMaskWith[newInput.name],
                );
            }
        }
        if (newInput.value && pattern && !regexPattern.test(newInput.value)) {
            return;
        }

        const errorMessage = this.validateProperty(newInput);
        if (errorMessage) errors[newInput.name] = errorMessage;
        else delete errors[newInput.name];
        data[newInput.name] = newInput.value;

        this.setState({ data, errors }, () => {
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
                this.doChange(data);
            } catch (ex) {}
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
                onChangeHandler(data);
            } catch (ex) {}
        });
    };

    handleEventListener = (
        { currentTarget },
        name,
        onEventHandler,
    ) => {
        const input = currentTarget.querySelector(`input[name="${name}"]`);
        if (input && onEventHandler) {
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
                onEventHandler(input);
            } catch (ex) {}
        }
    };

    renderButton(props) {
        const {
            label,
            className,
            dataTest = null,
            onClick,
            type,
            waitingLabel = '',
            isValidated = true,
            isReadyState = true,
        } = props;
        let dataTestField = null;
        if (dataTest) {
            dataTestField = { 'data-test': dataTest.field };
        }
        // const onClickEvent = type === 'submit' ? this.handleSubmit : onClick;
        let isDisabled = isValidated ? !this.validate(true) : false;
        isDisabled = !isReadyState || isDisabled;
        return (
            <Button
                disabled={isDisabled}
                onClick={onClick}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...dataTestField}
                className={className}
                type={type}
            >
                {waitingLabel || label}
            </Button>
        );
    }

    renderInput(props) {
        const {
            name,
            label,
            type = 'text',
            dataTest = null,
            pattern = '',
            length = 200,
            multiline = false,
            placeholder = '',
            adornment = '',
            adornmentPosition = 'end',
            mask = {},
            onChangeHandler = '',
            customClass = '',
            onKeyupHandler = '',
            onKeypressHandler = '',
            onClickHandler = '',
            autoComplete = '',
            disabled = false,
        } = props;
        let dataTestLabel = null;
        let dataTestField = null;
        if (dataTest) {
            dataTestLabel = { 'data-test': dataTest.label };
            dataTestField = { 'data-test': dataTest.field };
        }
        const { data, errors, showPassword } = this.state;
        const InputProps = {};

        const errorMessage = ErrorLanguages(errors[name]);
        const isError = typeof errorMessage !== 'undefined';
        let output = null;
        let CustomTextField = null;

        if (!isEmpty(mask)) {
            InputProps.inputComponent = mask;
        }

        if (multiline) {
            CustomTextField = (
                <>
                    <div>{label}</div>
                    <TextField
                        className="text-field-wrapper"
                        name={name}
                        value={data[name]}
                        fullWidth
                        multiline
                        rows={4}
                        rowsMax={4}
                        type={type}
                        // eslint-disable-next-line object-curly-newline
                        inputProps={{ maxLength: length, ...dataTestField, placeholder, autoComplete, disabled, className: `input-field ${customClass}` }}
                        // eslint-disable-next-line
                    InputProps={InputProps}
                        InputLabelProps={
                            {
                                classes: {
                                    root: `input-label ${isError ? 'error' : ''}`,
                                },
                                ...dataTestLabel,
                            }
                        }
                        onChange={(event) => this.handleChange(event, pattern, onChangeHandler)}
                        onKeyUp={(event) => this.handleEventListener(event, name, onKeyupHandler)}
                        onKeyPress={(event) => this.handleEventListener(event, name, onKeypressHandler)}
                        onBlur={(event) => this.handleChange(event, pattern, onChangeHandler)}
                        onClick={(event) => this.handleEventListener(event, name, onClickHandler)}
                        error={isError}
                        helperText={errorMessage}
                        FormHelperTextProps={
                            {
                                classes: {
                                    root: `form-helper-text ${isError ? 'error' : ''}`,
                                },
                            }
                        }
                    />
                </>
            );
        } else {
            CustomTextField = (
                <TextField
                    className="text-field-wrapper"
                    name={name}
                    value={data[name]}
                    label={label}
                    fullWidth
                    type={showPassword && showPassword[name] ? 'text' : type}
                    // eslint-disable-next-line object-curly-newline
                    inputProps={{ maxLength: length, ...dataTestField, placeholder, autoComplete, disabled, className: `input-field ${customClass}` }}
                    // eslint-disable-next-line
                    InputProps={InputProps}
                    InputLabelProps={
                        {
                            classes: {
                                root: `input-label ${isError ? 'error' : ''}`,
                            },
                            ...dataTestLabel,
                        }
                    }
                    onChange={(event) => this.handleChange(event, pattern, onChangeHandler)}
                    onKeyUp={(event) => this.handleEventListener(event, name, onKeyupHandler)}
                    onKeyPress={(event) => this.handleEventListener(event, name, onKeypressHandler)}
                    onBlur={(event) => this.handleChange(event, pattern, onChangeHandler)}
                    onClick={(event) => this.handleEventListener(event, name, onClickHandler)}
                    error={isError}
                    helperText={errorMessage}
                    FormHelperTextProps={
                        {
                            classes: {
                                root: `form-helper-text ${isError ? 'error' : ''}`,
                            },
                        }
                    }
                />
            );
        }
        output = CustomTextField;
        if (/^icon-start$/.test(adornmentPosition)) {
            output = (
                <div className="split align-bottom">
                    <div className="flex-none no-padding margin-right-half margin-bottom-left-icon">
                        {adornment}
                    </div>
                    <div>
                        {CustomTextField}
                    </div>
                </div>
            );
        }
        return output;
    }

    renderRecaptcha(name) {
        const {  errors } = this.state;
        let errorMessage = ErrorLanguages(errors[name]);
        const isError = typeof errorMessage !== 'undefined';
        errorMessage = 'ReCaptcha is a required field.';
        // Set reCapcha key for localhost if it doesn't exist in the env file
        const APP_GOOGLE_RECAPCHA_KEY = mbpUtil.getEnv('APP_GOOGLE_RECAPCHA_KEY') ? mbpUtil.getEnv('APP_GOOGLE_RECAPCHA_KEY') : '6Le6ArEUAAAAANKfCmBmOEkIIWJVMuIUS926fonG';
        return (
            <>
                <ReCAPTCHA
                    defaultChecked
                    sitekey={APP_GOOGLE_RECAPCHA_KEY}
                    onChange={this.handleChangeRecap}
                    tabindex="0"
                    size="normal"
                />
                {isError ? (
                    <FormHelperText className={`form-helper-text ${isError ? 'recaptchaError' : ''}`}>{errorMessage}</FormHelperText>
                ) : (
                    ''
                )}
            </>
        );
    }

    render() {
        return null;
    }
}

export default Form;
