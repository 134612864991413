/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import gql from 'graphql-tag';
import { setLocale } from '../../../state/ducks/Member/ducks/Auth/helper/helper';
/**
 * @param {string} brand - baskets or berries
 * @param {string} contentType - type of page, i.e. template_page_customer_service
 */
const findCustomerServiceDetails = () => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`
        query CustomerService($brand: String!, $environment: String!, $contentType: String!) {
            findContent(brand: $brand, environment: $environment, contentType: $contentType, locale: "${setLocale()}")
            {
                content
            }
        }
    `
);

export default findCustomerServiceDetails;
