/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { object } from 'prop-types';
import { Link } from 'react-router-dom';

const PageSubheading = ({ classes }) => (
    <Grid container className={`${classes.container} ${classes.subHeaderBlock} ${classes.bannerContainer}`}>
        <Grid xs={12} md={8} item>
            <h3 className={classes.textSameDay}>
                <span>Need help with your order? </span>
                <div>
                    Call <b>1-866-538-2259</b> to speak with one of our trusted sympathy specialists.
                </div>
            </h3>
        </Grid>
        <Grid xs={12} md={4} item>
            <Link to="/connection-communities">
                <div className={classes.wisdoImageBlock}>
                    <img src="//cdn4.1800flowers.com/wcsstore/Flowers/images/2020/sympathy/wisdo-connection-communities.jpg " alt="wisdo connection" />
                </div>
            </Link>
        </Grid>
    </Grid>
);

PageSubheading.propTypes = {
    classes: object.isRequired,
};
export default PageSubheading;
