/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import ContactUsBannerBuilderPLA from '../ContactUsBanner/ContactUsBannerBuilderPLA';
import GraphqlSeoHeadTags from '../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import triggerPopChatEvent from '../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';
import { getOperatingSystem } from '../../../../../state/ducks/App/ducks/Config/Config-Helpers';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down(767)]: {
            paddingRight: '15px',
            paddingLeft: '15px',
        },
        [theme.breakpoints.down(991)]: {
            maxWidth: '720px',
        },
    },
    root: {
        cursor: 'default',
        '& p': {
            marginTop: '-8px',
            [theme.breakpoints.down(991)]: {
                marginBottom: '0.5rem',
            },
        },
        '& h1': {
            margin: '0',
            color: '#2e2c2c',
            fontSize: '2.8125rem',
            fontFamily: 'BebasNeue',
            transform: 'uppercase',
            lineHeight: '3.75rem',
            fontWeight: '500',
        },
        '& h2': {
            marginTop: '-20px',
            fontSize: '2.8125rem',
            fontFamily: 'BebasNeue',
            lineHeight: '3.75rem',
            fontWeight: '500',
            marginBottom: '0',
        },
        '& .services-holder': {
            paddingTop: '30px',
            marginLeft: '-5px',
            marginRight: '-5px',
        },
        '& .service-wrapper': {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingBottom: '10px',
            position: 'relative',
            float: 'left',
            minHeight: '1px',
            '@media (min-width: 768px)': {
                width: '33.33333%',
                maxWidth: '33.3333%',
                flexBasis: '33.33333%',
            },
        },
        '& .service-inner': {
            display: 'flex',
            padding: '15px',
            minHeight: '115px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#B9E529',
            [theme.breakpoints.down(767)]: {
                minHeight: '175px',
                display: 'block',
            },
        },
        '& .m-hide': {
            maxHeight: '65px',
            maxWidth: '100%',
            [theme.breakpoints.down(767)]: {
                display: 'none',
            },
        },
        '& .d-hide': {
            display: 'none',
            [theme.breakpoints.down(767)]: {
                display: 'block',
                bottom: '25px',
                position: 'absolute',
                paddingTop: '20px',
                '& img': {
                    maxHeight: '50px',
                },
            },
        },
        '& .service-title': {
            fontFamily: 'BebasNeue',
            fontSize: '1.75rem',
            opacity: '1',
            color: '#2e2c2c',
            lineHeight: '1.75rem',
            letterSpacing: '0',
            paddingLeft: '15px',
            fontWeight: '700',
            [theme.breakpoints.down(767)]: {
                lineHeight: '25px',
                paddingRight: '15px',
                paddingLeft: '0',
            },
        },
        '& #services': {
            marginBottom: '1.5rem !important',
        },
    },
});

const PlantsCustomerService = ({ classes, data }) => {
    const dispatch = useDispatch();
    const { seo = {}, modular_blocks = [] } = data;
    let heroData = {};
    let servicesData = {};
    let bannerData = {};
    heroData = modular_blocks[0]?.hero;
    servicesData = modular_blocks[1]?.options?.group;
    bannerData = modular_blocks[2]?.banner;
    const operatingSystem = getOperatingSystem()?.toLowerCase();

    const [isChat, setIsChat] = useState(false);
    const clickServiceData = (service, event) => {
        dispatch(trackEvent({
            eventName: 'feature_interaction', // Requird for GA4 feature_click
            eventCategory: 'Customer Service',
            eventAction: 'Click',
            eventLabel: service.link.title,
        }));
        if (service.link.title === 'Chat With Us') {
            event.preventDefault();
            if (operatingSystem !== 'android' && operatingSystem !== 'ios') {
                triggerPopChatEvent({});
            }
            setIsChat(true);
        }
    };
    if (operatingSystem === 'android' || operatingSystem === 'ios') {
        servicesData = servicesData.filter((currentService) => currentService?.link?.title !== 'Chat With Us');
    }

    return (
        <Container className={classes.container}>
            <div className={classes.root}>
                {
                    seo?.seo_searchable
                        ? (
                            <GraphqlSeoHeadTags seoData={seo} alternativeLang="true" />
                        ) : null
                }
                <section id="customer-service-page">
                    <article id="main-heading" style={{ marginBottom: '1rem !important' }}>
                        <div>
                            <ReactMarkdown escapeHtml={false} source={heroData?.copy} />
                        </div>
                    </article>
                    <article id="services">
                        <Grid container className="services-holder">
                            {servicesData.map((service) => (
                                <Grid lg={4} md={4} xs={6} s={6} className="service-wrapper">
                                    <Link to={service?.link?.href} style={{ textDecoration: 'none' }} onClick={(event) => clickServiceData(service, event)}> {/* eslint-disable-line */}
                                        <div className="service-inner">
                                            <img className="m-hide" src={service?.icon_image?.url} alt={service?.icon} />
                                            <span className="service-title">{service?.link?.title}</span>
                                            <div className="d-hide">
                                                <img src={service?.icon_image?.url} alt={service?.icon} />
                                            </div>
                                        </div>
                                    </Link>
                                </Grid>
                            ),
                            )}
                        </Grid>
                        {isChat ? <ContactUsBannerBuilderPLA /> : ''}
                    </article>
                    <article id="service-content">
                        <ReactMarkdown escapeHtml={false} source={bannerData?.main_copy} />
                    </article>
                </section>
            </div>

        </Container>
    );
};

PlantsCustomerService.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
};

export default withStyles(styles)(PlantsCustomerService);
