/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, bool, string, func,
} from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import  { compose } from 'recompose';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import loadable from '@loadable/component';

import GraphqlSearchContainer from '../GraphqlSearchPage/GraphqlSearchContainer';
import CoreBrowsingRedirectContainer from './CoreBrowsingRedirectContainer';

import { trackModule } from '../../../helpers/tracking/common/commonTrackingHelpers';
import findURL from '../../../gql/queries/findURL';
import { GRAPHQL_ENV } from '../../../gql';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { setPageInformation } from '../../../../state/ducks/App/App-Actions';

const LoadableCoreBrowsingBuilder = loadable(() => import(/* webpackChunkName: "CoreBrowsingBuilder" */ './CoreBrowsingBuilder'));
/*
    Component Intent
        - This component's job is to take a path, vanity url, or bannercode and begin page determination
        - The priority for findURL is vanity > bannercode > path
        - When the findURL call is made we recieve a type back
        - That type will tell us what page to build and query to run next

        - There should most likely be 2 fallbacks:
            * if a pathname has no type returned, run a Search query
            * if no pathname, or a totally bogus one, revert to HomePage
*/
const NavigationBrowsingContainer = ({
    location: { pathname, search }, match: { params: { slug } }, brand, featureFlags, isBot = false, is404, productUnavailableName, pageInfo,
}) => {
    // after initial search findURL fails to run again
    // below if statement checks if 'searchterm' exists in url before calling find URL
    // if exists it will call search container
    if (pathname.includes('searchterm')) {
        const pathnameArr = pathname.split('/');
        const searchTerm = pathnameArr[2];
        return <GraphqlSearchContainer brand={brand} searchTerm={searchTerm} />;
    }
    // on page transition, bring user back to top of page - currently not working on back nav when cached
    useEffect(() => {
        if (typeof window !== 'undefined' && window.scroll) {
            window.scroll(0, 0);
        }
    });
    const pathNameStr = `/${slug}`;
    const FIND_URL_QUERY = findURL(brand.domain, pathNameStr);
    const { loading, data, error } = useQuery(FIND_URL_QUERY);

    if (loading) {
        return null;
    }

    if (error || !data) {
        mbpLogger.logError({
            query: FIND_URL_QUERY,
            component: 'NavigationBrowsingContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    let pageData =  data.findURL;
    pageData = { path: pathNameStr, search, ...pageData };

    if (mbpUtil.getEnv('REACT_APP_MBP_LOGGER_CONSOLE') === 'DEBUG') {
        console.log('NavigationBrowsingContainer pageData', pageData);
    }
    pageInfo(pageData?.type);
    if (featureFlags['is-redirect-testing-enabled']
        && !isBot
        && pageData?.redirectTesting
    ) {
        mbpLogger.logDebug({ message: 'NavigationBrowsingContainer ... redirecting' });

        return (
            <CoreBrowsingRedirectContainer
                page={pageData}
                featureFlags={featureFlags}
                brand={brand}
            />
        );
    }

    mbpLogger.logDebug({ message: `NavigationBrowsingContainer ... sending to CoreBrowsingBuilder.  is404? ${is404}` });
    return (
        <LoadableCoreBrowsingBuilder
            brand={brand}
            page={pageData}
            is404={is404}
            productUnavailableName={productUnavailableName}
        />
    );
};

NavigationBrowsingContainer.propTypes = {
    location: object.isRequired,
    brand: object.isRequired,
    featureFlags: object.isRequired,
    isBot: bool.isRequired,
    is404: bool,
    productUnavailableName: string,
    pageInfo: func.isRequired,
    match: {
        params: {
            slug: string.isRequired,
        },
    },
};

NavigationBrowsingContainer.defaultProps = {
    is404: false,
    productUnavailableName: '',
    match: {
        params: {
            slug: '',
        },
    },
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    featureFlags: getFeatureFlags(state),
    isBot: getIsBot(state),
});

const mapDispatchToProps = (dispatch) => ({
    pageInfo: bindActionCreators(setPageInformation, dispatch),
});

const enhance = compose(
    trackModule('NavigationBrowsingContainer'),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(NavigationBrowsingContainer);
