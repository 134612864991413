/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'Request Redelivery',
    description: 'Let&apos;s get you squared away. Please fill out the form below and hit continue. You will receive a notification if the customer service agent assigned to handle your order needs any additional information or to confirm rescheduling.',
    form_header: 'I would like to request a redelivery',
};
export default data;
