/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GBMChatLinksByBrand = {
    '18F': 'https://business.google.com/initiateBusinessChat?args=CigIAxIkZGM5Njg1ZTgtZjIwNS00ZTRiLWI0YTAtYmIxYTBkZTU3NjZlYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkZGM5Njg1ZTgtZjIwNS00ZTRiLWI0YTAtYmIxYTBkZTU3NjZlGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    '18B': 'https://business.google.com/initiateBusinessChat?args=CigIAxIkNDhjMmQ4Y2EtZTI2MC00OWI4LWI0YTEtZTlmNTdiNGY3MmFjYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkNDhjMmQ4Y2EtZTI2MC00OWI4LWI0YTEtZTlmNTdiNGY3MmFjGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    HD: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkMjc3ZjE1YjQtMmRjOC00ZTVmLTk0NmMtMzllNTk1NDk5NzdkYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkMjc3ZjE1YjQtMmRjOC00ZTVmLTk0NmMtMzllNTk1NDk5NzdkGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    CCO: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkY2NkMGIxZmYtYjQ4My00NTEzLWFkNjQtYzU0YzkyNWVlMjdlYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkY2NkMGIxZmYtYjQ4My00NTEzLWFkNjQtYzU0YzkyNWVlMjdlGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    TPF: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkNzAwMWRmNzAtNTFiNC00YWQyLTg0MTktMTJmNjI0M2U5YWI4Ygd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkNzAwMWRmNzAtNTFiNC00YWQyLTg0MTktMTJmNjI0M2U5YWI4GgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    SCH: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkYTcwYWQxZTMtNTllOC00NDAwLTkzMmItNDZhZDMwM2JmZTk0Ygd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkYTcwYWQxZTMtNTllOC00NDAwLTkzMmItNDZhZDMwM2JmZTk0GgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    SY: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkMDU4YWQ3YTAtOGZjMi00OTUzLTg2ZDctMWE5MTk0YWRiOGMzYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkMDU4YWQ3YTAtOGZjMi00OTUzLTg2ZDctMWE5MTk0YWRiOGMzGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    WLF: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkMDUxYjFmYjMtYjg2Mi00NzA3LTk3Y2ItNzU2ZDgwOWRlMWQ2Ygd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkMDUxYjFmYjMtYjg2Mi00NzA3LTk3Y2ItNzU2ZDgwOWRlMWQ2GgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    FB: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkZjkzZmY4YzAtZDIyYS00ZTQ2LWE1NDQtODUzNWIyMmRhYjMwYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkZjkzZmY4YzAtZDIyYS00ZTQ2LWE1NDQtODUzNWIyMmRhYjMwGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    FBQ: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkZjkzZmY4YzAtZDIyYS00ZTQ2LWE1NDQtODUzNWIyMmRhYjMwYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkZjkzZmY4YzAtZDIyYS00ZTQ2LWE1NDQtODUzNWIyMmRhYjMwGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    BRY: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkZDM2YTQ1YTAtNTA3ZC00ZGViLTk5ZTAtZDk3ZGUyMmExZDgxYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkZDM2YTQ1YTAtNTA3ZC00ZGViLTk5ZTAtZDk3ZGUyMmExZDgxGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
    VC: 'https://business.google.com/initiateBusinessChat?args=CigIAxIkNTBlMGYxMTctNGI3OC00ZTliLWJjODctN2U1YmRhMzA5MDZlYgd3ZWJ2aWV3aiJodHRwOi8vYnVzaW5lc3NtZXNzYWdlcy5nb29nbGUuY29tckIIAxIkNTBlMGYxMTctNGI3OC00ZTliLWJjODctN2U1YmRhMzA5MDZlGgpjYy1saXN0aW5nIgw5Njg2MTA1NTQ1NDCaAYsBChJjYy1saXN0aW5nX2NvbnRleHQSdQo4dHlwZS5nb29nbGVhcGlzLmNvbS9jaGF0LmJvdC5wbGF0Zm9ybS5CdXNpbmVzc0h1YkNvbnRleHQSOUgIWiQKGG92ZXJyaWRlX2NybV9lbnRyeV9wb2ludBIIVEVTVF9VUkxaDwoHaXNfdGVzdBIEdHJ1ZbIBOwo5SAhaJAoYb3ZlcnJpZGVfY3JtX2VudHJ5X3BvaW50EghURVNUX1VSTFoPCgdpc190ZXN0EgR0cnVl&hl=en',
};

export const ABCChatLinksByBrand = {
    '18F': 'https://bcrw.apple.com/urn:biz:a80a7352-e042-11e7-af38-7b88b04daa8e',
    '18B': 'https://bcrw.apple.com/urn:biz:df8107e5-b9a6-4e68-b329-e3e5b1247e21',
    HD: 'https://bcrw.apple.com/urn:biz:9ab55a12-ccb6-458d-8c6a-758cbf5ec242',
    CCO: 'https://bcrw.apple.com/urn:biz:f45ba478-770d-4a0a-b07f-0150d513a83b',
    TPF: 'https://bcrw.apple.com/urn:biz:2f592990-00e3-4d90-95d4-e6166978019e',
    SCH: 'https://bcrw.apple.com/urn:biz:f2e39503-9a29-4785-addb-bb89f0733e13',
    SY: 'https://bcrw.apple.com/urn:biz:54faf34c-2648-409d-ba11-a4626037a085',
    WLF: 'https://bcrw.apple.com/urn:biz:0a995446-c8f7-4206-83e5-da936feed683',
    FB: 'https://bcrw.apple.com/urn:biz:4a741121-9782-4688-8d64-e6c485394b26',
    FBQ: 'https://bcrw.apple.com/urn:biz:4a741121-9782-4688-8d64-e6c485394b26',
    BRY: 'https://bcrw.apple.com/urn:biz:5008980e-55ab-4892-8de5-1b2de545bcfe',
    VC: 'https://bcrw.apple.com/urn:biz:a8b8309f-5b4e-47b6-a2f8-b5ba8fb33c6c',
};

export const determineChatlink = (operatingSystem, currentBrand, featureFlags) => {
    let chatLink = '';
    if (operatingSystem === 'android') {
        if (featureFlags['is-gbm-floral-brands-enabled'] && ['18B', 'SCH', 'BRY', 'FB', 'FBQ'].includes(currentBrand)) {
            chatLink = GBMChatLinksByBrand['18F'];
        } else {
            chatLink = GBMChatLinksByBrand[currentBrand];
        }
    } else if (operatingSystem === 'ios') {
        chatLink = ABCChatLinksByBrand[currentBrand];
    }
    return chatLink;
};
