/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import { object, string, bool } from 'prop-types';
import mbpLogger from 'mbp-logger';
import gql from 'graphql-tag';
import { GRAPHQL_ENV } from '../../../../gql';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import isPastCutoff from '../../../../helpers/determineCutoffTime';
import memberDucks from '../../../../../state/ducks/Member/ducks';
import ConnectedGraphqlUnderNavTextWidget from './GraphqlUnderNavTextWidget';

const {
    common: {
        commonSelectors: { getUserRole, getUserType },
    },
} = memberDucks;

const determineLocale = (page, isCanadianCategory) => {
    if (page?.path?.includes('canada') || isCanadianCategory) {
        return 'en-ca';
    }
    return 'en-us';
};

const GraphqlUnderNavTextWidgetContainer = ({
    userRole, brand, userType, page, isCanadianCategory, presentationFamily,
}) => {
    const UNT_QUERY = gql`
    query FindUnderNavTextQuery {
        findUnderNavText(brand: "${brand['domain-name']}", locale: "${determineLocale(page, isCanadianCategory)}", environment: "${GRAPHQL_ENV}", isBeforeCutOff:"${!isPastCutoff()}", isGuest:"${userType === 'G'}", isPassportUser:"${userRole === 'P'}")
  {
content
  }

    }
    `;
    const { error, data } = useQuery(UNT_QUERY);

    if (error) {
        mbpLogger.logError({
            query: UNT_QUERY,
            component: 'GraphqlUnderNavTextWidgetContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    let content;
    if (data?.findUnderNavText) {
        ({ content } = data.findUnderNavText);
    }

    if (!content?.entries?.[0]) {
        mbpLogger.logWarning({
            query: UNT_QUERY,
            component: 'GraphqlUnderNavTextWidgetContainer.js',
            message: 'No data returned from graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    return <ConnectedGraphqlUnderNavTextWidget data={content?.entries?.[0]} contentType={page.contentType} presentationFamily={presentationFamily} />;
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    userRole: getUserRole(state),
    userType: getUserType(state),
});

GraphqlUnderNavTextWidgetContainer.propTypes = {
    brand: object.isRequired,
    userRole: string.isRequired,
    userType: string.isRequired,
    page: object.isRequired,
    isCanadianCategory: bool,
    presentationFamily: string,
};

GraphqlUnderNavTextWidgetContainer.defaultProps = {
    isCanadianCategory: false,
    presentationFamily: '',
};

export default connect(mapStateToProps)(GraphqlUnderNavTextWidgetContainer);
