/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactMarkdown from 'react-markdown';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useUIDSeed } from 'react-uid';
import {
    object, string, number, node, shape, array,
} from 'prop-types';

import CustomerOptionsBlock from './Partials/CustomerOptionsBlock';
import PlantsCustomerService from './Partials/PlantsCustomerService';
import ContacUsBlock from './Partials/ContactUsBlock';
import GraphqlBreadcrumbs from '../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import HeroBlock from './Partials/HeroBlock';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import CustomerServiceContainer from './ContactUsBanner/ContactUsBannerContainer';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import OutageMessage from './Partials/OutageMessage/OutageMessage';

const styles = (theme) => ({
    container: {
        maxWidth: '1400px',
        margin: '30px auto 80px auto',
        [theme.breakpoints.down('md')]: {
            margin: '20px auto',
        },
        '&> div': {
            '&:nth-child(2)': {
                [theme.breakpoints.down(1400)]: {
                    padding: '0 15px',
                },
            },
        },
    },
    faqContainer: {
        maxWidth: '80%',
        margin: 'auto',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '95%',
        },
        '& h3': {
            padding: '10px',
            fontSize: '14px',
            fontWeight: 'normal',
            marginBottom: 0,
        },
        '& button': {
            maxWidth: 350,
            textTransform: 'capitalize',
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
                maxWidth: 120,
            },
        },
    },
    justifyContent: {
        justifyContent: 'space-between',
    },
    answers: {
        padding: '30px',
        margin: '0',
        background: theme.palette.csBgColor,
        '& a': {
            color: theme.palette.colorPrimary,
        },
    },
    guaranteeContainer: {
        width: '70%',
        margin: '30px auto',
        padding: '20px',
        border: '10px solid #CCC',
        '& h4': {
            margin: 0,
            textAlign: 'center',
        },
    },
    heroBlock: {
        alignSelf: 'center',
    },
    rightContainer: {
        paddingLeft: '10%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '0',
        },
    },
    breadCrumbsCustomerService: {
        [theme.breakpoints.down(1400)]: {
            padding: '0 15px',
        },
    },
    contactUsGridContainer: {
        marginRight: '10px',
        marginLeft: '10px',
    },
});

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <>
            <div
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`wrapped-tabpanel-${index + 1}`}
                aria-labelledby={`wrapped-tab-${index + 1}`}
                {...other}
            >
                {value === index ? (
                    <>
                        {children}
                    </>
                ) : null}
            </div>
        </>
    );
}

TabPanel.propTypes = {
    children: node.isRequired,
    index: number.isRequired,
    value: number.isRequired,
};

const CustomerServiceBuilder = ({
    classes, data, brand, page, featureFlags, ssrDeviceType,
}) => {
    const seed = useUIDSeed();
    let heroData = {};
    let optionsData = {};
    let contactUs = {};
    let faq = {};
    let guarantee = {};
    const { seo = {} } = data;

    if (brand?.code === 'PLA') {
        return <PlantsCustomerService data={data} />;
    }
    if (data?.modular_blocks?.length && data !== undefined) {
        data.modular_blocks.forEach((dataList) => {
            const type = Object.keys(dataList)[0];
            switch (type) {
                case 'hero':
                    heroData = dataList?.hero;
                    break;
                case 'options':
                    optionsData = dataList;
                    break;
                case 'contact_us':
                    contactUs = dataList?.contact_us;
                    break;
                case 'faq':
                    faq = dataList?.faq;
                    break;
                case 'guarantee':
                    guarantee = dataList?.guarantee;
                    break;
                default:
                    return null;
            }
            return type;
        });
    }

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const a11yProps = (index) => ({
        id: `wrapped-tab-${index + 1}`,
        'aria-controls': `wrapped-tabpanel-${index + 1}`,
        tabIndex: index + 1,
    });

    const GetIcon = ({ icon }) => {
        switch (icon) {
            case 'Edit':
                return <EditIcon />;
            case 'LocalShippingIcon':
                return <LocalShippingIcon />;
            case 'ErrorOutlineIcon':
                return <ErrorOutlineIcon />;
            case 'MailOutlineIcon':
                return <MailOutlineIcon />;
            default:
                return null;
        }
    };

    GetIcon.propTypes = {
        icon: string.isRequired,
    };
    return (
        <>
            {
                seo?.seo_searchable
                    ? (
                        <GraphqlSeoHeadTags seoData={seo} alternativeLang="true" />
                    ) : null
            }

            <div className={classes.container}>
                {seo.breadcrumb && (
                    <div className={classes.breadCrumbsCustomerService}>
                        <GraphqlBreadcrumbs breadCrumbArray={seo.breadcrumb} />
                    </div>
                ) }
                <Grid container>
                    {/* Hero Block */}
                    { Object.keys(heroData).length > 0
                    && (
                        <Grid item xs={12} sm={4} className={classes.heroBlock}>
                            <HeroBlock data={heroData} showTitle ssrDeviceType={ssrDeviceType} />
                        </Grid>
                    )}
                    <Grid container xs={12} sm={8} className={classes.rightContainer}>
                        {/* Customer Option Block */}
                        { Object.keys(optionsData).length > 0
                        && (
                            <Grid item xs={12}>
                                <CustomerOptionsBlock data={optionsData} />
                            </Grid>
                        )}
                        {/* Hero Block */}
                        { (Object.keys(heroData).length > 0 && ssrDeviceType === 'mobile')
                        && (
                            <Grid item xs={12} md={4} className={classes.heroBlock}>
                                <HeroBlock data={heroData} showTitle={false} ssrDeviceType={ssrDeviceType} />
                            </Grid>
                        )}
                        {/* Contact us Block */}
                        { Object.keys(contactUs).length > 0
                        && (
                            <Grid item xs={12} className={classes.contactUsGridContainer}>
                                <ContacUsBlock data={contactUs} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {featureFlags['is-atlas-maintenance-alert-enabled'] && brand?.code !== 'CAN' && (
                    <OutageMessage />
                )}
                { Object.keys(guarantee).length > 0 && brand?.code !== 'CAN'
                && (
                    <div className={classes.guaranteeContainer} style={{ borderColor: guarantee.border_color ? guarantee.border_color : '' }}>
                        <h4>{guarantee.header || ''}</h4>
                        <ReactMarkdown
                            source={guarantee.copy || ''}
                            skipHtml
                        />
                    </div>
                )                }
                {/* Faq Block */}
                { Object.keys(faq).length > 0 && brand?.code !== 'CAN'
                && (
                    <div className={classes.faqContainer}>
                        <h3 style={{ backgroundColor: faq?.header_background_color }}>
                            {faq?.header}
                        </h3>
                        <Tabs value={value} onChange={handleChange} classes={{ flexContainer: classes.justifyContent }} aria-label="wrapped label tabs example">
                            {faq.group.map((d, i) => <Tab key={seed(d)} value={i} label={d?.faq_topic} {...a11yProps(i)} />)}
                        </Tabs>
                        {faq.group.map((d, i) => (
                            <TabPanel key={seed(d)} value={value} index={i}>
                                <div style={{ fontSize: '14px' }}>
                                    {d.group.map((info) => (
                                        <ReactMarkdown
                                            key={seed(info)}
                                            source={info?.answers}
                                            escapeHtml={false}
                                            className={classes.answers}
                                        />
                                    ))}
                                </div>
                            </TabPanel>
                        ))}
                    </div>
                )}
            </div>
            {featureFlags['is-webchat-enabled'] && <CustomerServiceContainer brand={brand} page={page} />}
        </>
    );
};

CustomerServiceBuilder.propTypes = {
    classes: object.isRequired,
    data: shape({
        modular_block: array,
    }).isRequired,
    brand: object.isRequired,
    page: object.isRequired,
    featureFlags: object.isRequired,
    ssrDeviceType: string.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    ssrDeviceType: getSSRDeviceType(state),
});

export default connect(mapStateToProps)(withStyles(styles)(CustomerServiceBuilder));
