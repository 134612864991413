/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, shape, string,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown';

const styles = (theme)  => ({
    contactUsContainer: {
        padding: '30px 50px',
        backgroundColor: theme.palette.csh?.backgroundColor,
        [theme.breakpoints.down('md')]: {
            padding: '20px 10px',
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        '& h2': {
            margin: 0,
            fontSize: 24,
            display: 'inline',
            marginBottom: '20px',
            float: 'right',
            marginTop: '10px',
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                marginTop: 0,
            },
        },
        '& p': {
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            marginTop: '10px',
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                fontSize: 13,
                margin: 0,
            },
        },
    },
    phoneIcon: {
        width: '50px',
        marginRight: '30px',
        [theme.breakpoints.down('md')]: {
            width: '25px',
            marginRight: '5px',
        },
    },
});

const ContactUsBlock = ({  data, classes }) => {
    const { header, header_icon, call_copy_english } = data;
    return (
        <Grid container className={classes.contactUsContainer} justify="space-between" xs={12}>
            <Grid item>
                <div>
                    {header_icon && <img className={classes.phoneIcon} src={header_icon.url} alt="contact us icon" />}
                    <h2>{header}</h2>
                </div>
            </Grid>
            <Grid item>
                <ReactMarkdown
                    source={call_copy_english}
                    skipHtml
                />
            </Grid>
        </Grid>
    );
};

ContactUsBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        header: string,
        header_icon: shape({
            url: string.isRequired,
        }).isRequired,
        call_copy_english: string,
        call_copy_spanish: string,
    }).isRequired,

};
export default withStyles(styles)(ContactUsBlock);
