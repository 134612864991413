/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    bannerImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-banner-large-new.jpg',
    brandList: [
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-18f-logo.png',
            productName: 'Mother&#39;s Embrace<sup>&#8482;',
            produLink: 'https://www.1800flowers.com/florist-delivered-mothers-embrace-163053',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-18f-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-hd-logo.png',
            productName: 'Tower of Treats<sup>&reg;</sup>Classic</br> Mother&#39;s Day Gift',
            produLink: 'https://www.harryanddavid.com/h/gift-baskets-tower-boxes/seasonal-gift-towers/26977',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-hd-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-cco-logo.png',
            productName: 'Happy Mother&#39;s Day</br>Cookie Pail',
            produLink: 'https://www.cheryls.com/happy-mothers-day-cookie-pail-cco-spr19-204791?categoryId=400020482',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-cco-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-sc-logo.png',
            productName: 'Simply Chocolate</br>Celebrate Spring Gift Basket',
            produLink: 'https://www.simplychocolate.com/simply-chocolate-celebrate-spring-gift-basket-163377?categoryId=400197995',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-sc-product-new.jpg',
        },

        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-18b-logo.png',
            productName: 'Full Bloom</br>Sweet Treats Gift Basket',
            produLink: 'https://www.1800baskets.com/full-bloom-sweet-treats-gift-basket-170815?categoryId=400086177',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-18b-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-fb-logo.png',
            productName: 'Mom&#39;s the Best<sup>&#8482;</sup>',
            produLink: 'https://www.fruitbouquets.com/fruit-bouquets-a-treat-for-mom-163084?categoryId=400113016',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-fb-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-wf-logo.png',
            productName: 'Mother&#39;s Day Gift Tower',
            produLink: 'https://www.wolfermans.com/w/gourmet-bakery-gifts/towers/50648?categoryId=400148578',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-wf-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-tpf-logo.png',
            productName: 'Moms are</br>Amazing Popcorn Tins',
            produLink: 'https://www.thepopcornfactory.com/moms-are-amazing-popcorn-tins-tpf-69161?categoryId=400024482',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-tpf-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-pu-logo.png',
            productName: 'Personalized</br>Mom Established Mug',
            produLink: 'https://www.personalizationuniverse.com/personalized-mom-established-mug',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-pu-product-new.jpg',
        },
        {
            logo: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-gd-logo.png',
            productName: 'The Claw Foot Tub',
            produLink: 'https://www.goodsey.com/the-claw-foot-tub-mk000158?categoryId=400208784',
            productImage: '//cdn4.1800flowers.com/wcsstore/Flowers/images/c-mdgg-gd-product-new.jpg',
        },
    ],
    bottomBanner: {
        link: '/moms-who-wow-sweepstakes',
        imagePath: '//cdn4.1800flowers.com/wcsstore/Flowers/images/MomsWhoWow_1180x280.jpg',
    },
};
export default data;
