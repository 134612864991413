/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { Component } from 'react';
import { object } from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Data from './ViewData';
import ContactUsBannerContainer from '../ContactUsBanner/ContactUsBannerContainer';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
            padding: 50,
            border: `1px solid ${theme.palette.grey10}`,
        },
    },
    panel: {
        boxShadow: 'none',
        '&:first-child': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        '&:last-child': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        '&:before': {
            height: 0,
        },
    },
    breadCrumb: {
        marginTop: 10,
        '& a': {
            color: theme.palette.common.black,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: 15,
            outline: 'none',
        },
    },
    arrowSign: {
        fontSize: 16,
        verticalAlign: 'middle',
    },
    panelDescription: {
        borderBottom: `1px solid ${theme.palette.grey10}`,
    },
    panelDetails: {
        '& p': {
            fontSize: 14,
            '& a': {
                color: theme.palette.colorPrimary,
            },
        },
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    header: {
        '& h1': {
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: 40,
        },
    },
});

class CustomerServiceFaqPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            const pathArray = window?.location?.href?.split('?');
            const searchElement = pathArray?.[1]?.replace('scrollTo=', '').trim() || '';
            if (searchElement !== '') {
                const targetElement = document.querySelector(`#${searchElement}`);
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                document.querySelector(`#${searchElement}>div`).click();
            }
        }
    }

    handleChange =  (panel) => (event, expanded) => {
        if (expanded) {
            this.setState({ expanded: panel });
        } else {
            this.setState({ expanded: false });
        }
    }

    render() {
        const { classes, page, brand } = this.props;
        const { expanded } = this.state;
        const { list, title } = Data;
        return (
            <>
                <div className={classes.container}>
                    <div className={classes.breadCrumb}><Link to="/customer-service"><ArrowBackIosIcon className={classes.arrowSign} />Help Center</Link></div>
                    <Grid className={classes.header} item xs={12}>
                        <h1>{title}</h1>
                    </Grid>
                    {list.length && list.map((data, i) => (
                        <ExpansionPanel key={data.id} id={data.id} className={classes.panel} expanded={expanded === `panel${i}`} onChange={this.handleChange(`panel${i}`)}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelDescription}>
                                <div className={classes.title}>{data.title}</div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.panelDetails}>
                                <div>
                                    {data.description.length && data.description.map((desList) => {
                                        const { header, desData } = desList;
                                        return (
                                            <div key={header}><h4>{header}</h4>
                                                <div>{ReactHtmlParser(desData)}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                </div>
                <ContactUsBannerContainer page={page} brand={brand} />
            </>
        );
    }
}
CustomerServiceFaqPage.propTypes = {
    classes: object.isRequired,
    page: object.isRequired,
    brand: object.isRequired,
};
export default withStyles(styles)(CustomerServiceFaqPage);
