/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import {
    object,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReactHtmlParser from 'react-html-parser';
import Data from './ViewData';
import ContactUsBannerContainer from '../ContactUsBanner/ContactUsBannerContainer';
import toggleWebChat from '../../../../helpers/toggleWebChat';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
    title: {
        textAlign: 'center',
        '& h2': {
            fontSize: 28,
        },
        '& p': {
            fontSize: 15,
            margin: '30px 0',
            padding: '0px 30px',
            textAlign: 'left',
            '& button': {
                cursor: 'pointer',
                padding: 0,
                fontSize: 15,
                background: theme.palette.common.white,
                borderStyle: 'none',
                textDecoration: 'underline',
            },
        },
    },
    orderOptions: {
        margin: '25px auto',
        padding: '0 16px',
        '& p': {
            color: theme.palette.contentColor,
            padding: '0 10px',
            fontSize: 14,
            marginBottom: 0,
        },
        '& a': {
            color: theme.palette.common.black,
            margin: '0 10px',
            padding: '20px 0',
            display: 'flex',
            fontSize: '16px',
            fontWeight: '700',
            borderBottom: `1px solid ${theme.palette.common.black}`,
            letterSpacing: '0.47px',
            textDecoration: 'none',
            justifyContent: 'space-between',
        },
    },
    breadCrumb: {
        marginTop: 10,
        '& a': {
            color: theme.palette.contentColor,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: 15,
            outline: 'none',
        },
    },
    arrowSign: {
        fontSize: 16,
        verticalAlign: 'middle',
    },
});

const CustomerServiceFlowersPage = ({ classes, page, brand }) => {
    const {
        title, description, orderOptions,
    } = Data;
    const seed = useUIDSeed();
    return (
        <>
            <Grid container className={classes.container}>
                <div className={classes.breadCrumb}><Link to="/customer-service"><ArrowBackIosIcon className={classes.arrowSign} />Help Center / Order Issue</Link></div>
                <Grid className={classes.title} item xs={12}>
                    <h2>{title}</h2>
                    {ReactHtmlParser(description)}
                    <p>
                        If you or your recipient are still not satisfied with the quality of the flowers, you can
                        <Link to="/customer-service-reschedule"> request a redelivery </Link>
                        or <button type="button" onClick={() => toggleWebChat()}>chat with us</button>. We are standing by and ready to help.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    { orderOptions.map((option) => (
                        <div key={seed(option)} className={classes.orderOptions}>
                            <p>{option.title}</p>
                            {option.option.map((data) => <Link key={seed(data)} to={data.link}><div>{data.title}</div> &#x3E;</Link>)}
                        </div>
                    ))}
                </Grid>
            </Grid>
            <ContactUsBannerContainer page={page} brand={brand} />
        </>
    );
};

CustomerServiceFlowersPage.propTypes = {
    classes: object.isRequired,
    brand: object.isRequired,
    page: object.isRequired,
};

export default withStyles(styles)(CustomerServiceFlowersPage);
