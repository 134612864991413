/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const toggleWebChat = () => {
    const webChat = document.querySelector('[aria-label="Click here to open Web Chat and get help."]');
    if (webChat) {
        webChat.style.visibility = 'visible';
        webChat.click();
    }
    // below code is commented out because we no longer want to open chatbot right automatically
    // if (webChat === null && typeof window !== 'undefined') {
    //     // if user navigates to non /customer-service page then uses back button, chatbot needs to be reloaded
    //     window.location.reload();
    // }
};

export default toggleWebChat;
