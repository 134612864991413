/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, shape, array, string, bool, number, func,
} from 'prop-types';
import { compose } from 'recompose';
import { trackPage } from '../../../helpers/tracking/common/commonTrackingHelpers';
import { FilterDialog } from '../GraphqlCategoryPage/Partials/FilterDialog/FilterDialog';
import GraphqlNoResultsContainer from './Partials/GraphqlNoResultsContainer';
import GraphqlSearchProducts from './Partials/GraphqlSearchProducts';

const styles = (theme) => ({
    siteContainer: {
        margin: '0 15px',
        maxWidth: '1400px',
        [theme.breakpoints.up(1430)]: {
            margin: '0 auto',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '100%',
            margin: '0 auto',
        },
    },
    header: {
        WebkitFontSmoothing: 'antialiased',
        textAlign: 'left',
        width: '100%',
    },
    numOfResultsContainer: {
        display: 'flex',
        alignItems: 'baseline',
        lineHeight: '1.2',
        borderTop: `1px solid ${theme.palette.colorNeutral20}`,
        padding: '30px 10px 20px 0',
        width: '100%',
        position: 'relative',
    },
    resultsText: {
        color: theme.palette.colorNeutral70,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '20px',
        letterSpacing: '.03em',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        padding: '0 10px',
    },
    resultsTextMain: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        '& h1': {
            margin: '5px 0',
            fontSize: 36,
        },
        [theme.breakpoints.down(600)]: {
            '& h1': {
                fontSize: 30,
            },
        },
    },
});

const productType = {
    plants: 'plants',
    canada: 'flowers',
};

class GraphqlSearchBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: {},
        };
    }

    setFilterDataInCategoryBuilder = (data) => {
        this.setState({
            filterData: data,
        });
    }

    render() {
        const {
            classes, brand, pageData, searchTerm, featureFlags, displayDeliveryMethod, presentationFamily, pageCount, setFilterOptions, fetchMoreResults,
        } = this.props;
        const { filterData } = this.state;
        const {
            products, redirectUrl, totalProducts, totalPages,
        } = pageData;
        const isFilterEnabled = featureFlags['is-sort-and-filter-collections-enabled'];
        const productTypeToSuggest = productType[brand?.domain] || 'plants';
        if (redirectUrl) {
            // If redirect is absolute URL, change it to relative
            // Does redirect match pattern:   http or https://domain/rest-of-url  ?
            const m = redirectUrl.match(/^http(s)?:\/\/[^\/]+(\/.*?)$/i);    // eslint-disable-line no-useless-escape
            const redirectPage = m ? m[2] : redirectUrl;   // if matches pattern, redirectPage = /rest-of-url
            return (
                <Route>
                    <Redirect to={redirectPage[0] === '/' ? `${redirectPage}` : `/${redirectPage}`} />
                </Route>
            );
        }
        if (products !== null && products.length) {
            return (
                <div className={classes.siteContainer}>
                    <Grid className={classes.header}>
                        <Grid className={classes.numOfResultsContainer}>
                            <span className={classes.resultsText}>{`${(totalProducts > 0) ? totalProducts : products.length} Results for "${searchTerm}"`}</span>
                            {isFilterEnabled && presentationFamily === 'food' && <FilterDialog displayDeliveryMethod={displayDeliveryMethod} setFilterOptions={setFilterOptions} brand={brand} />}
                        </Grid>
                        <Grid className={classes.siteContainer}>
                            <GraphqlSearchProducts pageNumber={pageCount} totalPages={totalPages} brand={brand} filterData={filterData} featureFlags={featureFlags} searchTerm={searchTerm} products={products} fetchMoreResults={fetchMoreResults} />
                        </Grid>
                    </Grid>
                </div>
            ); // if returned an array with products
        }

        return (
            <div className={classes.siteContainer}>
                <Grid className={classes.header}>
                    <Grid className={classes.numOfResultsContainer}>
                        <Grid container>
                            <div className={classes.resultsTextMain}>
                                <h1>{`We could not find anything for "${searchTerm}".`}</h1>
                                <span>These popular {productTypeToSuggest} below might interest you:</span>
                            </div>
                        </Grid>
                    </Grid>
                    {products && products.length && isFilterEnabled && presentationFamily === 'food'
                        ? <FilterDialog displayDeliveryMethod={displayDeliveryMethod} setFilterDataInCategoryBuilder={this.setFilterDataInCategoryBuilder} brand={brand} />
                        : null}
                    <Grid>
                        <GraphqlNoResultsContainer filterData={filterData} brand={brand} />
                    </Grid>
                </Grid>
            </div>
        ); // search return 0 products and renders the best collection
        // best collection is also missing path (string) in the image object, no images in ui
    }
}

GraphqlSearchBuilder.propTypes = {
    brand: object.isRequired,
    classes: object.isRequired,
    searchTerm: string,
    presentationFamily: string.isRequired,
    featureFlags: object.isRequired,
    pageData: shape({
        products: array.isRequired,
        redirectUrl: string,
    }).isRequired,
    displayDeliveryMethod: bool,
    pageCount: number.isRequired,
    setFilterOptions: func.isRequired,
    fetchMoreResults: func.isRequired,
};
GraphqlSearchBuilder.defaultProps = {
    searchTerm: '',
    displayDeliveryMethod: true,
};
const enhance = compose(
    withRouter,
    trackPage({
        type: 'search',
        name: 'Search',
    }),
    withStyles(styles),
);

export default enhance(GraphqlSearchBuilder);
