/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'Frequently Asked Questions',
    list: [
        {
            title: 'COVID-19 Updates',
            id: 'covid19Updates',
            description: [
                {
                    header: 'Health & Safety Measures:',
                    desData: '<p>As we navigate the current health crisis together, we continue to prioritize the safety and well-being of our team members, their families and our customers. As always, we are here to help and support you in sending smiles and expressing yourselves in these unprecedented times.</p>',
                },
                {
                    header: 'Health & Safety Measures:',
                    desData: '<ul>'
                    + '<p>'
                      + 'Across our 1-800-FLOWERS.COM, Inc. family of brands, gifts are delivered by one of our reputable carriers including FedEx, UPS and United States Postal Service, or by a local florist or shop.'
                    + '</p>'
                    + '<li>'
                     + ' <p>As a measure of social distancing and to minimize any potential risk of exposure for both our carriers and our customers, no contact is necessary for deliveries.</p>'
                   + ' </li>'
                   + ' <li>'
                    + '<p>The World Health Organization has <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses?cmp=EMC-1005333-27-1-200-0010000-US-US-EN-FY20FXOCOVID190&amp;ET_CID=765895&amp;ET_RID=67582340&amp;LINK=WHO_CORONAVIRUS">provided guidance</a> that the risk of COVID-19 infection from a package that has moved, travelled, or been exposed to different conditions and temperatures is low.</p>'
                   + '</li>'
                   + '<li>'
                      + '<p>We are working closely with our carriers to responsibly manage this evolving situation.  You can find detailed information about the steps each of our reputable carriers are taking during this time on the below sites:</p>'
                     + '<ul>'
                        + '<li><p><a href="https://www.fedex.com/en-us/coronavirus.html?cmp=EMC-1005333-27-1-200-0010000-US-US-EN-FY20FXOCOVID190&amp;ET_CID=765895&amp;ET_RID=67582340&amp;LINK=FEDEX_CORONAVIRUS">FedEx</a></p><p></p></li>'
                        + '<li><p><a href="https://www.ups.com/us/en/about/news/important-updates.page">UPS</a></p></li>'
                        + '<li><p><a href="https://about.usps.com/newsroom/statements/usps-statement-on-coronavirus.htm">USPS</a></p></li>'
                      + '</ul>'
                    + '</li>'
                    + '<li>'
                      + '<p>We have provided our network of local florists with the following delivery protocol guidelines:</p>'
                      + '<ul>'
                        + '<li>'
                          + '<p>Leaving an arrangement unattended (e.g. on the porch) will be an acceptable form of delivery, with the exception of food items.</p>'
                        + '</li>'
                        + '<li>'
                          + '<p>All employees should practice responsible sanitation and hand-washing</p>'
                        + '</li>'
                        + '<li>'
                          + '<p>Delivery vehicles should be properly ventilated throughout the day and in clean condition</p>'
                        + '</li>'
                        + '<li>'
                          + '<p>Announce arrival by ringing bell or knocking on the door, but practice touchless delivery</p>'
                        + '</li>'
                     + '</ul>'
                    + '</li>'
                    + '<li>'
                      + '<p>Deliveries to non-residential addresses, like hospitals and schools, may be impacted by health and safety restrictions due to the evolving pandemic. We are monitoring this closely and will be in touch with you if we cannot deliver your gift to this address.</p>'
                    + '</li>'
                  + '</ul>',
                },
                {
                    header: 'Food & Floral Handling:',
                    desData: '<ul>'
                    + '<li>'
                      + '<p>Please be assured that the 1-800-FLOWERS.COM, Inc. family of brands has robust hygiene, food handling and storage practices for our products that are intended to maintain their integrity and ensure their safe use. In light of COVID-19, not only are we following those robust practices, they are now receiving an even greater focus. Currently, both the FDA and CDC consider the risk of transmission through food to be very low. In fact, the FDA has stated that they are not aware of any reports of transmission of COVID-19 through food, and the CDC has said that there is no evidence of food supporting transmission of COVID-19.</p>'
                    + '</li>'
                    + '<li>'
                      + '<p>We are in frequent communication with all of our suppliers to confirm they are working in accordance with the federal, state and local food safety laws, as well as recent guidance and precautions issued by the CDC. As a company, we take food safety very seriously and continually monitor to ensure all of our facilities are practicing Good Manufacturing Practices (GMP&apos;s) as defined by the FDA which include, but are not limited to, sanitizing, hand washing/drying, wearing gloves and hair restraints.</p>'
                   + '</li>'
                    + '<li>'
                      + '<p>Our floral fulfillment locations follow Good Manufacturing Practices (GMPs) as defined by the FDA. These GMPs include, but are not limited to, social distancing, sanitizing, hand washing/drying, wearing gloves, restrictions against eating or drinking at work stations, instructions to employees that employees should not come to work if they are sick or not feeling well, or employees being sent home if they become ill while working.</p>'
                    + '</li>'
                    + '<li>'
                      + '<p>We continue to monitor the situation closely and hope that you will find this information useful and reassuring. We encourage you to continue to seek out credible sources, such as the CDC, regarding this topic. The CDC web site is located at'
                      + '<a href="https://www.cdc.gov/">www.cdc.gov</a></p>'
                    + '</li>'
                  + '</ul>',
                },
            ],
        },
        {
            title: 'Customer Service',
            id: 'customerService',
            description: [
                {
                    header: 'What is the policy for late/non-delivery?',
                    desData: '<p>Late Delivery/Non-Delivery: Our local florists and logistics team work diligently to make sure that your special gift arrives on time, within our normal delivery hours of 9AM to 8PM in the recipient&apos;s time zone. During our busy holiday periods like Christmas, Valentine&apos;s and Mother&apos;s Day, we may extend our delivery hours before 9AM and after 8PM to ensure that all gifts are delivered on time. If for any reason your gift does not arrive on time, our dedicated Customer Service agents will do everything they can to help successfully resolve your issue.'
                    + '<br>'
                    + '<br>'
                    + '<strong>Who Should I Contact for Late/Non-delivery Issues?</strong>'
                    + '<br>'
                    + '<a href="/customer-service-my-order" title="Who Should I Contact for Late/Non-delivery Issues?">Click here</a> to complete the form with your order-related question(s).</p>',
                },
                {
                    header: 'What is the policy for damaged items/refunds/redeliveries and exchanges?',
                    desData: '<p>All of our flowers & gifts are backed by our 100% Smile Guarantee, which we&apos;re proud to stand behind. If you&apos;re not satisfied with your purchase, for any reason at all, we&apos;ll do whatever it takes to make it right. This includes offering a refund, redelivery or comparable exchange on your order. It’s all part of our promise to provide you with an excellent customer experience every single time.</p>',
                },
                {
                    header: 'Who Should I Contact for Damaged Items or to Make a Refund, Redelivery or Exchange?',
                    desData: '<p><a href="/customer-service-flowers" title="Who Should I Contact for Damaged Items or to Make a Refund, Redelivery or Exchange?">Click here</a>  to complete the form with your order-related question(s).</p>',
                },
            ],
        },
        {
            title: 'Billing',
            id: 'billing',
            description: [
                {
                    header: 'How do I use a Promotion Code?',
                    desData: '<p>To use a promotion code, make sure you have the code ready as you proceed through checkout. On the Billing Page, enter the code carefully into the box that says <strong>Promotion Code</strong>.'
                    + '<br><br>'
                    + 'If you&apos;re redeeming an offer that requires you to enter both a promotion code and a membership number, enter the promotion code in the box that says <strong>Promotion Code</strong> and your membership number in the box that says <strong>Program ID</strong>.'
                    + '<br><br>'
                    + '<strong>Please note:</strong> Certain items are not eligible for promotion codes. These will be marked clearly with the message: <strong>"Promotion Codes are not valid for use on this product"</strong>.</p>',
                },
                {
                    header: 'When will my credit card be charged?',
                    desData: '<p>We&apos;ll attempt to secure authorization on your credit card at the point of purchase online. If there&apos;s a problem securing this authorization, you&apos;ll be notified on the spot and prompted to use another card. Once we receive verification of sufficient funds, your order will be completed and transferred securely to us. Your account will be charged in 24 to 48 hours.</p>',
                },
                {
                    header: 'Which credit cards or payment types are accepted?',
                    desData: '<p><strong>We accept the following credit card payment options:</strong>'
                + 'American Express<sup>®</sup>, Discover<sup>®</sup>, MasterCard<sup>®</sup>, Visa<sup>®</sup>'
                + '<br><br>'
               + '<strong>We also accept the following express checkout payment methods:</strong>'
                + 'AMEX Express Checkout, Chase Pay, Masterpass PayPay, Visa<sup>®</sup> Checkout | Apple Pay and Android Pay are accepted on our mobile app only</p>',
                },
                {
                    header: 'Will I be charged sales tax?',
                    desData: '<p>Local and State sales tax will be collected if your recipient&apos;s mailing address is in Arizona, California, Colorado, Florida, Illinois, Massachusetts, Nevada, New Jersey, New Mexico, New York, Ohio, Oklahoma, Tennessee, Texas or Virginia.</P>',
                },
                {
                    header: 'Is it safe to use my credit card or debit card online?',
                    desData: 'Yes! The safety of your personal information is extremely important to us. We encrypt your information using SSL (secure socket layer) technology to protect your personal data and credit card information from unauthorized access. When your browser is in secure mode, you&apos;ll notice a key or lock icon at the bottom of your browser window. Please note: If you pay by debit card, the amount you charge will be put on "hold", meaning it will not be available to you after you place your order. Once your transaction is processed, the amount of the transaction will be withdrawn from your account and the original "hold" will be voided. You can review all of this important information in our Security & Privacy Policies',
                },
            ],
        },
        {
            title: 'Order Status',
            id: 'orderStatus',
            description: [
                {
                    header: 'What happens after I place an order?',
                    desData: '<p>Once your order is placed on the site, you&apos;ll see a <strong>Thank You Page</strong>, which will include your order confirmation number on it. Please make sure to hold onto this number in case you have any questions/issues.'
                + '<br><br>'
                + 'Your order is then sent over a secure connection to 1-800-Flowers.com. You&apos;ll receive an email confirmation once your order has been received.</p>',
                },
                {
                    header: 'How do I check the status of my order?',
                    desData: '<div>'
                    + '<p>Checking the status of your order is quick and easy!'
                    + '<br>'
                    + '</p>'
                    + '<ol class="nested-ordered-list">'
                      + '<li>View the status of a single order by entering your order number on the <a href="/webapp/wcs/stores/servlet/OrderTrackingSearch?userAction=OrderTrackingByEmail&amp;catalogId=13302&amp;langId=-1&amp;storeId=20051" title="Order Tracking Page">Order Tracking Page</a>.</li>'
                      + '<li>Sign in to your account to review the complete order history of all your 1800-Flowers.com orders. Don&apos;t have an account? <a href="/webapp/wcs/stores/servlet/AjaxUserRegistrationForm?langId=-1&amp;storeId=20051&amp;catalogId=13302&amp;new=Y" title="Register Now">Register now</a>.</li>'
                      + '<li>If you provided an email address, we&apos;ll send you a confirmation as soon as we receive it.</li>'
                    + '</ol>'
                    + '<br>'
                    + 'If you still have questions, let’s chat. You can <a href="/customer-service">contact us and report an issue here</a>.'
                    + 'Please remember that our normal delivery hours are between 9AM and 8PM in your recipient’s time zone.'
                  + '</div>',
                },
                {
                    header: 'Order and Delivery E-Mail Confirmations',
                    desData: '<div>'
                    + '<p>After your order is submitted, you&apos;ll receive a series of e-mails with the status of your order. Please be sure to enter a valid email address when you provide your billing information to ensure that you receive them.'
                    + '<br><br>'
                    + '</p>'
                    + '<ol class="nested-ordered-list">'
                      + '<li><strong>Order Confirmation Email:</strong> This email will let you know that your order has been received. If you don&apos;t receive this email, please reach out to us right away at <strong>1-800-227-5387</strong> to ensure that your order is being processed. You can also try checking your spam folder.</li>'
                      + '<li><strong>Shipping Confirmation Email:</strong> This is to let you know that your gift has been shipped, and applies to any order arriving via FedEx or USPS.</li>'
                      + '<li><strong>Delivery Confirmation Email:</strong> This is the big one! It lets you know that your special gift has successfully been delivered. You should receive this email by <strong>8PM</strong> in your recipient&apos;s time zone. Please note: During our busy holiday periods like Christmas, Valentine&apos;s and Mother&apos;s Day, we may extend our delivery hours <strong>before 9AM and after 8PM</strong> to make sure that all gifts are delivered on time. If for any reason your gift does not arrive on time, our dedicated Customer Service agents will do everything they can to help successfully resolve your issue.</li>'
                    + '</ol>'
                  + '</div>',
                },
                {
                    header: 'How do I change or cancel my order?',
                    desData: '<p>If you need to change or cancel your order, please reach out to us immediately at <strong>1-800-468-1141</strong>.'
                    + '<br><br>'
                    + '<strong>Please note:</strong> some orders are shipped very soon after being placed and may already be on their way or delivered, so it may not always be possible to cancel.'
                    + '<br><br>'
                    + 'If your order is scheduled for a future delivery date, we&apos;ll do everything possible to fulfill your request. Just <a href="/email-us" title="E-mail Us">email us</a> or give us a call at <strong>1-800-468-1141</strong>.</p>',
                },
            ],
        },
        {
            title: 'Shipping & Delivery',
            id: 'shippingAndDelivery',
            description: [
                {
                    header: 'What are the current delivery methods?',
                    desData: '<p>1-800-Flowers.com has two ways of delivering your gift: they are either hand delivered by a local florist or shop or delivered by one of our reputable carriers including: FedEx, UPS and United States Postal Service.</p>',
                },
                {
                    header: 'Do you offer delivery outside the continental United States?',
                    desData: '<p>Yes! We are proud to offer international delivery to 195 counties worldwide by partnering with local florists across the globe to hand deliver your gift. Direct Ship items are only available for delivery in the continental United States.'
                    + '<br><br>'
                    + '<strong>Please note:</strong> we do not ship to international locations other than U.S. Territories and Military APO/FPO addresses.</p>',
                },
                {
                    header: 'Do you deliver to military installations?',
                    desData: '<p>Due to the perishable nature of our flowers and the security policies of many bases, we&apos;re unfortunately unable to deliver to military installations.'
                    + '<br><br>'
                    + 'If you&apos;d like to send a gift to someone in the military, we recommend visiting <a href="/cheryls-home" title="Cheryl&apos;s">Cheryl&apos;s</a> and <a href="/thepopcornfactory-home" title="The Popcorn Factory">The Popcorn Factory</a>, both part of the 1-800-Flowers.com Family of Brands. These gourmet food brands can deliver delicious treats to APO/FPO military bases around the world, with exclusive collections specifically for military personnel.</p>',
                },
                {
                    header: 'What are the current delivery restrictions?',
                    desData: '<ul class="nested-unordered-list">'
                    + '<li>Items shipped by a carrier such as FedEx, UPS or the US Postal Service cannot be delivered to funeral homes, hospitals or rural routes.</li>'
                    + '<li>Due to state regulations, we are unable to ship wine to/within certain states.</li>'
                  + '</ul>',
                },
                {
                    header: 'How are shipping and delivery charges determined?',
                    desData: '<p>Please see existing shipping chart for <a class="fptLink" title="Florist Designed" href="/fpt-delivery-details" target="_blank">Florist Designed</a> and <a class="gptLink" title="Direct Ship" href="/gpt-delivery-details" target="_blank">Direct Ship</a> products here.</p>',
                },
                {
                    header: 'Will I have to sign for my package?',
                    desData: '<p>A signature is typically not required when your package is shipped. However, there are instances when the delivery driver may decide to choose this option, which is at the discretion of the shipper. In addition, any order including wine/alcohol will require a signature upon delivery.</p>',
                },
                {
                    header: 'How will my gift be packaged?',
                    desData: '<div>'
                    + '<p>Depending on which delivery method you choose, your gift will be packaged as follows:</p>'
                    + '<ol class="nested-ordered-list">'
                    + '<li>Local Florist Delivery</li>'
                    + 'Your order will be sent to a local florist shop, where it will be expertly designed and hand delivered to your recipient.'
                    + '<br>'
                    + 'Reasons to choose florist delivery:'
                    + '<ul>'
                      + '<li>'
                        + '<strong>Same-Day Delivery</strong>'
                        + '<br>'
                        + 'Just place your order before 2 pm M-F, 1 pm Saturday and 11:30 am Sunday in your recipient’s time zone, and they’ll get them the same day! Please note: Same-Day Delivery may not be available during peak holiday times or in adverse weather conditions.'
                      + '</li>'
                      + '<li>'
                        + '<strong>Top Quality Florists</strong>'
                        + '<br>'
                       + 'We work with our BloomNet<sup>®</sup> Network of florists, which carefully screens its florists for artistic quality and level of service. BloomNet<sup>®</sup> maintains stringent standards and quality control with its member florists. As with all flower orders from us, your flowers are guaranteed to remain fresh for at least 7 days.'
                      + '</li>'
                    + '</ul>'
                    + '<li>Direct Ship</li>'
                    + 'The gift you select will be delivered by UPS, FedEx or USPS. Flowers will arrive carefully packaged in our signature shipping box, which is specially designed to ensure optimal freshness.'
                    + '<br>'
                    + 'Reasons to choose flowers fresh from our farms:'
                    + '<br>'
                    + '<ul>'
                      + '<li>'
                        + '<strong>A Wider Selection</strong>'
                        + '<br>'
                        + 'From rare flower varieties to unique pairings of the freshest blooms, many of our bouquets are available exclusively through direct shipment.'
                        + '<br>'
                      + '</li>'
                      + '<li>'
                        + '<strong>Fresh From Our Growers<sup>®</sup> Bouquets</strong>'
                        + '<br>'
                        + 'Our direct shipment flowers are picked from select floral fields around the world and shipped to our distribution centers. At that point, they’re cleaned and quality tested before being shipped to your recipient.'
                      + '</li>'
                    + '</ul>'
                  + '</ol>'
                + '</div>',
                },
            ],
        },
        {
            title: 'Celebrations Passport',
            id: 'celebrationsPassport',
            description: [
                {
                    header: 'Which shipments, deliveries and products qualify for Free Shipping/No Service Charge under the Celebrations Passport® membership program?',
                    desData: '<p>'
                + '<strong>Shipments &amp; Deliveries:</strong>'
                + '<br>'
                + 'Celebrations Passport<sup>®</sup> membership provides you with <strong>Free Shipping/No Service Charge</strong> for qualifying purchases ordered for delivery two business days or more from the date of your order. Same-Day, Next-Day and Weekend Delivery are also available, however, an additional expedited delivery surcharge will apply. For more details, please see our <a href="/passport-terms-and-conditions" title="Passport Terms and Conditions">Terms and Conditions</a>.'
                + '<br><br>'
                + '<strong>Qualifying Products:</strong>'
                + '<br>'
                + 'Most products across our Family of Brands, with the exception of Stock Yards, qualify for the Free Shipping/No Service Charge benefit. When you choose your delivery date for each order, the applicable shipping charges, service fees or surcharges, and amount will be shown. There is no minimum purchase amount required for your order(s).'
              + '</p>',
                },
                {
                    header: 'As a Celebrations Passport member, what are expedited delivery surcharges?',
                    desData: '<p>For Same-Day Delivery on qualifying products, an expedited delivery surcharge of $5.99 is added to your order. For Next-Day Delivery, an expedited delivery surcharge of $3.99 per order is applied. Saturday Delivery carries a $5 surcharge for Local Florist Delivery and a $12.99 surcharge for all Direct Shipped products. For more details, please see our <a href="/passport-terms-and-conditions" title="Passport Terms and Conditions">Terms and Conditions.</a></p>',
                },
                {
                    header: 'How can I make sure that I am recognized as a Celebrations Passport member when I place my order?',
                    desData: '<p>To be recognized as a Celebrations Passport member online, you&apos;ll need to sign in prior to completing your order. You may also use the program for your 1-800-Flowers.com orders placed over the phone, however, you&apos;ll be asked to provide proof of membership when you go to place your order. This will typically be the email address and credit card you used to enroll. Please note: program benefits are currently not available for phone orders placed with our other brands.</p>',
                },
                {
                    header: 'How do I check my Celebrations Rewards pass balance?',
                    desData: '<p>To check your current balance, please call 1-800-242-5353. Please note that your Activity Page reflects the original balance for any Rewards passes issued in the last 90 days. For any further assistance, please call our Celebrations Rewards specialists at 1-800-993-1192.</p>',
                },
                {
                    header: 'Why wasn&apos;t my last order reflected in my points balance?',
                    desData: '<p>It may take up to 48 hours for your updated points balance and current membership level to be reflected in your account. If you&apos;re still seeing the incorrect balance after that time, please reach out to our dedicated Celebrations Rewards<sup>®</sup> service specialists at 1-800-993-1192. They&apos;re available M-F, 8am to 8pm EST to help.</p>',
                },
                {
                    header: 'Why haven&apos;t I received my Celebrations Rewards pass?',
                    desData: '<p>If you don&apos;t see your pass, make sure to check the bulk or junk mail folder in your email account to see if if was sent their by accident. You can also log on to your Account at any of our participating brands and access your pass on the Celebrations Rewards<sup>®</sup> page under <strong>My Account</strong>.'
                    + '<br><br>'
                    + 'Remember that we send out your pass in the beginning of the month following the month in which you qualify for your rewards.</p>',
                },
                {
                    header: 'Why doesn&apos;t my Celebrations Rewards pass work?',
                    desData: '<p>Your Rewards pass will be valid for 90 days after you&apos;ve received it via email. Once a pass has expired, it can no longer be used.</p>',
                },
                {
                    header: 'When does my Celebrations Passport membership expire?',
                    desData: '<p>To find your membership expiration date, click on <strong>My Account</strong> in the upper right corner and sign in. Next, click on Passport under the My Account menu. This will bring you to your Passport Profile page, where you&apos;ll see both your membership end date and renewal date.</p>',
                },
                {
                    header: 'How do I renew my Celebrations Passport membership?',
                    desData: '<div>'
                    + '<p>Your membership is automatically renewed and billed to your credit card on file for successive one-year periods, at the then-current rates and surcharges for the program. Prior to the automatic renewal and billing, we&apos;ll notify you of the following in advance, via the email you used at the time of enrollment:</p>'
                    + '<ul>'
                      + '<li>Your pending renewal</li>'
                      + '<li>Your then-current fees and charges applicable to the program</li>'
                      + '<li>Where to find more information about the program</li>'
                    + '</ul>'
                    + '<br>'
                   + ' We&apos;ll also send you an additional email confirming your renewal.'
                  + '</div>',
                },
                {
                    header: 'How do I cancel my Celebrations Passport membership?',
                    desData: '<p>If you elect not to renew your membership, you may cancel at any time prior to renewal by calling us at 1-800-961-2036. Your credit card on file with us will not be charged.</p>',
                },
            ],
        },
        {
            title: 'Allergy Warning',
            id: 'allergyWarning',
            description: [
                {
                    desData: 'Allergy Warning: Facilities and equipment used to produce Fruit Bouquets/Shari’s Berries products may include allergens other than those identified in the product description on the specific product pages. We urge customers with known food allergies to exercise caution when ordering our products. Individuals with serious food allergies should refrain from ordering our Fruit Bouquets/Shari’s Berries products',
                },
            ],
        },
    ],
};

export default data;
