/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */
const data = {
    page_Content: {
        page_title: 'Celebrations Rewards Program FAQs',
        content: [
            {
                question: 'I thought 1-800-Flowers.com had a rewards program, how do I get more information to join?',
                answer: 'We no longer have a rewards program that gives you points for purchases, but instead offer a program that allows you to get Free Shipping / No Service Charge across our family of brands for a small fee. Membership pays for itself in little as two orders and members have access to exclusive offers throughout the year. <a href="/passport-flowers">Click here</a> to learn more.',
            },
            {
                question: 'What happens to my existing points?',
                answer: 'You are not losing any of your existing points, in fact we’re issuing you bonus points so you can get one final reward in the program. You should have received your final savings pass via email and it can be accessed on the account page of our website.',
            },
            {
                question: 'What happens to my existing Rewards passes?',
                answer: 'All existing rewards passes will be honored and will stay valid until the expiration date of the pass. You can find your rewards passes on the account page of our website.',
            },
            {
                question: 'Where can I find my point balance for a recent purchase?',
                answer: 'The Celebrations Rewards program was closed as of July 22 and purchases made after that day do not earn any points.',
            },
            {
                question: 'I made a purchase prior to July 22 and I didn’t receive my points, what can I do?',
                answer: 'Please reach out to our Loyalty Priority Hotline at 1-800-993-1192 and they can offer you assistance.',
            },
            {
                question: 'I enjoyed the Celebrations Rewards program and that I could earn points across a number of brands, will you be starting a new program?',
                answer: 'As a company, we continually work to ensure we are providing the very best ways to thank our most valued customers. With this mind, we offer a program that allows you to get Free Shipping / No Service Charges across for a full year across our portfolio of all-star brands. Membership pays for itself in little as two orders and members have access to exclusive offers throughout the year. <a href="/passport-flowers">Click here</a> to learn more.',
            },
        ],
    },
};

export default data;
