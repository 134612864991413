/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'My Order Is Not As Expected',
    description: '<p>Our aim to help you connect, express and celebrate with the ones you love. We hope that your gift delivers a smile to the recipient but understand that sometimes things go awry.</p>'
    + '<p> Our local florists always put their passion and creativity into each gift. At times, they may need to make design substitutions to ensure your order is fresh and delivered in a timely manner. This is especially true as we navigate the evolving health crisis. Please know that in this instance, the utmost care and attention is given so your gift is of equal value.</p>'
    + '<p>In order to ensure freshness, your flowers may arrive in bud form. Rest assured, with a little time and TLC, they will be in full bloom in a couple of days. For the best results, stems should be re-cut and placed in clean water along with the food provided.</p>'
    + '<p>If you or your recipient are still not satisfied with the quality of the flowers, please choose from the below and we will be in touch as soon as we can.</p>',
    orderOptions: [
        {
            title: 'Related questions and videos',
            option: [
                {
                    title: 'What to expect when flowers arrive',
                    link: '/customer-service-help-videos',
                },
            ],

        },
    ],
};
export default data;
