/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object,
} from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useUIDSeed } from 'react-uid';
import PasswordBlock from './PasswordBlock';
import Data from './ViewData';

const styles = (theme)  => ({
    container: {
        width: '100%',
        border: `1px solid ${theme.palette.crBgColor}`,
        margin: 'auto',
        maxWidth: '980px',
        borderRadius: '5px',
        marginBottom: '50px',
        marginTop: '25px',
        '& h1': {
            textAlign: 'center',
            fontSize: '24px',
            color: theme.palette.colorPrimary,
            fontFamily: 'Roboto',
        },
    },
    innerContainer: {
        width: '90%',
        margin: 'auto',
        paddingBottom: '30px',
    },
    question: {
        fontSize: '16px',
        color: theme.palette.crQusColor,
        margin: '5px 0px',
    },
    answer: {
        margin: '5px 0px 15px',
        fontSize: '14px',
    },
    divider: {
        textAlign: 'center',
        borderTop: `1px solid ${theme.palette.crDividerColor}`,
        width: '80%',
        margin: '0 auto',
        paddingTop: '70px',
    },
    bottom: {
        padding: '50px',
        background: theme.palette.crBgColor,
        borderBottom: `1px solid ${theme.palette.crBgColor}`,
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
    },
});

const CelebrationsRewardsPage = ({ classes }) => {
    const { page_Content: { page_title, content } } = Data;
    const seed = useUIDSeed();
    return (
        <>
            <div className={classes.container}>
                <h1>{page_title}</h1>
                <div className={classes.innerContainer}>
                    {content.map((data)  => (
                        <div key={seed(data)}>
                            <h2 className={classes.question}>{data.question}</h2>
                            <p className={classes.answer}>{ReactHtmlParser(data.answer)}</p>
                        </div>
                    ))}
                </div>
                <div className={classes.divider} />
                <PasswordBlock />
                <div className={classes.bottom} />
            </div>
        </>
    );
};

CelebrationsRewardsPage.propTypes = {
    classes: object.isRequired,
};
export default withStyles(styles)(CelebrationsRewardsPage);
