/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import {
    object,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Data from './ViewData';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
    title: {
        textAlign: 'center',
        '& h2': {
            fontSize: 28,
        },
        '& p': {
            color: theme.palette.contentColor,
            fontSize: 17,
            margin: 0,
            '& a': {
                color: theme.palette.contentColor,
            },
        },
    },
    orderOptions: {
        margin: '50px auto',
        padding: '0 16px',
        '& p': {
            color: theme.palette.contentColor,
            padding: '0 16px',
            fontSize: 14,
        },
        '& a': {
            color: theme.palette.common?.black,
            margin: '0 10px',
            padding: '20px 0',
            display: 'flex',
            fontSize: '16px',
            fontWeight: '700',
            borderBottom: `1px solid ${theme.palette.common?.black}`,
            letterSpacing: '0.47px',
            textDecoration: 'none',
            justifyContent: 'space-between',
        },
    },
    breadCrumb: {
        marginTop: 10,
        '& a': {
            color: ' #404040',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: 15,
            outline: 'none',
        },
    },
    arrowSign: {
        fontSize: 16,
        verticalAlign: 'middle',
    },
});

const CustomerServiceOrderPage = ({ classes }) => {
    const {
        title = '', description = '', link = '', orderOptions = [],
    } = Data;
    const seed = useUIDSeed();
    return (
        <Grid container className={classes.container}>
            <div className={classes.breadCrumb}><Link to="/customer-service"><ArrowBackIosIcon className={classes.arrowSign} />Help Center</Link></div>
            <Grid className={classes.title} item xs={12}>
                <h2>{title}</h2>
                <p>{description} <Link to={link}> Customer Service Hub</Link></p>
            </Grid>
            <Grid item xs={12}>
                {orderOptions.length > 0 && orderOptions.map((option) => (
                    <div key={seed(option)} className={classes.orderOptions}>
                        <p>{option?.title}</p>
                        {option?.option.length > 0 &&  option.option.map((data) => <Link key={seed(data)} to={data?.link}><div>{data?.title}</div> &#x3E;</Link>)}
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

CustomerServiceOrderPage.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(CustomerServiceOrderPage);
