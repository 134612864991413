/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React,  { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object,
} from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import ReadMoreDialog from './ReadMoreDialog';
import Data from './ViewData';
import OrchidsDeliveryProducts from './OrchidsDeliveryProducts';

const styles = (theme)  => ({
    container: {
        width: '100%',
        padding: 15,
    },
    imocMain: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        padding: '30px 20px',
        boxSizing: 'border-box',
        overflow: 'auto',
        alignItems: 'center',
    },
    imocHeader: {
        borderRight: `1px solid ${theme.palette.grey5}`,
        margin: '0px 30px 0 0',
        color: theme.palette.colorPrimary,
        fontSize: 24,
        paddingRight: 30,
        fontWeight: 700,
    },
    imocDescription: {
        fontSize: 16,
    },
    topImageBanner: {
        position: 'relative',
        textAlign: 'center',

    },
    topImageBannerContent: {
        textAlign: 'center',
        position: 'absolute',
        top: '45%',
        left: '73%',
        transform: 'translate(-50%, -50%)',
        width: '50%',

        '& p': {
            fontSize: 24,
        },
        '& span': {
            fontSize: 16,
            fontWeight: 100,
            cursor: 'pointer',
        },
    },

    orchidCareMain: {
        position: 'relative',
    },
    orchidCareInner: {
        top: '50%',
        left: 0,
        width: '100%',
        position: 'absolute',
        transform: 'translate(0, -50%)',
        '& p': {
            fontSize: 22,
            margin: 0,
            textAlign: 'center',
        },
        '& div': {
            fontSize: 20,
            textAlign: 'center',
            margin: '10px 0',
            '& a': {
                textDecoration: 'none',
                color: theme.palette.common.black,
                fontWeight: 700,
            },
        },
    },
    madeMeSmile: {
        textAlign: 'right',
        color: theme.palette.grey5,
        padding: 10,
        '& p': {
            margin: 0,
        },
    },
    madeMeSmileMain: {
        borderBottom: '1px solid',
        marginBottom: '30px',
        paddingBottom: '30px',
    },
    madeMeSmileTitle: {
        margin: '0px 0 20px',
        fontSize: '30px',
        fontWeight: '700',
        '& span': {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            '&::before': {
                content: "''",
                backgroundColor: theme.palette.common.black,
                height: '1px',
                margin: '0 20px 0 0',
                flex: '1 0 20px',
            },
            '&::after': {
                content: "''",
                backgroundColor: theme.palette.common.black,
                height: '1px',
                margin: '0 0 0 20px',
                flex: '1 0 20px',
            },
        },
    },
    productList: {
        padding: '0 15px',
    },
});
const OrchidsDeliveryPage = ({ classes }) => {
    const {
        banner_title, banner_description,
        top_image_banner: {
            image_url, content, top_banner_alt_text, popup_data,
        },
        section_one, section_two, section_three,
        made_me_smile: {
            made_me_smile_content, made_me_smile_title,
        },
        orchid_care_banner: {
            orchid_care_image, orchid_care_content, orchid_care_link, orchid_care_link_text, orchid_care_alt_text,
        },
    } = Data;
    const [popDataRequest, openClick] = useState({
        trigger: false,
        data: {},
    });
    const { trigger, data } = popDataRequest;
    const seed = useUIDSeed();
    return (
        <>
            <ReadMoreDialog show={trigger} openClick={openClick} data={data} />
            <div className={classes.container}>
                <div className={classes.imocMain}>
                    <span className={classes.imocHeader}>
                        {banner_title}
                    </span>
                    <span className={classes.imocDescription}>
                        {banner_description}
                    </span>
                </div>
                <div className={classes.topImageBanner}>
                    <img src={`${image_url}?height=242&width=1440`} alt={top_banner_alt_text} />
                    <div className={classes.topImageBannerContent}>
                        {ReactHtmlParser(content)}
                        <span
                            role="presentation"
                            onClick={() => openClick({
                                trigger: true,
                                data: popup_data,
                            })}
                        >Read More &#43;
                        </span>
                    </div>
                </div>
                <Grid container className={classes.productList}>
                    <Grid xs={3} item />
                    <Grid xs={9} item>
                        {/* Product Row */}
                        <OrchidsDeliveryProducts productList={section_one} />
                        {/* Made Me Smile  */}
                        <Grid className={classes.madeMeSmileMain} container>
                            <Grid className={classes.madeMeSmileTitle} item xs={12}>
                                <span>{made_me_smile_title}</span>
                            </Grid>
                            {
                                made_me_smile_content.map((madeMeData) => {
                                    const { image, username } = madeMeData;
                                    return (
                                        <Grid key={seed(madeMeData)} className={classes.madeMeSmile} item xs={3}>
                                            <img src={image} alt={username} />
                                            <p>{username}</p>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        {/* Product Row */}
                        <OrchidsDeliveryProducts productList={section_two} />
                        {/* Orchid Care */}
                        <Grid container>
                            <Grid item xs={12} className={classes.orchidCareMain}>
                                <img src={orchid_care_image} alt={orchid_care_alt_text} />
                                <div className={classes.orchidCareInner}>
                                    {ReactHtmlParser(orchid_care_content)}
                                    <div><Link to={orchid_care_link}>{orchid_care_link_text}</Link></div>
                                </div>
                            </Grid>
                        </Grid>
                        {/* Product Row */}
                        <OrchidsDeliveryProducts productList={section_three} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

OrchidsDeliveryPage.propTypes = {
    classes: object.isRequired,
};
export default withStyles(styles)(OrchidsDeliveryPage);
