/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import {
    object, string, arrayOf, shape, bool, number, array, func,
} from 'prop-types';

// import { actions as tagManagerActions } from '../../../../../../../../state/ducks/TagManager';
import ConnectedDesktopSimpleProduct from './DesktopSimpleProduct/DesktopSimpleProduct';
// import DesktopMobile from '../../../../../../../helpers/DesktopMobile/DesktopMobile'; TODO: remove if test works

export const GraphqlSimpleProduct = ({
    categoryData,
    filterData,
    path,
    breadCrumbArray,
    skuPriceRange,
    availability,
    image,
    name,
    partNumber,
    fromCategoryPage,
    prices,
    brand,
    url,

    // Category
    categoryId,
    categoryName,
    categoryLegacyId,

    skipAddons,
    showMovieUpsell,
    // tracking
    track,
    trackData,
    trackEvent,
    isPassportEligible,
    presentation_family,
    isSmallScreen,
}) => (
    // TODO: rename and remove additional component files throughtout if test works
    <ConnectedDesktopSimpleProduct
        name={name}
        partNumber={partNumber}
        url={url}
        prices={prices}
        brand={brand}
        image={image}
        path={path}
        filterData={filterData}
        categoryData={categoryData}
        fromCategoryPage={fromCategoryPage}
        breadCrumbArray={breadCrumbArray}
        skuPriceRange={skuPriceRange}
        availability={availability}
        categoryId={categoryId}
        categoryName={categoryName}
        categoryLegacyId={categoryLegacyId}
        skipAddons={skipAddons}
        showMovieUpsell={showMovieUpsell}
        trackEvent={trackEvent}
        track={track}
        trackData={trackData}
        isPassportEligible={isPassportEligible}
        presentationFamily={presentation_family}
        isSmallScreen={isSmallScreen}
    />
);

GraphqlSimpleProduct.propTypes = {
    categoryData: object,
    filterData: object,
    prices: array,
    brand: object.isRequired,
    path: string,
    name: string.isRequired,
    partNumber: string,
    url: string.isRequired,
    categoryId: string,
    categoryName: string,
    categoryLegacyId: string,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ),
    skuPriceRange: shape({
        sale: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
        retail: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
    }).isRequired,
    availability: shape({
        deliveryMessage: string.isRequired,
    }).isRequired,
    fromCategoryPage: bool,
    image: shape({
        name: string.isRequired,
    }).isRequired,
    skipAddons: bool,
    showMovieUpsell: bool,
    track: bool,
    trackEvent: func,
    isPassportEligible: bool,
    presentation_family: string,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
    }),
    isSmallScreen: bool.isRequired,
};

GraphqlSimpleProduct.defaultProps = {
    categoryData: null,
    filterData: null,
    prices: null,
    path: '',
    categoryId: '',
    categoryName: '',
    categoryLegacyId: '',
    isPassportEligible: null,
    presentation_family: '',
    partNumber: '',
    fromCategoryPage: false,
    skipAddons: false,
    showMovieUpsell: false,
    track: false,
    trackEvent: () => { },
    breadCrumbArray: [],
    trackData: {},
};

export default GraphqlSimpleProduct;
