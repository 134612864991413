/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, bool, string } from 'prop-types';
import { compose } from 'recompose';
import loadable from '@loadable/component';

import mbpUtil from 'mbp-api-util';
import { trackPage } from '../../../helpers/tracking/common/commonTrackingHelpers';
import { isContentPageRoute } from '../../../../routes/contentPageRoutes';
import RedirectWithStatus from '../GraphqlCommonComponents/RedirectWithStatus/RedirectWithStatus';
import { determineLanguagePrefixPath } from '../../../helpers/determineInternationalizationPath';
import ErrorBoundary from '../../../helpers/ErrorBoundary';

const LoadableHomePage = loadable(() => import(/* webpackChunkName: "GraphqlHomePage" */ '../GraphqlHomePage/GraphqlHomePage'));
const LoadableGraphqlMasterTemplatePage = loadable(() => import(/* webpackChunkName: "GraphqlMasterTemplatePage" */ '../MasterTemplatePage/GraphqlMasterTemplatePage'));
const LoadableGraphqlDepartmentPage = loadable(() => import(/* webpackChunkName: "GraphqlDepartmentPage" */ '../GraphqlDepartmentPage/GraphqlDepartmentPage'));
const LoadableGraphqlRecipePage = loadable(() => import(/* webpackChunkName: "GraphqlRecipePage" */ '../GraphqlRecipePage/GraphqlRecipePage'));
const LoadableGraphqlClubPage = loadable(() => import(/* webpackChunkName: "GraphqlClubPage" */ '../GraphqlClubPage/GraphqlClubPage'));
const LoadableHousePlantPage = loadable(() => import(/* webpackChunkName: "HousePlantPage" */ '../HousePlantPage/HousePlantPage'));
const LoadableConnectPage = loadable(() => import(/* webpackChunkName: "ConnectPage" */ '../ConnectPage/ConnectPage'));
const LoadableGraphqlRecipeCategoryLanderPage = loadable(() => import(/* webpackChunkName: "GraphqlRecipeCategoryLanderPage" */ '../GraphqlRecipePage/GraphqlRecipeCategoryLanderPage'));
const LoadableContentPageBuilder = loadable(() => import(/* webpackChunkName: "ContentPageBuilder" */ '../ContentPageBuilder/ContentPageBuilder'));
const LoadableGraphqlCategoryPageContainer = loadable(() => import(/* webpackChunkName: "GraphqlCategoryPageContainer" */ '../GraphqlCategoryPage/GraphqlCategoryPageContainer'));
const LoadableGraphqlTemplatePageContainer = loadable(() => import(/* webpackChunkName: "GraphqlTemplatePageContainer" */ '../GraphqlTemplatePage/GraphqlTemplatePageContainer'));
const LoadableSeoArticlesPage = loadable(() => import(/* webpackChunkName: "SeoArticlesPage" */ '../SeoArticlesPage/SeoArticlesPage'));
const LoadablePassportPageContainer = loadable(() => import(/* webpackChunkName: "PassportPageContainer" */ '../PassportPage/PassportPage'));
const LoadableProductNotAvailableContainer = loadable(() => import(/* webpackChunkName: "ProductNotAvailableContainer" */ '../GraphqlProductPage/Partials/ProductNotAvailable/ProductNotAvailableContainer'));
const LoadableGraphqlProductPageContainer = loadable(() => import(/* webpackChunkName: "GraphqlProductPageContainer" */ '../GraphqlProductPage/GraphqlProductPageContainer'));
const LoadableGraphqlContentPage = loadable(() => import(/* webpackChunkName: "GraphqlContentPage" */ '../GraphqlContentPage/GraphqlContentPage'));
const LoadableAddOnsContainer = loadable(() => import(/* webpackChunkName: "GraphqlContentPage" */ '../GraphqlAddOnsPage/GraphqlAddOnsContainer'));
const LoadableWrapUpContentContainer = loadable(() => import(/* webpackChunkName: "WrapUpContentContainer" */ '../WrapUpPage/WrapUpContentContainer'));
const LoadableConnectionCommunitiesPage = loadable(() => import(/* webpackChunkName: "ConnectionCommunitiesPage" */ '../ConnectionCommunitiesPage/ConnectionCommunitiesPage'));
const LoadableEcardsPage = loadable(() => import(/* webpackChunkName: "EcardsPage" */ '../EcardsPage/EcardsPage'));
const LoadableGraphqlWyngStaticPage = loadable(() => import(/* webpackChunkName: "GraphqlWyngStaticPage" */ '../GraphqlWyngStaticPage/GraphqlWyngStaticPage'));
const LoadableGraphqlRadioPage = loadable(() => import(/* webpackChunkName: "GraphqlRadioPage" */ '../GraphqlRadioPage/GraphqlRadioPage'));
const LoadableRadioOfferResultsPage = loadable(() => import(/* webpackChunkName: "RadioOfferResultsPage" */ '../GraphqlRadioPage/RadioOfferResults/RadioOfferResultsPage'));
const LoadableSympathyPagesBuilder = loadable(() => import(/* webpackChunkName: "SympathyPagesBuilder" */ '../SympathyComponents/SympathyPagesBuilder'));
const LoadableRecipeEntry = loadable(() => import(/* webpackChunkName: "RecipeEntry" */ '../GraphqlRecipeEntry/GraphqlRecipeEntry'));
const LoadableHtmlSitemapContainer = loadable(() => import(/* webpackChunkName: "HtmlSitemapContainer" */ '../HtmlSitemap/HtmlSitemapContainer'));
const LoadableGraphql404ErrorContainer = loadable(() => import(/* webpackChunkName: "Graphql404ErrorContainer" */ '../Graphql404Error/Graphql404ErrorContainer'));
const LoadableGraphqlSCITemplateContainer = loadable(() => import(/* webpackChunkName: "GraphqlSCITemplateContainer" */ '../DynamicSCITemplate/GraphqlSCITemplateContainer'));

/*  Component Intent
- This component takes pageData from <CoreBrowsingContainer />
- the two indicators to determine a page are type and contentType
e.g. A collection page has a { type: category, contentType: standard_collections }
** This is how to determine what page is going to be built

Usage
- To add a component to CoreBrowsing:
    - Apply checks for type and contentType and load the container that will make the Grapqhl query
*/

const CoreBrowsingBuilder = ({
    brand, page, is404, productUnavailableName,
}) => {
    if (mbpUtil.getEnv('REACT_APP_MBP_LOGGER_CONSOLE') === 'DEBUG') {
        console.log('CoreBrowsingBuilder page ---->', page);
    }

    // Excluded Category paths
    // TODO: Brittle implementation
    const excludedCategoryPaths = [
        '/Sympathy',
    ];

    // First: Vanity
    // TODO: This should be handled server-side
    // RedirectWithStatus will give universalLoader context.url and context.status
    if (page?.vanity) {
        const { bannerCode = '', url = '', redirectType = '' } = page.vanity;
        const bannerCodeUrl = page.bannerCode?.content?.entries?.[0]?.url;
        if (bannerCode && url && redirectType && bannerCodeUrl) {
            // example of vanity.url /refer.do?r=tmobile18f&utm_medium=bgs&utm_source=tmobile&utm_campaign=specoffer-web_email&utm_content=poliveto&utm_term=20d
            const pageSearchStr = url.split('?')[1];
            return (
                <ErrorBoundary boundaryName="Redirect Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <RedirectWithStatus
                        to={`${bannerCodeUrl}?${pageSearchStr}`}
                        status={redirectType}
                    />
                </ErrorBoundary>
            );
        }
        if (url && redirectType) {
            return (
                <ErrorBoundary boundaryName="Redirect Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <RedirectWithStatus to={url} status={redirectType} />
                </ErrorBoundary>
            );
        }
    }

    // Home
    if (page.type === 'home') {
        return (
            <ErrorBoundary boundaryName="Home Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableHomePage page={page} />
            </ErrorBoundary>
        );
    }

    // Master Template Page
    if (page.contentType === 'master_template_page') {
        return (
            <ErrorBoundary boundaryName="Master Template Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlMasterTemplatePage page={page} />
            </ErrorBoundary>
        );
    }

    // SCI Template Page
    if (page.contentType === 'sci_template') {
        return (
            <ErrorBoundary boundaryName="Graphql SCI Template Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlSCITemplateContainer page={page} />
            </ErrorBoundary>
        );
    }

    // Department
    if (page.type === 'department') {
        return (
            <ErrorBoundary boundaryName="Department Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlDepartmentPage page={page} />
            </ErrorBoundary>
        );
    }

    // Recipe
    if (page.type === 'content' && page.contentType === 'recipe_lander') {
        return (
            <ErrorBoundary boundaryName="Recipe Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlRecipePage page={page} />
            </ErrorBoundary>
        );
    }

    // Club
    if (page.contentType === 'club_lander') {
        return (
            <ErrorBoundary boundaryName="Club Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlClubPage page={page} />
            </ErrorBoundary>
        );
    }

    // houseplants static page
    if (page.path === '/houseplants') {
        return (
            <ErrorBoundary boundaryName="House Plant Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableHousePlantPage page={page} />
            </ErrorBoundary>
        );
    }
    // Connection Template
    if (page.type === 'content' && page.contentType === 'template_page_connection_collections') {
        return (
            <ErrorBoundary boundaryName="Connect Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableConnectPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // Recipe Category Lander Page
    if (page.contentType === 'recipe_category_lander') {
        // eslint-disable-next-line no-param-reassign
        page.type = 'content-page'; // will remove after CS sending correct value
        return (
            <ErrorBoundary boundaryName="Recipe Category Lander Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlRecipeCategoryLanderPage page={page} brand={brand} currRecipePath={page.path} />
            </ErrorBoundary>
        );
    }

    // Contents
    if (isContentPageRoute({ page })) {
        if (page.type === 'notfound') {
            // eslint-disable-next-line no-param-reassign
            page.type = 'content';
        }
        return (
            <ErrorBoundary boundaryName="Content Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableContentPageBuilder page={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    // Category
    if (page.type === 'category' && !excludedCategoryPaths.includes(page.path)) {
        return (
            <ErrorBoundary boundaryName="Category Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlCategoryPageContainer page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // Category Template
    if (page.type === 'category-template' || page.contentType === 'template_page_sales' || page.contentType ===  'template_page_partner_image_carousels') {
        return (
            <ErrorBoundary boundaryName="Template Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlTemplatePageContainer pageData={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // Seo Article Template
    if (page.type === 'content' && page.contentType === 'template_page_seo_articles') {
        return (
            <ErrorBoundary boundaryName="SEO Articles Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSeoArticlesPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    if (page.contentType === 'passport') {
        return (
            <ErrorBoundary boundaryName="Passport Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadablePassportPageContainer page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // Product
    if (page.type === 'product' && page.contentType === 'standard_product') {
        if (
            is404 // SSR
            || (typeof window !== 'undefined' && window.is404) // Client
        ) {
            // render 404 UI
            return (
                <ErrorBoundary boundaryName="Product Not Available Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <LoadableProductNotAvailableContainer brand={brand} productUnavailableName={productUnavailableName || (typeof window !== 'undefined' && window.productUnavailableName)} />
                </ErrorBoundary>
            );
        }
        return (
            <ErrorBoundary boundaryName="Product Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlProductPageContainer page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // Content GFB
    if (page.type === 'content-page') {
        return (
            <ErrorBoundary boundaryName="Graphql Content Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlContentPage page={page} />
            </ErrorBoundary>
        );
    }

    // TODO: Move us to Checkout VVVVV
    if (page.path === '/add-ons') {
        return (
            <ErrorBoundary boundaryName="AddOns Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableAddOnsContainer pageData={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    if (page.path === '/wrap-up') {
        return (
            <ErrorBoundary boundaryName="WrapUp Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableWrapUpContentContainer pageData={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    // TODO: Move us to Checkout ^^^^^

    // Connection Communities Page
    if (page.type === 'content' && page.contentType === 'template_page_wisdo') {
        return (
            <ErrorBoundary boundaryName="Connection Communities Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableConnectionCommunitiesPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    // Ecards Page
    if (page.type === 'content' && page.contentType === 'template_page_ecards') {
        return (
            <ErrorBoundary boundaryName="ECards Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableEcardsPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    // Wyng Static Page
    if (page.contentType === 'template_page_wyng') {
        return (
            <ErrorBoundary boundaryName="Wyng Static Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlWyngStaticPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // Radio Interstitial flow
    // TODO: Use as model for correctly defining content pages in CMS (type AND subType)
    if (page.type === 'content' && page.contentType === 'radio_host_form') {
        return (
            <ErrorBoundary boundaryName="Radio Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlRadioPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    // TODO: ???? (Use route params to define elements - /radio-offers-results/:type)
    if (page.path?.split('/')[1] === 'radio-offers-results') {
        return (
            <ErrorBoundary boundaryName="Radio Offer Results Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableRadioOfferResultsPage page={page} brand={brand} />
            </ErrorBoundary>
        );
    }

    // TODO: Move to content page routes
    if (page.path?.includes('Sympathy-') || page.path?.includes('sympathy-') || page.path?.includes('/customer-testimonials-sympathy')) {
        if (page.type === 'notfound') { // for static pages
            // eslint-disable-next-line no-param-reassign
            page.type = 'category';
        }
        return (
            <ErrorBoundary boundaryName="Sympathy Pages Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSympathyPagesBuilder page={page} />
            </ErrorBoundary>
        );
    }
    if (page.contentType === 'recipe_entry') {
        // eslint-disable-next-line no-param-reassign
        page.type = 'content-page'; // will remove after CS sending correct value
        return (
            <ErrorBoundary boundaryName="Recipe Entry Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableRecipeEntry page={page} />
            </ErrorBoundary>
        );
    }
    // HTML site map
    if (page.contentType === 'site_map') {
        return (
            <ErrorBoundary boundaryName="Sitemap Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableHtmlSitemapContainer page={page} brand={brand} />
            </ErrorBoundary>
        );
    }
    // Undefined in CMS
    if (page.type === 'notfound') {
        // is404(SSR) and window.is404(client) will only be available through SSR when page not found
        // during local development(local:3000) this page will render blank please test in SSR
        if (
            is404 // SSR
            || (typeof window !== 'undefined' && window.is404) // Client
            // || page.path.indexOf('searchterm') === -1
        ) {
            // render 404 UI
            if (brand?.presentation_family === 'growthbrand') {
                return <RedirectWithStatus to={`${determineLanguagePrefixPath('/404')}`} status="404" />;
            }
            if (brand?.presentation_family === 'plants') {
                return <RedirectWithStatus to="/404" status="404" />;
            }
            return (
                <ErrorBoundary boundaryName="404 Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <LoadableGraphql404ErrorContainer brand={brand} page={page} />
                </ErrorBoundary>
            );
        }
        // when ssr hits this it will give context.url and context.status data (universalLoader.js)
        return <RedirectWithStatus to="/404" status="404" />;
    }

    return (
        <ErrorBoundary boundaryName="404 Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
            <LoadableGraphql404ErrorContainer brand={brand} page={page} />
        </ErrorBoundary>
    );
};

CoreBrowsingBuilder.propTypes = {
    page: object.isRequired,
    brand: object.isRequired,
    is404: bool,
    productUnavailableName: string,
};

CoreBrowsingBuilder.defaultProps = {
    is404: false,
    productUnavailableName: '',
};

const enhance = compose(
    trackPage(),
);

export default enhance(CoreBrowsingBuilder);
