/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, shape, string, arrayOf, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';

const styles = (theme)  => ({
    heroContainer: {
        '& a': {
            textDecoration: 'none',
            color: theme.palette.common?.black,
            margin: '10px 15px 0',
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                margin: '10px 5px',
            },
        },
    },
    heroImage: {
        width: '100%',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            minHeight: 250,
        },
    },
    heroText: {
        color: theme.palette.common?.black,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            top: '5%',
        },
        [theme.breakpoints.down('sm')]: {
            zIndex: 0,
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
        '& p': {
            margin: 0,
            padding: 0,
            fontSize: 16,
            color: theme.palette.common?.gray,
            letterSpacing: '0.4px',
            marginTop: '30px',
            [theme.breakpoints.down('md')]: {
                textAlign: 'initial',
                fontSize: 13,
            },
        },
        '& h1,h2,h3,h4,h5,h6': {
            margin: 0,
            padding: 0,
        },
        '& h1': {
            fontSize: '40px',
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                fontWeight: 700,
                textAlign: 'left',
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        '& h2': {
            fontSize: 24,
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                fontWeight: 'normal',
                textAlign: 'left',
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                marginTop: '15px',
            },
        },
    },
    markdownMobileTitle: {
        '& h2': {
            marginTop: '20px',
        },
        '& h1': {
            marginBottom: 0,
        },
        '& p': {
            [theme.breakpoints.down('666')]: {
                display: 'none',
            },
        },
    },
    markdownMobileText: {
        [theme.breakpoints.up('666')]: {
            display: 'none',
        },
        '& h1, h2': {
            display: 'none',
        },
        '& p': {
            margin: '0 10px 20px',
        },
    },
    heroButton: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        paddingTop: '20px',
        [theme.breakpoints.up('1025')]: {
            paddingTop: '80px',
            margin: '0px',
        },
        '& img': {
            width: 40,
            height: 40,
            [theme.breakpoints.down('xs')]: {
                width: 30,
                height: 30,
            },
        },
        '& div': {
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            marginLeft: '7px',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        '& p': {
            fontSize: 24,
            lineHeight: '45px',
            margin: '0px',
            whiteSpace: 'nowrap',
            color: theme.palette.common?.black,
            marginRight: '30px',
            [theme.breakpoints.down('1025')]: {
                fontSize: '18px',
                marginRight: '10px',
                lineHeight: '35px',
            },
        },
        '& a': {
            fontSize: 16,
            lineHeight: '45px',
            letterSpacing: '1.6px',
            color: theme.palette.common?.white,
            background: '#47793b',
            whiteSpace: 'nowrap',
            padding: '0 30px',
            margin: '0px',
            [theme.breakpoints.down('1025')]: {
                padding: '0 15px',
                fontSize: '12px',
                lineHeight: '35px',
                letterSpacing: 0,
            },
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
    arrow: {
        position: 'absolute',
        right: '0',
        color: theme.palette.colorPrimary,
        fontSize: 18,
    },
    mobileContactUs: {
        textAlign: 'left',
        margin: '0 25px 50px 25px',
        [theme.breakpoints.up('667')]: {
            display: 'none',
        },
        '& div': {
            display: 'block',
            padding: '0px',
        },
        '& p': {
            fontSize: 20,
            lineHeight: '35px',
            display: 'inline-block',
            marginRight: '20px',
        },
        '& a': {
            fontSize: 12,
            lineHeight: '38px',
            display: 'inline-block',
        },
        '& .heroButton': {
            paddingTop: '0px',
        },
    },
});

const HeroBlock = ({
    data, classes, showTitle, ssrDeviceType,
}) => {
    const seed = useUIDSeed();
    const { desktop_image, copy,  hero_buttons } = data;
    let markdownClass = '';
    let contactUsClass = '';
    if (showTitle && ssrDeviceType === 'mobile') {
        markdownClass = classes.markdownMobileTitle;
        if (hero_buttons?.length > 0) {
            contactUsClass = classes.desktopContactUs;
        }
    } else if (!showTitle && ssrDeviceType === 'mobile') {
        markdownClass = classes.markdownMobileText;
        if (hero_buttons?.length > 0) {
            contactUsClass = classes.mobileContactUs;
        }
    }

    return (
        <>
            <div className={classes.heroContainer}>
                {desktop_image && <img className={classes.heroImage} src={desktop_image?.url} alt="hero" />}
                <div className={classes.heroText}>
                    <ReactMarkdown
                        source={copy}
                        skipHtml
                        className={markdownClass}
                    />
                    {
                        hero_buttons?.length > 0 && (
                            <div className={contactUsClass}>
                                <Grid container>
                                    { hero_buttons.map((button) => {
                                        const { link: { title = '', href = '' } = {}, sub_copy = '' } = button;
                                        return (
                                            <Grid key={seed(button)} item xs={12} sm={6} md={6} lg={6}>
                                                <div className={classes.heroButtonWrapper}>
                                                    <div className={classes.heroButton}>
                                                        <p>{sub_copy}</p>
                                                        <Link id="heroButton" to={href}>
                                                            {title}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

HeroBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        copy: string,
        desktop_image: shape({
            url: string.isRequired,
        }).isRequired,
        hero_buttons: arrayOf(shape({
            link: shape({
                title: string.isRequired,
                href: string.isRequired,
            }).isRequired,
            image: shape({
                url: string.isRequired,
            }).isRequired,
            sub_copy: string.isRequired,
        })),
    }).isRequired,
    showTitle: bool.isRequired,
    ssrDeviceType: string.isRequired,
};
export default withStyles(styles)(HeroBlock);
