/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { shape, string } from 'prop-types';

function GraphqlCollectionSeoSchema({ categoryData: { page_title, meta_description }, productData: { image } }) {
    let name = '';
    if (page_title && meta_description && image?.name) {
        name = image.name;
        return (
            <Helmet>
                <script type="application/ld+json">
                    {
                        `{
                            "@context":"http://schema.org","@type":"CollectionPage","name":"${page_title.split(' | ')[0]}","description":"${meta_description}",
                            "primaryImageOfPage":{
                                "@type":"ImageObject","url":"${image?.path}${name}x.jpg","image":{
                                    "@type":"ImageObject","url":"${image?.path}${name}x.jpg"
                                }
                            }
                        }`
                    }
                </script>
            </Helmet>
        );
    }
    return null;
}

GraphqlCollectionSeoSchema.propTypes = {
    categoryData: shape({
        page_title: string.isRequired,
        meta_description: string.isRequired,
    }).isRequired,
    productData: shape({
        image: {
            name: string.isRequired,
        }.isRequired,
    }).isRequired,
};
export default GraphqlCollectionSeoSchema;
