/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import {
    object, shape, arrayOf, string,
} from 'prop-types';

import RedirectWithStatus from '../GraphqlCommonComponents/RedirectWithStatus/RedirectWithStatus';
import CoreBrowsingBuilder from './CoreBrowsingBuilder';

import { setABRedirectEventAction } from '../../../../state/ducks/Member/ducks/ABTesting/ABTesting-Actions';

const createGATestImpression = (featureFlag, url, variant, testName = 'redirect test') => `${testName} ${featureFlag} ${variant} ${url}`.toLowerCase();

const CoreBrowsingRedirectContainer = ({
    page, brand, featureFlags,
}) => {
    if (page?.redirectTesting) {
        const {
            matchingParameters, redirects, featureFlagName,
        } = page.redirectTesting;
        const dispatch = useDispatch();
        const { variant } = featureFlags[featureFlagName];
        // matchingParams are for direct loads w channel tests e.g. bing or Affliate
        if (matchingParameters.length !== 0 || variant === 'CONTROL') { // variant CONTROL still needs GA
            const gaString = createGATestImpression(featureFlagName, page.path, variant);
            dispatch(setABRedirectEventAction(gaString));
            return <CoreBrowsingBuilder brand={brand} page={page} />;
        }// TODO } else { serve variant based on flag matchingParameters }

        // TODO makes 3 findURL calls, can we avoid this? break into redirects on server vs client
        return redirects.map((redirect) => {
            if (redirect.variant === variant) {
                const { url } = redirect;
                const gaString = createGATestImpression(featureFlagName, url, variant);
                dispatch(setABRedirectEventAction(gaString));
                return <RedirectWithStatus to={url} status="301" />;
            }
            return null;
        });
    }
    return null;
};

CoreBrowsingRedirectContainer.propTypes = {
    page: shape({
        path: string.isRequired,
        bannerCode: shape({}).isRequired,
        vanity: shape({
            url: string.isRequired,
        }).isRequired,
        redirectTesting: arrayOf(shape({
            matchingParameters: arrayOf(string),
            redirects: arrayOf(shape({
                variant: string,
                url: string,
                code: string,
                contentType: string,
            })),
        })),
    }).isRequired,
    featureFlags: object.isRequired,
    brand: object.isRequired,
};

export default CoreBrowsingRedirectContainer;
