/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import  Grid from '@material-ui/core/Grid';
import {
    object,
} from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import CustomerServiceForm from '../Partials/CustomerServiceFormBlock/CustomerServiceFormBlock';
import Data from './ViewData';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
            padding: '0 15px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
    title: {
        '& h2': {
            fontSize: 28,
            textAlign: 'center',
        },
        '& p': {
            color: theme.palette.contentColor,
            fontSize: 17,
            margin: 'auto',
            maxWidth: '75%',
        },
    },
    formContainer: {
        width: '100%',
        maxWidth: '550px',
        margin: '15px auto 0 auto',
        border: `1px solid  ${theme.palette.colorNeutral40}`,
        boxShadow: `0 3px 3px ${theme.palette.imageCardBoxShadow}`,
        borderRadius: '3px',
    },
    header: {
        padding: '10px',
        fontSize: '16px',
        backgroundColor: theme.palette.colorNeutral40,
        fontWeight: 'bold',
    },
    customerService: {
        margin: '15px 10px 10px',
        '& .text-field-wrapper': {
            margin: '10px 0',
        },
        '& .submitBtn': {
            background: theme.palette.colorPrimary,
            color: theme.palette.common.white,
            width: '100%',
            margin: '10px 0',
        },
        '& .recaptchaError': {
            color: theme.palette.error.main,
        },
        '& .captchaContainer': {
            '& div': {
                '& div': {
                    '& div': {
                        width: '100% !important',
                        textAlign: 'right',
                    },
                },
            },
        },
    },
});

const CustomerServiceReschedulePage = ({ classes }) => {
    const { title = '', description = '', form_header = '' } = Data;
    return (
        <Grid container className={classes.container}>
            <Grid className={classes.title} item xs={12}>
                <h2>{title}</h2>
                <p>{ReactHTMLParser(description)}</p>
            </Grid>
            <div className={classes.formContainer}>
                <div className={classes.header}>{form_header}</div>
                <div className={classes.customerService}>
                    <CustomerServiceForm commentLabel="In a few words, please describe your issue" />
                </div>
            </div>
        </Grid>
    );
};

CustomerServiceReschedulePage.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(CustomerServiceReschedulePage);
