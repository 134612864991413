/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';

import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import GraphqlBreadcrumbs from '../../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';

// helpers
import pdpDictionary from '../../translations/pdpTranslations.json';
import { getLanguageName } from '../../../../../../state/ducks/Member/ducks/Auth/helper/helper';

export const addOgDynamicData = (product, seoData, brand, location) => {
    const { name, image, seo } = product;
    // copy seo data
    const seoCopy = { ...seoData };

    seoCopy.canonicalUrl = seo?.canonicalUrl || '';

    // if page title is empty then we can use product name rather than empty in page title
    if (typeof seoCopy.page_title === 'undefined' || seoCopy.page_title.trim() === '') {
        seoCopy.page_title = name || ''; // page_title is required for product datalayer;
    }

    // eslint-disable-next-line dot-notation
    seoCopy['og'] = [];
    seoCopy.og.push(
        { property: 'og:title', content: name || '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: `https://cdn1.${brand.domain}.com/wcsstore/${brand.identifier}/images/catalog/${image && image.name ? image.name : ''}x.jpg` },
        { property: 'og:description', content: seoData && seoData.meta_description ? seoData.meta_description : '' },
        { property: 'og:url', content: `https://www.${brand.domain}.com${location.pathname || ''}` },
    );
    return (
        <GraphqlSeoHeadTags seoData={seoCopy} alternativeLang="true" />
    );
};

export const getBreadcrumbArray = (product, location, breadcrumbArray = []) => {
    const language = getLanguageName();
    const { partNumber, name } = product;
    const nameTranslation = language === '/fr' ? pdpDictionary?.[`${partNumber}_name`] || name : name;
    const homeTranslation = language === '/fr' ? 'Accueil' : 'Home';
    let bcArray = [];
    // if you hit a pdp page directly
    if (location?.state === undefined || location?.state?.breadCrumbArray === undefined) {
        bcArray = [{ title: homeTranslation, href: '/' }, { title: nameTranslation, href: '' }];
        return (
            <GraphqlBreadcrumbs breadCrumbArray={bcArray} />
        );
    }

    if (breadcrumbArray.length) {
        bcArray = breadcrumbArray.slice();
        bcArray.push({ title: nameTranslation, href: '' });
        return (
            <GraphqlBreadcrumbs breadCrumbArray={bcArray} />
        );
    }

    // if breadCrumb array is passed and exsists append PDP name to end of array
    if (location?.state?.breadCrumbArray) {
        bcArray = location?.state?.breadCrumbArray.slice();
    }
    bcArray.push({ title: nameTranslation, href: '' });
    return (
        <GraphqlBreadcrumbs breadCrumbArray={bcArray} />
    );
};

export const shouldRenderSnipe = (snipe, personalizationFeatureFlag) => {
    if (snipe) {
        const isPersonalizationSnipe = snipe.toLowerCase().includes('personaliz'); // 'e' left out for flexibility between 'personalize' and 'personalization'
        return personalizationFeatureFlag || !isPersonalizationSnipe;
    }
    return false;
};

export const snipePath = (actualPath) => {
    if (actualPath?.indexOf('/snipes') >= 0) {
        return actualPath;
    }
    return `${actualPath}snipes/`;
};

/**
 *
 * @param {*} dateString is date string from API like 20200802
 * @description It will add Delimiter(-) in date if no "-" or "/" exists
 * @returns if 20200802 it will return 2020-08-02 (YYYY-MM-DD)
 */
export const dateStringReformat = (dateString) => {
    let returnDate = dateString;
    if (typeof dateString === 'string' && dateString.indexOf('-') < 0 && dateString.indexOf('/') < 0 && dateString.indexOf(':') < 0) {
        returnDate = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }
    return returnDate;
};
