/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useUIDSeed } from 'react-uid';
import {
    shape, object, arrayOf, string, number, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';

import customBreakpoints from '../../../../helpers/customBreakpoints';

import { trackProductListData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { GraphqlSimpleProduct } from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';

const styles = (theme) => ({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    simpleProduct4row: {
        flex: '0 0 25%',
        marginBottom: '30px',
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
});

const GraphqlSearchProducts = ({
    classes,
    products = [],
    brand,
    productNotAvailable,
    productsPerRow,
}) => {
    const seed = useUIDSeed();

    if (products) {
        trackProductListData({ products });
    }

    const categoryRow = () => {
        let cssPerRow = classes.simpleProduct3row;
        if (productsPerRow === 4 || productNotAvailable) {
            cssPerRow = classes.simpleProduct4row;
        }
        if (productsPerRow === 5) {
            cssPerRow = classes.simpleProduct5row;
        }
        if (productsPerRow === 6) {
            cssPerRow = classes.simpleProduct6row;
        }
        return cssPerRow;
    };

    const ProductContainer = (
        <div className={classes.main}>
            {products.map((product) => {
                const {
                    name = '', image = {}, seo: { url = '' } = {}, skuPriceRange = {}, availability = {}, id = '',
                } = product;
                return (
                    <Grid
                        className={categoryRow()}
                        key={seed(id)}
                        data-testid="search-components"
                    >
                        <GraphqlSimpleProduct
                            brand={brand}
                            name={name}
                            image={image}
                            url={url}
                            skuPriceRange={skuPriceRange}
                            availability={availability}
                        />
                    </Grid>
                );
            })}
        </div>
    );

    return (
        <>
            {ProductContainer}
        </>
    );
};

GraphqlSearchProducts.propTypes = {
    classes: object.isRequired,
    brand: object.isRequired,
    products: arrayOf(shape({
        availability: shape({
            availabilityIndicator: string,
            deliveryDateType: string,
            deliveryMessage: string,
            displayEndDate: string,
            displayStartDate: string,
            earliestShipDate: string,
            latestShipDate: string,
            perishable: string,
            productDeliveryType: string,
            shipAlone: string,
            __typename: string,
        }),
        brand: string,
        image: shape({
            altText: string,
            name: string,
            snipe: string,
            __typename: string,
        }),
        name: string,
        partNumber: string,
        prices: arrayOf(shape({
            currency: string,
            type: string,
            value: number,
            __typename: string,
        })),
        seo: shape({
            url: string,
            __typename: string,
        }),
    })).isRequired,
    productNotAvailable: bool,
    productsPerRow: number,

};

GraphqlSearchProducts.defaultProps = {
    productsPerRow: 4,
    productNotAvailable: false,
};

const enhance = compose(
    withStyles(styles),
);

export default enhance(GraphqlSearchProducts);
