/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import {
    object, string, shape, func, bool,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import DialogContent from '@material-ui/core/DialogContent';
import { bindActionCreators } from 'redux';
import { createEmailSubscription, resetEmailSubscription } from '../../../../state/ducks/App/App-Actions';
import { getEmailSubscriptionStatus } from '../../../../state/ducks/App/App-Selectors';

const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        [theme.breakpoints.down(768)]: {
            margin: '10px',
        },
    },
    textField: {
        border: 'none',
        padding: '7px',
        width: '305px',
        marginRight: '10px',
    },
    button: {
        background: theme.palette.black || '#000',
        color: theme.palette.white || '#fff',
        textTransform: 'uppercase',
        borderRadius: '0',
        lineHeight: '1.1',
        padding: '7px',
        fontWeight: 'bold',
        minWidth: '90px',
    },
    dialogTitle: {
        fontSize: '14px',
        fontWeight: '600',
    },
    modalBox: {
        minHeight: '100px',
        minWidth: '300px',
    },
    fixedHeader: {
        width: '300px',
        position: 'fixed',
    },
    dialogContent: {
        overflowY: 'auto',
    },
    closeBtn: {
        position: 'absolute',
        right: '0',
        top: '0',
    },
    progress: {
        margin: '4px',
        color: theme.palette.black,
    },
    subscribedText: {
        '& > p': {
            margin: '5px 0',
        },
    },
});

const EmailSubscribeBox = ({
    data = {}, classes, handleSubscribe, emailSubscriptionStatus, resetSubscriptionData,
}) => {
    const [email, setEmail] = useState('');
    const [displayDialog, setDisplayDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);

    useEffect(() => {
        const { isSubscribed, error } = emailSubscriptionStatus;
        if (error?.message?.length) {
            setErrorMessage(error.message);
            setEmail('');
            setDisplayDialog(true);
        }
        if (isSubscribed) {
            setEmail('');
            setSubscriptionSuccessful(true);
        }
    }, [emailSubscriptionStatus]);

    if (subscriptionSuccessful && data.subscribe_successful_copy?.length) {
        return (
            <div className={classes.subscribedText}>
                <ReactMarkdown source={data.subscribe_successful_copy} escapeHTML={false} />
            </div>
        );
    }

    const closeErrorDialog = () => {
        setDisplayDialog(false);
        resetSubscriptionData();
    };

    return (
        <div className={classes.wrapper}>
            <input
                aria-invalid="false"
                autoComplete="off"
                className={classes.textField}
                name="email"
                placeholder={data.input_placeholder || 'Enter email address'}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button
                variant="contained"
                type="button"
                className={classes.button}
                onClick={() => handleSubscribe({ email })}
            >
                {data.button_text || 'SUBSCRIBE'}
            </Button>
            <Dialog
                classes={{
                    paper: classes.modalBox,
                }}
                open={displayDialog}
                onClose={() => closeErrorDialog()}
            >
                <DialogTitle>
                    <div className={classes.dialogTitle}>
                        Email Subscribe
                        <IconButton className={classes.closeBtn} aria-label="Close" onClick={() => closeErrorDialog()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {errorMessage}
                </DialogContent>
            </Dialog>
        </div>
    );
};

EmailSubscribeBox.propTypes = {
    classes: object.isRequired,
    data: shape({
        subscribe_successful_copy: string,
        input_placeholder: string,
        button_text: string,
    }).isRequired,
    handleSubscribe: func.isRequired,
    emailSubscriptionStatus: shape({
        isSubscribed: bool,
        error: shape({
            message: string,
        }),
    }).isRequired,
    resetSubscriptionData: func.isRequired,
};

const mapStateToProps = (state) => ({
    emailSubscriptionStatus: getEmailSubscriptionStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleSubscribe: bindActionCreators(createEmailSubscription, dispatch),
    resetSubscriptionData: bindActionCreators(resetEmailSubscription, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailSubscribeBox));
