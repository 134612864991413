/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import SkeletonStyles from '../SkeletonStyles';

const styles = (theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    pageWrapper: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '1405px',
    },
    underNavext: {
        width: '100%',
        height: '60px',
    },
    productGrid: {
        '&--product-block': {
            paddingBottom: '109.565217%',
            [theme?.breakpoints?.down('md')]: {
                paddingBottom: '109.565217%',
            },
        },
        '&--product-title': {
            width: '50%',
        },
        '&--product-price': {
            width: '25%',
        },
        '&--product-delivery': {
            width: '35%',
        },
    },
    padding: {
        padding: 10,
    },
    paddingRight: {
        paddingRight: 50,
    },
});

const TabletCategoryPageSkeleton = ({ classes }) => (
    <>
        <div>
            <Grid container className={classes.padding}><div className={`${classes.underNavext} ${classes.shimmer}`} />
            </Grid>
            <div className={classes.pageWrapper}>
                <Grid container>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.padding}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                    </Grid>
                </Grid>
            </div>
        </div>
    </>
);

TabletCategoryPageSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(TabletCategoryPageSkeleton);
