/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import gql from 'graphql-tag';
import { GRAPHQL_ENV } from '..';
import { setLocale } from '../../../state/ducks/Member/ducks/Auth/helper/helper';

/**
 * @param {string} brand - baskets or berries
 * @param {string} partNumber - SKU to be fetched
 */

// should refactor the query below to use the information from the redux store and not the session storage.

const findProductPageByPartNumber = (brand, partNumber, currency) => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`{ findProductPageByPartNumber(brand: "${brand}", environment: "${GRAPHQL_ENV}", partNumber: "${partNumber}", currency: "${currency}", locale: "${setLocale()}") {
        content
        defaultContent
        product {
            name
            seo {
                url
                canonicalUrl
            }
            skuPriceRange {
                sale {
                    value
                    __typename
                }
                retail {
                    value
                    __typename
                }
                __typename
            }
            partNumber
            addonGroups{
                    name,
                    maxAllowedAddons,
                    addons{
                        partNumber,
                        id,
                        legacyId,
                        name,
                        shortDescription,
                        image{
                            path,
                            name
                        },
                    prices{
                        currency,
                        type,
                        value
                    },
                    ageVerifyFlag
                }
            }
            baseCode
            brand
            brandId
            parentCategories {
                id
            }
            displayDeliveryCalendar
            shortDescription
            longDescription
            sympathyValue
            isPassportEligible
            productType
            legacyId
            reviews
            isPersonalizable
            image {
                name
                path
                altText
                additionalImages
                snipe
                snipeImagePath
                __typename
            }
            upsells {
                id
                name
                image {
                    name
                    path
                    snipe
                    snipeImagePath
                    altText
                }
            prices {
                currency
                type
                value
                }
            }
            productContents
            availability {
                deliveryMessage
                productDeliveryType
                __typename
            }
            attributes {
                id,
                name,
                values {
                    id
                    value
                    __typename
                }
                __typename
            }
            productContents
            productSkus {
                id
                name
                rank
                productType
                brandId
                isInternational
                countries
                legacyId
                longDescription
                attributes {
                    name,
                    values {
                        value
                        __typename
                    }
                    __typename
                }
                personalization {
                    personalizationDefDesign
                    personalizationTemplate
                    indicator
                    lines {
                        lineNumber
                        maxChars
                    }
                }
                image {
                    name
                    path
                }
                availability{
                    earliestShipDate
                    deliveryMessage
                }
                ageVerifyFlag
                addOnFlag
                minAge
                prices {
                type
                value
                __typename
                }
               cyo {
                    maxQuantity
                    cyoGroup {
                        name
                        type
                        numberofUnitsRequiredForComponent
                        maxFlavorsForComponent
                        rank
                        components {
                            id
                            legacyId
                            name
                            image {
                                name
                                path
                            }
                        }
                    }
                  }
            __typename
            }
        __typename
        }
        isPassportBundleItem
    __typename
    }
}
`
);

export default findProductPageByPartNumber;
