/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// adds customers variant to event action
const addLDVariant = (block, variant) => {
    const trackingObj = {
        tracking_event_action: block?.tracking_event_action,
        tracking_event_category: block?.tracking_event_category,
        tracking_event_label: block?.tracking_event_label,
        eventName: 'feature_interaction', // Requird for GA4 feature_click
    };
    const eventAction = `${block.tracking_event_action} - ${variant}`;
    trackingObj.tracking_event_action = eventAction;
    return trackingObj;
};

export const generateVariantGAEvents = (blocks, featureFlags) => {
    if (blocks && blocks.length === 0) {
        return null;
    }
    const variantBlocks = [];

    // add variant to event action in tracking object
    blocks.forEach((block) => {
        if (block.a_b_testing_block) {
            const testFlagName = block.a_b_testing_block.feature_flag_name;
            if (featureFlags[testFlagName]) {
                const { variant = '' } = featureFlags[testFlagName];
                const variantTrackingObj = addLDVariant(block.a_b_testing_block, variant);
                variantBlocks.push(variantTrackingObj);
            }
        }
    });

    return variantBlocks;
};

/* eslint-disable no-undef */ // eventAction throws this execption??
export const generateTestImpressionGAEvent = (eventAction) => {
    const event = {
        eventAction,
        eventCategory: 'Test Impression',
    };
    return event;
};
