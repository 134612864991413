/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import {
    object, string, array, func, bool,
} from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { when } from '../../../components/Page/helpers/pageHelpers';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getABRedirectEventAction } from '../../../../state/ducks/Member/ducks/ABTesting/ABTesting-Selectors';
import { clearABRedirectEventAction } from '../../../../state/ducks/Member/ducks/ABTesting/ABTesting-Actions';

import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { generateVariantGAEvents, generateTestImpressionGAEvent } from '../utils/abTestingTrackingHelpers';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const getPageType = (page) => {
    let pageType = page?.type;
    if (!pageType && typeof window !== 'undefined') {
        const paths = window.location.pathname?.split('/');
        if (pageType === 'gift-list') { // giftlist coming from container creates some consistency since the pageType is not passed on start
            pageType = 'giftlist';
        } else if (pageType === 'searcg') { // need to aggregate all searchs, third index is the term instead of pageType
            pageType = 'search';
        } else {
            pageType = paths?.[2]?.toLocaleLowerCase();
        }
    }
    return pageType;
};

const testCallback = (currentPage, location, pageViewSuccessTest) => {
    if (window.mbpDataLayer?.length) {
        const dataLayer = window.mbpDataLayer;

        // Retry test if currentPage does not match data layer "page". (Core Browsing)
        if (currentPage.path
            && (currentPage.path !== location.pathname)
        ) {
            return false;
        }

        // Fire pageViewSuccessTest
        if (currentPage
            && (pageViewSuccessTest && typeof pageViewSuccessTest === 'function')
        ) {
            return pageViewSuccessTest({ currentPage, dataLayer });
        }
    }

    return false;
};

const setLogginEnabled = (sessionHelpersLoggingEnabled) => {
    if (typeof window !== 'undefined') {
        // Add global property to determine if debugging attribution.
        window.sessionHelpersLoggingEnabled = sessionHelpersLoggingEnabled;
    }
};

const checkRedirectGAEvent = (redirectGAEventAction) => {
    const redirectTestGAEvent = redirectGAEventAction ? generateTestImpressionGAEvent(redirectGAEventAction) : null;

    if (redirectTestGAEvent) {
        clearABRedirectEventAction();
    }
    return redirectTestGAEvent;
};

export default (params) => {
    const { pageViewSuccessTest, updateEveryRender = false } = params;

    return (WrappedComponent) => {
        class PageViewTracker extends Component {
            componentDidMount() {
                const clearFromDataLayer = ['track_product_listing_data', 'track_cyo_data', 'track_product_data'];
                // clearing older values from data layer
                if (window.mbpDataLayer?.length) {
                    const pageData = this.props?.page;

                    window.mbpDataLayer = window.mbpDataLayer.filter((layer) => {
                        const doNotClear = layer?.action === 'track_product_listing_data' && layer?.searchTerm === pageData?.searchTerm;
                        return doNotClear || !clearFromDataLayer.includes(layer?.action);
                    });
                }
                const { sessionHelpersLoggingEnabled, page } = this.props;
                setLogginEnabled(sessionHelpersLoggingEnabled);

                const pageType = getPageType(page);

                /* eslint-disable no-undef */
                if (typeof bttUT !== 'undefined' && bttUT?.start && pageType && this.props?.brand?.code) {
                    bttUT.start({ pageName: `${this.props.brand.code} - ${pageType} - VT`, txnName: 'eCommerce' });
                }
                when(this.test, this.success, 250, 20);
            }

            generateCurrentPageObject = (mbpDataLayer, flags) => {
                const {
                    brand, location, match, page, searchTerm,
                } = this.props;

                const currentPage = {
                    brand,
                    location,
                    match,
                    flags,
                    searchTerm,
                };

                // No page Prop; Pull from Data Layer entry
                if (!page) {
                    const pageEntry = (mbpDataLayer?.mbpDataLayer.filter((entry) => entry?.page)) || {};
                    return {
                        ...currentPage,
                        ...pageEntry?.[0]?.page,
                    };
                }

                return {
                    ...currentPage,
                    ...page,
                };
            }

            generateDataLayer = (currentPage, unprocessedDataLayer) => {
                const { seo } = this.props;

                const data = {
                    page: currentPage,
                    seo,
                    unprocessedDataLayer,
                };

                return data || {};
            }

            test = () => {
                const { location } = this.props;
                const dataLayer = window.mbpDataLayer;
                // Core DataLayer properties
                const currentPage = this.generateCurrentPageObject({
                    dataLayer, mbpDataLayer: dataLayer, // keeping dataLayer to avoid any other error
                });
                return testCallback(currentPage, location, pageViewSuccessTest);
            }

            success = () => {
                const {
                    trackPageView, blocks, redirectGAEventAction = '',
                    featureFlags, ffIsCheckoutMFEEnabled, ffIsTealiumEventOverrideEnabled,
                } = this.props;
                const mbpDataLayer = window.mbpDataLayer;
                const variantTestGAEvents = generateVariantGAEvents(blocks, featureFlags);
                const redirectTestGAEvent = checkRedirectGAEvent(redirectGAEventAction);

                if (mbpDataLayer) {
                    console.log('DataLayer found, FIRE PAGE VIEW', mbpDataLayer);
                    // Expose flags for data layer inclusion
                    const flags = {
                        isMFECheckoutEnabled: ffIsCheckoutMFEEnabled,
                        ffIsTealiumEventOverrideEnabled,
                    };
                    const currentPageData = this.generateCurrentPageObject({
                        mbpDataLayer,
                        flags,
                    }) || {};

                    trackPageView({
                        page: {
                            ...currentPageData,
                            dataLayer: mbpDataLayer,
                            variantTestGAEvents,
                            redirectTestGAEvent,
                        },
                        updateEveryRender,
                    });
                }
            }

            render() {
                /* eslint-disable react/jsx-props-no-spreading */
                return (
                    <WrappedComponent
                        {...this.props}
                    />
                );
            }
        }

        PageViewTracker.propTypes = {
            featureFlags: object,
            brand: object.isRequired,
            location: object.isRequired,
            match: object.isRequired,
            page: object,
            blocks: array,
            untData: array,
            seo: object,
            domainURL: string,
            trackPageView: func.isRequired,
            redirectGAEventAction: string,
            sessionHelpersLoggingEnabled: bool.isRequired,
            searchTerm: string.isRequired,
            ffIsCheckoutMFEEnabled: bool.isRequired,
            ffIsTealiumEventOverrideEnabled: bool.isRequired,
        };

        PageViewTracker.defaultProps = {
            untData: [],
            blocks: [],
            seo: {},
            domainURL: '',
            page: null,
            featureFlags: {},
            redirectGAEventAction: '',
        };

        const mapStateToProps = (state) => ({
            brand: getBrand(state),
            redirectGAEventAction: getABRedirectEventAction(state),
        });

        const mapDispatchToProps = (dispatch) => bindActionCreators({
            trackPageView: tagManagerActions.trackPageView,
            clearABRedirectEventAction,
        }, dispatch);

        PageViewTracker.displayName = `withPageView(${getDisplayName(PageViewTracker)})`;

        const enhance = compose(
            withRouter,
            connect(mapStateToProps, mapDispatchToProps),
        );

        return enhance(PageViewTracker);
    };
};
