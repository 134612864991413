/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import {
    bool, object, func, string, arrayOf, shape,
} from 'prop-types';
import MobileFilterDialog from './MobileFilterDialog/MobileFilterDialog';
import DesktopFilterDialog from './DesktopFilterDialog/DesktopFilterDialog';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';

export class FilterDialog extends PureComponent {
    render() {
        const {
            setFilterOptions, presentation_family, brand, featureFlags, displayDeliveryMethod, productFacets = [], setFacetsOptions, ssrDeviceType, language,
        } = this.props;
        return (
            <DesktopMobile
                desktop={() => <DesktopFilterDialog displayDeliveryMethod={displayDeliveryMethod} featureFlags={featureFlags} setFilterOptions={setFilterOptions} brand={brand} language={language} />}
                mobile={() => <MobileFilterDialog displayDeliveryMethod={displayDeliveryMethod} productFacets={productFacets} setFilterOptions={setFilterOptions} setFacetsOptions={setFacetsOptions} presentation_family={presentation_family} ssrDeviceType={ssrDeviceType} language={language} />}
            />
        );
    }
}

FilterDialog.propTypes = {
    setFilterOptions: func.isRequired,
    setFacetsOptions: func.isRequired,
    brand: string.isRequired,
    presentation_family: string.isRequired,
    featureFlags: object.isRequired,
    displayDeliveryMethod: bool.isRequired,
    productFacets: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    ssrDeviceType: string,
    language: string.isRequired,
};
FilterDialog.defaultProps = {
    ssrDeviceType: '',
};

export default FilterDialog;
