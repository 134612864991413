/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSSRMediaQuery from './useSSRMediaQuery';
import { getIsBot } from '../../../state/ducks/App/App-Selectors';
import { getFeatureFlags } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const getLowQualityUrl = (url = '') => {
    if (url === '' || typeof url !== 'string') return '';
    // If there is no quality attached
    if (url?.indexOf('quality=') === -1) {
        if (url.indexOf('?') === -1) {
            return `${url}?quality=1`;
        }
        return `${url}&quality=1`;
    }

    // Modify current quality to be 1 if it is not already
    const currentQuality = /quality=\d+/.exec(url)[0].split('=')[1];
    if (currentQuality !== '1') {
        return url.replace(`quality=${currentQuality}`, 'quality=1');
    }

    return url;
};

// reconcile browser functions
let eventLoopQueuer = setTimeout;
let eventLoopCleaner = clearTimeout;
// prefer idle callback when possible
if (typeof requestIdleCallback !== 'undefined') {
    /**
    * requestIdleCallback is a newer function but the one we want when
    * available. Its lower priority than setTimeout so more likely to
    * keep the main thread free.
    *
    * We handle this low priority by adding the "onClick" to the
    * placeholder component.
    */
    eventLoopQueuer = requestIdleCallback;
    eventLoopCleaner = cancelIdleCallback;
}

const defaultQualities = {
    mobile: '70',
    tablet: '75',
    desktop: '80',
};

/**
 * @description This hook will originally fetch a low quality image with the query quality=1.
 * It will then fetch a high quality image in the backgroundThis
 * @documentation This is the link for fastly quality documentation
 *  https://developer.fastly.com/reference/io/quality/
 * @param {string} url the url of the image to be fetched.
 * @param {object} qualities An object that defines the high quality image quality that will be fetched
 * @param {object} breakpoints An object that can override the default breakpoints for screen sizes
 */
const useImageUrlQualityTransformation = (
    url = '',
    qualities = defaultQualities,
    breakpoints = {
        mobile: '200px',
        tablet: '640px',
        desktop: '1024px',
    },
) => {
    const isBot = useSelector(getIsBot);
    const featureFlags = useSelector(getFeatureFlags);
    const ffIsProgressiveImagesEnabled = featureFlags['is-progressive-images-enabled'];

    if (isBot || !ffIsProgressiveImagesEnabled) {
        return url;
    }

    const [currentUrl, setCurrentUrl] = useState(getLowQualityUrl(url));
    const [hqImageFetched, setHqImageFetched] = useState(false);
    const [hqImageTimeout, setHQImageTimeout] = useState();
    const deviceType = useSSRMediaQuery(breakpoints);
    const isMobile = deviceType === 'mobile';
    const isTablet = deviceType === 'tablet';
    const isDesktop = deviceType === 'desktop';

    useEffect(() => () => {
        if (typeof window !== 'undefined' && hqImageTimeout) {
            eventLoopCleaner(hqImageTimeout);
        }
    }, [hqImageTimeout]);

    useEffect(() => {
        // Set the low quality image state
        if (!hqImageFetched && currentUrl?.indexOf('quality=1') !== -1 && currentUrl !== '' && typeof window !== 'undefined') {
            setHqImageFetched(true);
            setHQImageTimeout(eventLoopQueuer(() => {
                let newQuality;
                if (isMobile) {
                    newQuality = qualities?.mobile || qualities?.tablet || qualities?.desktop || defaultQualities.mobile;
                }
                if (isTablet) {
                    newQuality = qualities?.tablet || qualities?.desktop || qualities?.mobile || defaultQualities.tablet;
                }
                if (isDesktop) {
                    newQuality = qualities?.desktop || qualities?.tablet || qualities?.mobile || defaultQualities.desktop;
                }
                const newSrc = currentUrl.replace('quality=1', `quality=${newQuality}`);
                const imageToLoad = new Image();
                imageToLoad.src = newSrc;
                imageToLoad.onload = () => {
                    // When image is loaded replace the image's url
                    setCurrentUrl(newSrc);
                };
            }));
        }
    }, [url, isMobile, isTablet, hqImageFetched, currentUrl, qualities, hqImageTimeout, ffIsProgressiveImagesEnabled]);

    return currentUrl;
};

export default useImageUrlQualityTransformation;
