/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, shape, bool,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
    ratingRaised: {
        '&.yotpo .yotpo-bottomline .rating-star.yotpo-icon': {
            color: '#fec600', // TODO: add this color to brandtheme once yotpo will start working
        },
        [theme.breakpoints.down(480)]: {
            '& .yotpo-bottomline.pull-left.star-clickable': {
                display: 'inline-block',
            },
        },
    },
    pullRight: {
        '& .star-clickable.yotpo-bottomline': {
            float: 'right',
            textAlign: 'right',
        },
    },
});

const YotpoProductReview = ({
    classes,
    partNumber,
    name,
    productUrl,
    image,
    shortDescription,
    right,
}) => (
    <div
        className={`yotpo bottomLine ${classes.ratingRaised} ${right ? classes.pullRight : ''}`}
        data-product-id={partNumber}
        data-name={name}
        data-url={productUrl}
        data-image-url={`${image.path} ${image.name}.jpg`}
        data-description={shortDescription}
        data-reviews="5"
    />
);

YotpoProductReview.propTypes = {
    classes: object.isRequired,
    name: string.isRequired,
    partNumber: string.isRequired,
    shortDescription: string,
    image: shape({
        name: string.isRequired,
        path: string.isRequired,
    }).isRequired,
    productUrl: string.isRequired,
    right: bool,
};

YotpoProductReview.defaultProps = {
    shortDescription: '',
    right: false,
};

export default withStyles(styles)(YotpoProductReview);
