/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import {
    object, func, string, bool,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getDataDictionary } from '../../../../../../../state/ducks/App/App-Selectors';

const styles = (theme) => ({
    sortAndFilterContainer: {
        position: 'relative',
        marginLeft: '20px',
    },
    filterBtn: {
        whiteSpace: 'nowrap',
        border: `1px solid ${theme.palette.grey9}`,
        padding: '8px 0px 8px 8px',
        display: 'inline-block',
        cursor: 'pointer',
        color: theme.palette.colorPrimary,
        background: theme.palette.colorNeutral20,
        fontWeight: '700',
        fontSize: '14px',
    },
    filterContainerDialog: {
        position: 'absolute',
        right: 0,
        top: '38px',
        zIndex: 3,
        maxWidth: '210px',
        width: '210px',
        padding: '10px',
        boxShadow: '0px 2px 3px -1px rgba(0,0,0,0.35)',
        background: theme.palette.colorPrimaryContrast,
    },
    filterTitle: {
        color: theme.palette.colorNeutral70,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
        margin: '16px 0',
    },
    filterOptions: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterSerachBtn: {
        padding: '13px 25px',
        borderRadius: '0px',
        color: theme.palette.colorNeutral70,
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            background: theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
        '&:active': {
            background: theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '8px 10px',
        },
    },
    textRight: {
        textAlign: 'right',
    },
    content: {
        padding: '15px 0px',
        fontSize: '14px',
        fontWeight: 700,
        color: theme.palette.colorNeutral70,
    },
    applyBtn: {
        color: theme.palette.colorPrimary,
        width: '100%',
        border: '2px solid',
        display: 'block',
        padding: '8px 0px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    btnBackground: {
        background: theme.palette.colorPrimary,
        color: theme.palette.colorPrimaryContrast,
        '&:hover': {
            background: theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
    },
    applyButtonWrapper: {
        marginTop: '10px',
    },
    switch: {
        '& span': {
            '& span': {
                color: theme.palette.colorPrimary,
            },
        },
    },
    ArrowDropdownIcon: {
        color: theme.palette.colorPrimary,
        marginTop: '-8px',
        marginBottom: '-8px',
        marginLeft: '-8px',
    },
});

class DesktopFilterDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggleFilter: false,
            sellingBtn: true,
            highToLowBtn: false,
            lowToHighBtn: false,
            localFlorist: true,
            giftBox: true,
        };
        this.containerRef = createRef();
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside = (event) => {
        if (this.containerRef && event?.target && this.containerRef?.current && !this.containerRef.current.contains(event.target)) {
            this.setState({
                toggleFilter: false,
            });
        }
    }

     toggleFilter = () => {
         const { toggleFilter } = this.state;
         this.setState({
             toggleFilter: !toggleFilter,
         });
     };

     applyFilter =() => {
         const { setFilterOptions } = this.props;
         setFilterOptions(this.state);
         const {
             toggleFilter, giftBox, highToLowBtn, sellingBtn, localFlorist, lowToHighBtn,
         } = this.state;
         this.setState({
             sellingBtn,
             highToLowBtn,
             lowToHighBtn,
             localFlorist,
             giftBox,
             toggleFilter: !toggleFilter,
         });
     }

      handleChangeLocalFlorist =   () => {
          const { localFlorist } = this.state;
          this.setState({
              localFlorist: !localFlorist,
          });
      };

      handleChangeGiftBox =   () => {
          const { giftBox } = this.state;
          this.setState({
              giftBox: !giftBox,
          });
      };

    setFilterData = (filter) => {
        switch (filter) {
            case 'sellingBtn':
                return this.setState({
                    sellingBtn: true,
                    highToLowBtn: false,
                    lowToHighBtn: false,
                }, () => { this.applyFilter(); });
            case 'highToLowBtn':
                return this.setState({
                    sellingBtn: false,
                    highToLowBtn: true,
                    lowToHighBtn: false,
                }, () => { this.applyFilter(); });
            case 'lowToHighBtn':
                return this.setState({
                    sellingBtn: false,
                    highToLowBtn: false,
                    lowToHighBtn: true,
                }, () => { this.applyFilter(); });
            default:
                return null;
        }
    }

    showDeliveryMethod = () => {
        const { classes, displayDeliveryMethod } = this.props;
        const { localFlorist, giftBox } = this.state;
        if (displayDeliveryMethod) {
            return (
                <>
                    <div className={classes.filterTitle}>Delivery Method</div>
                    <Grid container>
                        <Grid className={classes.content} item xs={6}> Shipped in the Gift Box
                        </Grid>
                        <Grid className={classes.textRight} item xs={6}>
                            <Switch
                                className={giftBox ? classes.switch : ''}
                                checked={giftBox}
                                onClick={() => this.handleChangeGiftBox()}
                                value="checkedA"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className={classes.content} item xs={6}> Delivery by Local Florist
                        </Grid>
                        <Grid className={classes.textRight} item xs={6}>
                            <Switch
                                className={localFlorist ? classes.switch : ''}
                                checked={localFlorist}
                                onClick={() => this.handleChangeLocalFlorist()}
                                value="checkedB"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                    </Grid>
                </>
            );
        }
        return null;
    }

    render() {
        const {
            classes, brand, dataDictionary, language,
        } = this.props;
        const {
            toggleFilter, sellingBtn, highToLowBtn, lowToHighBtn,
        } = this.state;
        const sortBy = 'Sort By';
        const sortProductsBy = 'Sort Products by';
        const bestSelling = 'Best Selling';
        const lowToHigh = 'Low - High';
        const highToLow = 'High - Low';
        return (
            <div
                ref={this.containerRef}
                className={classes.sortAndFilterContainer}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <span role="presentation" onClick={() => this.toggleFilter()} className={classes.filterBtn} style={{ margin: brand === 'harryanddavid' ? '10px 0px 10px 0px' : 'null' }}>{language === '/en' ? sortBy : dataDictionary[sortBy] } <ArrowDropDownIcon className={classes.ArrowDropdownIcon} /></span>
                    </Grid>
                </Grid>
                {
                    toggleFilter
                        ? (
                            <Grid container className={classes.filterContainerDialog} style={{ marginTop: brand === 'harryanddavid' ? '10px' : 'null' }}>
                                <Grid item xs={12}>
                                    <div className={classes.filterTitle}>{language === '/en' ? `${sortProductsBy}:` : `${dataDictionary[sortProductsBy]}:`}</div>
                                    <div className={classes.filterOptions}>
                                        <Button onClick={() => this.setFilterData('sellingBtn')} className={sellingBtn ? `${classes.filterSerachBtn} ${classes.btnBackground}` : classes.filterSerachBtn}>{language === '/en' ? bestSelling : dataDictionary[bestSelling]}</Button>
                                        <Button onClick={() => this.setFilterData('highToLowBtn')} className={highToLowBtn ? `${classes.filterSerachBtn} ${classes.btnBackground}` : classes.filterSerachBtn}>{language === '/en' ? highToLow : dataDictionary[highToLow]}</Button>
                                        <Button onClick={() => this.setFilterData('lowToHighBtn')} className={lowToHighBtn ? `${classes.filterSerachBtn} ${classes.btnBackground}` : classes.filterSerachBtn}>{language === '/en' ? lowToHigh : dataDictionary[lowToHigh]}</Button>
                                    </div>
                                    {this.showDeliveryMethod()}

                                </Grid>
                            </Grid>
                        )
                        : ''
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dataDictionary: getDataDictionary(state),
});

DesktopFilterDialog.propTypes = {
    classes: object.isRequired,
    setFilterOptions: func.isRequired,
    brand: string.isRequired,
    displayDeliveryMethod: bool.isRequired,
    dataDictionary: object.isRequired,
    language: string.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(DesktopFilterDialog));
