/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme)  => ({
    passportJoin:
    {
        width: '80%',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '45px',
        '& h3': {
            fontSize: '30px',
            color: theme.palette.crPassportTitle,
            margin: '0px 70px',
        },
        '& p': {
            fontSize: '18px',
            letterSpacing: '-0.5px',
            margin: '10px 0px',
            '& span': {
                color: theme.palette.crPassportSubTitle,
            },
            '& strong': {
                color: theme.palette.crPassportTitle,
            },
        },
        '& a': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            padding: '10px 35px',
            textDecoration: 'none',
            fontSize: '16px',
            margin: '5px auto',
            display: 'inline-block',
            letterSpacing: '2px',
        },
    },
});
const PasswordBlock = ({
    classes,
}) => (
    <div className={classes.passportJoin}>
        <p>Join our <span>celebrations</span><strong>passport®</strong> program and get</p>
        <h3>FREE SHIPPING/NO SERVICE CHARGE* <span>ACROSS OUR FAMILY OF BRANDS</span></h3>
        <p>Membership pays off in 2 orders.</p>
        <Link to="/passport-flowers">LEARN MORE</Link>
    </div>
);

PasswordBlock.propTypes = {
    classes: object.isRequired,
};

export default  withStyles(styles)(PasswordBlock);
