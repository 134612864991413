/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import mbpLogger from 'mbp-logger';
import { useLazyQuery } from 'react-apollo';
import { object } from 'prop-types';
import findCustomerServiceDetails from '../../../../gql/queries/findCustomerServiceDetails';
import { GRAPHQL_ENV } from '../../../../gql';
import ContactUsBannerBuilder from './ContactUsBannerBuilder';

const ContactUsBannerContainer = ({ brand, page }) => {
    const CUSTOMER_SERVICE_QUERY = findCustomerServiceDetails();

    const [getContactDetails, {
        error, data,
    }] = useLazyQuery(CUSTOMER_SERVICE_QUERY);

    useEffect(() => {
        getContactDetails({
            variables: {
                brand: brand['domain-name'],
                environment: GRAPHQL_ENV,
                contentType: 'template_page_customer_service',
            },
        });
    }, [brand]);

    if (error) {
        mbpLogger.logError({
            query: CUSTOMER_SERVICE_QUERY,
            component: 'CustomerService.js',
            message: 'Error loading customer service data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
    }

    return <ContactUsBannerBuilder brand={brand} page={page} data={data?.findContent?.content?.entries?.[0].modular_blocks} />;
};

ContactUsBannerContainer.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
};

export default ContactUsBannerContainer;
