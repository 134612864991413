/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, shape, string, bool,
} from 'prop-types';

const styles = (theme) => ({
    order_tracking_link: {
        textDecoration: 'none',
        letterSpacing: '0.42px',
        [theme.breakpoints.up(768)]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up(1025)]: {
            fontSize: '16px',
            borderRadius: '6px',
        },
    },
    trackingText: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        marginRight: '6px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        height: '35px',
        borderRadius: '6px',
    },
    truckIcon: {
        height: '29px',
        width: '29px',
        marginRight: '6px',
        marginLeft: '6px',
    },
});

const GraphqlUNTOrderTracking = ({ classes, orderTracking }) => (
    <Link
        to={orderTracking?.link?.href}
        style={{ backgroundColor: orderTracking?.background_color || '' }}
        className={classes.order_tracking_link}
    >
        <div className={classes.container}>
            <img className={classes.truckIcon} src={orderTracking?.icon?.url} alt="" />
            <p style={{ color: orderTracking?.font_color || '' }} className={classes.trackingText}>{orderTracking?.link?.title}</p>
        </div>
    </Link>
);

GraphqlUNTOrderTracking.propTypes = {
    classes: object.isRequired,
    orderTracking: shape({
        background_color: string.isRequired,
        font_color: string.isRequired,
        icon: shape({
            url: string.isRequired,
        }).isRequired,
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
        show: bool.isRequired,
    }).isRequired,
};

export default withStyles(styles)(GraphqlUNTOrderTracking);
