/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { PureComponent } from 'react';
import List from '@material-ui/core/List';
import {
    object, shape, string, func, array,
} from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import ReactHtmlParser from 'react-html-parser';

class ProductFacetsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount = () => {
        const {
            facetsView, checkedOptions,
        } = this.props;

        if (
            facetsView?.entries.length
            && checkedOptions.length
            && checkedOptions.some((facet) => facetsView.entries.find((val) => val.value === facet))
        ) {
            this.handleClick();
        }
    }

    handleClick = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    setFacetsFilterData = (e) => {
        const {
            checkedOptions, setCheckedOptions, history, setFilterDataInCategoryBuilder,
        } = this.props;
        const value = e.target.value;
        let checkedArr = [];

        if (checkedOptions && value) {
            checkedArr = [...checkedOptions];
            if (checkedArr.some((check) => check === value)) {
                checkedArr = checkedArr.filter((check) => check !== value);
            } else {
                checkedArr.push(value);
            }
            setCheckedOptions(checkedArr);
        }

        const urlSelectedParams = checkedArr.join('&');
        history.push({
            search: `${urlSelectedParams ? `facet=${urlSelectedParams}` : ''}`,
            state: checkedArr,
        });
        if (typeof window !== 'undefined') {
            const matches = window.matchMedia('(min-width: 1024px)').matches;
            if (matches) {
                setFilterDataInCategoryBuilder(checkedArr);
            }
        }
    };

    render() {
        const {
            facetsView, classes, iconType, checkedOptions,
        } = this.props;
        const { open } = this.state;
        const filterFacetsName = facetsView ? facetsView.name : null;
        const facetslength = facetsView ? facetsView.entries.length : null;
        let expandButton = {};
        if (iconType === 'primary') {
            expandButton = (open ? <ExpandMore data-testid="expand-more-icon" /> : <ExpandLess data-testid="expand-less-icon" />);
        } else {
            expandButton = (open ? <Remove data-testid="remove-icon" /> : <Add data-testid="add-icon" />);
        }
        return (
            <>
                {filterFacetsName !== 'OfferPrice_USD' && filterFacetsName !== 'ParentCatalogGroup'
                    ? (
                        <div className={classes.rootListItem}>
                            <ListItem onClick={this.handleClick} className={classes.ListItem} classes={{ selected: classes.active }} data-testid={facetsView.name}>
                                <ListItemText secondary={(
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.facetsName}
                                            color="textPrimary"
                                        >
                                            {filterFacetsName}
                                        </Typography>
                                    </>
                                )}
                                />
                                {expandButton}
                            </ListItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding className={facetslength > 9 ? classes.rootFacetsValueOverflow : classes.rootFacetsValues}>
                                    {facetsView ? facetsView.entries.map((data) => {
                                        let isChecked = false;
                                        if (checkedOptions?.length) {
                                            isChecked =  checkedOptions.includes(data?.value);
                                        }
                                        return (
                                            <ListItem key={data.value} className={classes.nested}>
                                                <input
                                                    type="checkbox"
                                                    className={classes.facetsCheckbox}
                                                    name="facetsViewCheckbox"
                                                    value={data.value}
                                                    checked={isChecked}
                                                    onChange={(e) => this.setFacetsFilterData(e)}
                                                />
                                                <ListItemText secondary={(
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.facetsValues}
                                                            color="textPrimary"
                                                        >
                                                            {ReactHtmlParser(data?.name)}
                                                        </Typography>
                                                    </>
                                                )}
                                                />
                                            </ListItem>
                                        );
                                    }) : null}
                                </List>
                            </Collapse>
                        </div>
                    ) : null}
            </>

        );
    }
}
ProductFacetsView.defaultProps = {
    iconType: 'primary',
};
ProductFacetsView.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    facetsView: shape({
        name: string.isRequired,
    }).isRequired,
    checkedOptions: array.isRequired,
    setCheckedOptions: func.isRequired,
    setFilterDataInCategoryBuilder: func.isRequired,
    iconType: string,
};

export default withRouter(ProductFacetsView);
