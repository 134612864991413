/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'I have a different issue',
    description: '<p>Before contacting support, please read the following</p>'
   + '<p>You can find your order and confirm your delivery window by visiting <a href="/account/orders">track my order.</a></p>'
   + '<p>If you are missing a package please know that deliveries are typically made until 8PM in your recipient’s timezone. You will receive a confirmation email or text message when your gift has been delivered. Please reach out about a missing delivery if the window has passed and the recipient has checked where contactless deliveries are typically dropped off.</p>'
   + '<p>As a consequence of the global pandemic and its impact on supply, local florists may need to make design substitutions to ensure your gift is totally fresh and delivered in a timely manner. Whenever a substitution is made, the utmost care and attention is given so your gift is of equal value.</p>'
   + '<p>In order to ensure freshness, your flowers may arrive in bud form. Rest assured, with a little time and TLC, they will be in full bloom in a couple of days. For the best results, stems should be re-cut and placed in clean water along with the food provided.</p>'
   + '<p>If you or your recipient are still not satisfied with the quality of the flowers, please get in touch by clicking "Chat with us" below. Or you can find answers to most questions at the <a href="/customer-service">Customer Service Hub</a>.</p>',
};
export default data;
