/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import SkeletonStyles from './SkeletonStyles';

const styles = () => ({
    // Import Skeleton layout
    ...SkeletonStyles,
    container: {
        maxWidth: '1400px',
        margin: '0 auto',
    },
    topBanner: {
        minHeight: 300,
    },
    customerOption: {
        margin: '30px auto',
        maxWidth: '80%',
    },
    customerOptionInner: {
        width: '32%',
        minHeight: 150,
        display: 'inline-block',
        margin: 5,
    },
    title: {
        width: 230,
        minHeight: 30,
    },
    tabs: {
        width: '100%',
        minHeight: 150,
        margin: '10px 0',
    },
    bottom: {
        width: '100%',
        minHeight: 70,
    },
});

const CustomerServiceSkeleton = ({ classes }) => (
    <div>
        <div className={`${classes.container}`}>
            <div className={`${classes.topBanner} ${classes.textFW} ${classes.shimmer}`} />
            <div className={classes.customerOption}>
                <div className={`${classes.customerOptionInner} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.customerOptionInner} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.customerOptionInner} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.customerOptionInner} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.customerOptionInner} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.customerOptionInner} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.title} ${classes.textFW} ${classes.shimmer}`} />
                <div className={`${classes.tabs} ${classes.textFW} ${classes.shimmer}`} />
            </div>
        </div>
        <div className={`${classes.bottom} ${classes.textFW} ${classes.shimmer}`} />
    </div>
);

CustomerServiceSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(CustomerServiceSkeleton);
