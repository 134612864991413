/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'My Order is late or missing',
    orderDateDescription: 'If you have a late or missing order, we can help. If the delivery window has passed and your recipient has checked where contactless packages are typically dropped off, follow these steps and we’ll get you back on track.',
    steps: [
        {
            title: '<b>Step 1:</b> Start by finding your',
            deliveryInfo: [],
            link: 'gift&apos;s current status here.',
        },
        {
            title: '<b>Step 2:</b> Make sure to read this:',
            deliveryInfo: [
                {
                    title: 'Deliveries may be made on a flexible schedule as a result of high demand and the impacts of COVID-19 on staffing and supply. Read more about what to expect during the health crisis',
                    link: '/customer-service-faq-covid',
                },
                {
                    title: 'Deliveries are typically made until 8PM in your recipient&apos;s timezone, but due to higher than usual demand, they can arrive later. You will receive a confirmation email when your gift has been delivered. Read more about deliveries.',
                    link: '/customer-service-faq?scrollTo=shippingAndDelivery',
                },
                {
                    title: 'If your order was delivered but your recipient doesn’t see it, please ask them to check the following locations: front porch, front desk, package room, building lobby, or wherever contactless packages can be safely delivered.',
                },
            ],
        },
        {
            title: '<b>Step 3:</b> If you still have questions about your order,',
            deliveryInfo: [],
            link: 'chat with us.',
        },
    ],
    orderOptions: [
        {
            title: 'Related questions and videos',
            option: [
                {
                    title: 'Shipping and delivery',
                    link: '/customer-service-faq?scrollTo=shippingAndDelivery',
                },
                {
                    title: 'What to expect when flowers arrive',
                    link: '/customer-service-help-videos',
                },
            ],
        },
    ],
};
export default data;
