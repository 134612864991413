/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';

const styles = (theme) => ({
    label: {
        margin: '10px 0  20px 0',
        textAlign: 'center',
        fontSize: 13,
        color: theme.palette.sympathyPage?.linkColor,
        fontWeight: 600,
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    img: {
        margin: '0 auto',
        '& img': {
            width: '100%',
        },
    },
    padding: {
        padding: '0 25px',
    },
});

const FlowersColors = ({ data, classes }) => {
    const { link, img, label } = data;
    return (
        <Grid className={classes.padding} key={link} item xs={4}>
            <Link className={classes.link} to={link}>
                <div className={classes.img}>
                    <img src={`${img}?height=130&width=160`} alt={label} />
                </div>
                <h3 className={classes.label}>{ReactHtmlParser(label)} &gt;</h3>
            </Link>
        </Grid>
    );
};
FlowersColors.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
};

export default withStyles(styles)(FlowersColors);
