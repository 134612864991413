/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const styles = (theme) => {
    const { palette: { common }, breakpoints: { minWidth, maxWidth } } = theme;

    return ({
        veteransAdvantageWrapper: {
            '&.veteransAdvantageContainer': {
                '&.veteransAdvantageHolder': {
                    position: 'relative',
                    [theme.breakpoints.up(minWidth?.medium_screen_up)]: {
                        paddingTop: '50px',
                        '& img.dHero': {
                            height: '392px !important',
                            width: '100%',
                        },
                        '& img.mHero': {
                            display: 'none',
                        },
                    },
                    [theme.breakpoints.down(maxWidth?.small_medium_down)]: {
                        '& img.mHero': {
                            width: '100%',
                            height: '371px',
                        },
                        '& img.dHero': {
                            display: 'none',
                        },
                    },
                    '& .container': {
                        paddingLeft: '15px !important',
                        paddingRight: '15px !important',
                        [theme.breakpoints.down(maxWidth?.big_screen_wiki_tiles)]: {
                            maxWidth: '960px',
                        },
                        [theme.breakpoints.down(maxWidth?.small_medium_down)]: {
                            maxWidth: '720px',
                        },
                        [theme.breakpoints.down(maxWidth?.small_screen_up)]: {
                            maxWidth: '540px',
                        },
                        [theme.breakpoints.down(maxWidth?.xs_large_screen_wiki_tiles)]: {
                            paddingLeft: '0 !important',
                            paddingRight: '0 !important',
                        },
                    },
                    '& .affiliate-div': {
                        position: 'absolute',
                        left: '7%',
                        top: '9.5%',
                        [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                            top: '11%',
                            left: '8%',
                        },
                        '& h2': {
                            fontFamily: 'Hind, sans-serif',
                            font: 'normal normal bold 16px/43px Hind',
                            letterSpacing: '1.92px',
                            color: '#000',
                            textTransform: 'uppercase',
                            display: 'block',
                            height: '31px',
                            marginBottom: '0',
                            marginTop: 0,
                            [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                                height: '26px',
                            },
                        },
                        '& h1': {
                            fontFamily: 'BebasNeue',
                            font: 'normal normal bold 42px/43px BebasNeue',
                            letterSpacing: '0px',
                            color: '#000',
                            display: 'block',
                            fontWeight: '400',
                            marginTop: '0',
                            marginBottom: '0.5rem',
                            [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                                fontFamily: 'BebasNeue',
                                font: 'normal normal bold 55px/48px BebasNeue',
                                fontWeight: '400',
                                width: '216px',
                                height: '151px',
                                display: 'inline-block',
                                marginTop: '17px',
                            },
                        },
                        '& p': {
                            width: '45%',
                            font: 'normal normal normal 18px/24px Hind',
                            color: '#000',
                            fontFamily: 'Hind, sans-serif',
                            marginTop: '11px',
                            '&:first-child': {
                                marginTop: 0,
                                marginBottom: 0,
                                display: 'block',
                                width: '100%',
                            },
                            [theme.breakpoints.down(maxWidth?.big_screen_wiki_tiles)]: {
                                width: '61%',
                            },
                            [theme.breakpoints.down(maxWidth?.small_medium_down)]: {
                                display: 'none',
                            },
                        },
                        '& div': {
                            fontFamily: 'Hind, sans-serif',
                            font: 'normal normal normal 18px/24px Hind',
                            letterSpacing: '0px',
                            color: '#000',
                            opacity: '1',
                            marginTop: '11px',
                        },
                    },
                    '& .box': {
                        marginTop: '4%',
                        width: '100%',
                        margin: '0 auto',
                        padding: '0 15px',
                        position: 'relative',
                        [theme.breakpoints.up(minWidth?.medium_screen_up)]: {
                            marginTop: '3rem !important',
                            maxWidth: '1270px',
                            '& .row': {
                                display: 'flex',
                                flexWrapper: 'wrap',
                                marginRight: '-15px',
                                marginLeft: '-15px',
                            },
                        },
                        [theme.breakpoints.between(minWidth?.small_screen_up, maxWidth?.medium_screen_up)]: {
                            marginTop: '2.7%',
                        },
                        [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                            marginTop: '3.7%',
                            position: 'relative',
                            width: '100%',
                            '& .boxWrapper': {
                                paddingTop: '2rem !important',
                                paddingRight: '15px',
                                paddingLeft: '15px',
                            },
                            '& .boxWrapper:nth-of-type(2)': {
                                marginTop: '1.9rem !important',
                                [theme.breakpoints.between(minWidth?.xxs_screen_up, maxWidth?.xs_large_sreen)]: {
                                    marginTop: '1rem !important',
                                    '& .description': {
                                        width: '310px',
                                        heigth: '45px',
                                        display: 'inline-block',
                                        marginBottom: '0 !important',
                                    },
                                },
                            },
                            '& .mobileHidden': {
                                display: 'none',
                            },
                        },
                        '& a': {
                            border: '1px solid #000',
                            opacity: '1',
                            padding: '8px 35px',
                            color: '#000',
                            clear: 'both',
                            lineHeight: '46px',
                            fontWeight: '600',
                            fontFamily: 'Hind, sans-serif',
                            font: 'normal normal bold 18px/32px Hind',
                            letterSpacing: '1.8px',
                            textTransform: 'uppercase',
                            width: '193px',
                            height: '51px',
                            position: 'relative',
                            bottom: '7px',
                            display: 'inline-block',
                            textDecoration: 'none',
                            [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                                bottom: '18px',
                            },
                        },
                        '& .description': {
                            fontFamily: 'Hind, sans-serif',
                            font: 'normal normal normal 18px/20px Hind',
                            letterSpacing: '0px',
                            color: '#000',
                            opacity: '1',
                            lineHeight: '1.5',
                            position: 'relative',
                            bottom: '12px',
                        },
                        '& .mobileDiv': {
                            display: 'none',
                            padding: '0 15px',
                            flex: '0 0 100%',
                            position: 'relative',
                            textAlign: 'center',
                            [theme.breakpoints.down(maxWidth?.small_medium_down)]: {
                                display: 'flex',
                                flexWrap: 'wrap',
                            },
                            '& .mobileDescription': {
                                fontFamily: 'Hind, sans-serif',
                                font: 'normal normal normal 18px/24px Hind',
                                letterSpacing: '0px',
                                color: '#000',
                                opacity: '1',
                                '& p': {
                                    marginTop: '0',
                                },
                                [theme.breakpoints.between(minWidth?.xs_Screen, maxWidth?.xs_large_screen)]: {
                                    width: '359px',
                                    height: '79px',
                                    display: 'inline-block',
                                    marginTop: '15px',
                                },
                                [theme.breakpoints.down(maxWidth?.xxs_screen)]: {
                                    display: 'inline',
                                },
                                [theme.breakpoints.down(maxWidth?.xs_screen)]: {
                                    display: 'inline',
                                    position: 'inherit',
                                    top: '15px',
                                },
                            },
                        },
                        '& .padding': {
                            [theme.breakpoints.up(minWidth?.medium_screen_up)]: {
                                padding: '0 15px',
                            },
                        },
                        '& .borderCenter': {
                            [theme.breakpoints.up(minWidth?.medium_screen_up)]: {
                                borderRight: '1px solid #000',
                                height: '240px',
                            },
                            [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                                '&:after': {
                                    content: '""',
                                    display: 'block',
                                    margin: '0 auto',
                                    width: '95%',
                                    paddingTop: '20px',
                                    borderBottom: `1px solid ${common?.gray_700}`,
                                    height: '33px',
                                    opacity: '1',
                                },
                            },
                            opacity: '1',
                        },
                        '& .discountBox': {
                            position: 'relative',
                            display: 'block',
                            margin: '0 auto',
                            textAlign: 'center',
                            '& h1': {
                                fontFamily: 'BebasNeue',
                                font: 'normal normal bold 100px/120px BebasNeue',
                                letterSpacing: '0px',
                                colors: '#000',
                                opacity: '1',
                                fontWeight: '400',
                                width: '310px',
                                height: '100px',
                                margin: '0 auto',
                                '& .conditionsApplyStar': {
                                    '&:after': {
                                        content: '*',
                                        fontFamily: 'Hind, sans-serif',
                                        font: 'normal normal normal 24px/24px Hind',
                                        letterSpacing: '0px',
                                        color: '#000',
                                        opacity: '1',
                                        position: 'relative',
                                        left: '-4px',
                                        bottom: 'bottom: 59px',
                                    },
                                },
                            },
                            '& p': {
                                fontFamily: 'Hind, sans-serif',
                                font: 'normal normal normal 18px/20px Hind',
                                letterSpacing: '0px',
                                color: '#000',
                                opacity: '1',
                                lineHeight: '1.5',
                                position: 'relative',
                                bottom: '12px',
                            },
                            '& a:hover': {
                                textDecoration: 'none',
                            },
                        },
                        '& .downArrow': {
                            marginTop: '1.8rem',
                            [theme.breakpoints.down(maxWidth?.xs_screen)]: {
                                marginTop: '1rem',
                            },
                        },
                    },
                    '& .bottomSpace': {
                        minHeight: '220px',
                        [theme.breakpoints.down(maxWidth?.medium_screen_up)]: {
                            minHeight: '82px',
                        },
                    },
                },
                '& .bottomSpace': {
                    height: '100% !important',
                    '&.dnone': {
                        display: 'none',
                    },
                    '& >div': {
                        zIndex: '9999',
                        position: 'fixed',
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'transparent',
                        '-webkit-backdrop-filter': 'brightness(0.5)',
                        backdropFilter: 'brightness(0.3)',
                        textAlign: 'center',
                        display: 'block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        '& >div': {
                            position: 'absolute !important',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'white',
                            width: '100% !important',
                            maxWidth: '375px',
                            '& .zoid-close': {
                                top: '2px',
                                color: 'black',
                                right: '1px',
                                width: '25px',
                                height: '25px',
                                display: 'block',
                                zIndex: '9',
                                position: 'absolute',
                                fontSize: '20px',
                                fontWeight: '500',
                                cursor: 'pointer',
                            },
                        },
                    },
                },
                '& .subscription-email': {
                    '& form': {
                        width: 'auto !important',
                    },
                },
            },
        },
    });
};

export default styles;
