/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    title: 'Contact an Agent',
    description: 'Let&apos;s get you squared away. Please fill out the form below and hit continue. You will receive a notification once the customer service agent assigned to you receives your request.',
    form_header: 'I have a question about my order',
};
export default data;
