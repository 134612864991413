/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import loadable from '@loadable/component';
import GraphqlSympathyContainer from '../app/components/GraphqlComponents/GraphqlSympathy/GraphqlSympathyContainer';
import SCIContainer from '../app/components/GraphqlComponents/SCI/SCIContainer';
import VeteransAdvantagePage from '../app/components/GraphqlComponents/VeteransAdvantagePage/VeteransAdvantagePage';
import CelebrationsRewardsPage from '../app/components/GraphqlComponents/CelebrationsRewardsPage/CelebrationsRewardsPage';
import OrchidsDeliveryPage from '../app/components/GraphqlComponents/OrchidsDeliveryPage/OrchidsDeliveryPage';
import MessageFromCeoPage from '../app/components/GraphqlComponents/MessageFromCeoPage/MessageFromCeoPage';
import CelebrationsMothersDayPage from '../app/components/GraphqlComponents/CelebrationsMothersDayPage/CelebrationsMothersDayPage';
import MothersDayArticleTrendingFlowersPage from '../app/components/GraphqlComponents/MothersDayArticleTrendingFlowersPage/MothersDayArticleTrendingFlowersPage';
import GiftForPetLoverMomPage from '../app/components/GraphqlComponents/GiftForPetLoverMomPage/GiftForPetLoverMomPage';
import CustomerServiceContainer from '../app/components/GraphqlComponents/CustomerService/CustomerServiceContainer';
import CustomerServiceHelpVideos from '../app/components/GraphqlComponents/CustomerServicePage/CustomerServiceHelpVideos/CustomerServiceHelpVideos';
import CustomerServiceFaqCovid from '../app/components/GraphqlComponents/CustomerServicePage/CustomerServiceFaqCovid/CustomerServiceFaqCovid';
import CustomerServiceConfirmPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceConfirmPage/CustomerServiceConfirmPage';
import CustomerServiceOrderPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceOrderPage/CustomerServiceOrderPage';
import CustomerServiceFaqPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceFaqPage/CustomerServiceFaqPage';
import CustomerServiceDifferentIssuePage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceDifferentIssuePage/CustomerServiceDifferentIssuePage';
import CustomerServiceCancelOrderPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceCancelOrderPage/CustomerServiceCancelOrderPage';
import CustomerServiceReschedulePage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceReschedulePage/CustomerServiceReschedulePage';
import CustomerServiceAgentPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceAgentPage/CustomerServiceAgentPage';
import CustomerServiceMyOrderPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceMyOrderPage/CustomerServiceMyOrderPage';
import CustomerServiceFlowersPage from '../app/components/GraphqlComponents/CustomerService/CustomerServiceFlowersPage/CustomerServiceFlowersPage';
import CitiPointsTermsOfUse from '../app/components/GraphqlComponents/CitiPointsTermsOfUse/CitiPointsTermsOfUse';

const BecomeAnAffiliate = loadable(() => import(/* webpackChunkName: "BecomeAnAffiliate" */ '../app/components/GraphqlComponents/BecomeAnAffiliate/BecomeAnAffiliate'));
const MothersDayArticleGiftIdeasPage = loadable(() => import(/* webpackChunkName: "MothersDayArticleGiftIdeasPage" */ '../app/components/GraphqlComponents/MothersDayArticleGiftIdeasPage/MothersDayArticleGiftIdeasPage'));

export const contentPageRoutes = [
    {
        path: '/Sympathy',
        Component: GraphqlSympathyContainer,
    },
    {
        path: '/fhdirect',
        Component: SCIContainer,
        // condition: page => page.search.includes('LocNumber'),
    },
    {
        path: '/we-salute',
        Component: VeteransAdvantagePage,
    },
    {
        path: '/celebrations-rewards',
        Component: CelebrationsRewardsPage,
    },
    {
        path: '/orchids-delivery',
        Component: OrchidsDeliveryPage,
    },
    {
        path: '/a-message-to-our-customers-400216497',
        Component: MessageFromCeoPage,
    },
    {
        path: '/articles/mday/mothers-day-gift-ideas',
        Component: MothersDayArticleGiftIdeasPage,
    },
    {
        path: '/celebrations-mothers-day-gift-ideas',
        Component: CelebrationsMothersDayPage,
    },
    {
        path: '/articles/mday/top-trending-mother-s-day-flowers',
        Component: MothersDayArticleTrendingFlowersPage,
    },
    {
        path: '/articles/mday/gifts-for-the-pet-lover-mom',
        Component: GiftForPetLoverMomPage,
    },
    {
        path: '/:lang([a-zA-Z]{2})/customer-service',
        Component: CustomerServiceContainer,
    },
    {
        path: '/customer-service',
        Component: CustomerServiceContainer,
    },
    {
        path: '/customer-service-help-videos',
        Component: CustomerServiceHelpVideos,
    },
    {
        path: '/customer-service-faq-covid',
        Component: CustomerServiceFaqCovid,
    },
    {
        path: '/customer-service-confirm',
        Component: CustomerServiceConfirmPage,
    },
    {
        path: '/customer-service-order',
        Component: CustomerServiceOrderPage,
    },
    {
        path: '/customer-service-faq',
        Component: CustomerServiceFaqPage,
    },
    {
        path: '/customer-service-different-issue',
        Component: CustomerServiceDifferentIssuePage,
    },
    {
        path: '/customer-service-cancel',
        Component: CustomerServiceCancelOrderPage,
    },
    {
        path: '/customer-service-reschedule',
        Component: CustomerServiceReschedulePage,
    },
    {
        path: '/customer-service-agent',
        Component: CustomerServiceAgentPage,
    },
    {
        path: '/customer-service-my-order',
        Component: CustomerServiceMyOrderPage,
    },
    {
        path: '/customer-service-flowers',
        Component: CustomerServiceFlowersPage,
    },
    {
        path: '/citi-points-terms-of-use',
        Component: CitiPointsTermsOfUse,
    },
    {
        path: '/affiliates',
        Component: BecomeAnAffiliate,
    },

];

export const isContentPageRoute = ({ page }) => contentPageRoutes.filter((route) => route.path === page.path).length;
export default {};
