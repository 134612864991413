/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import FlowersColors from '../blocks/FlowersColors';

const MobileSympathyFlowers = ({ classes, data }) => {
    const { rightSidebar: { hopeBanner, serviceBanner }, leftSidebar: { funeralFlowersColor } } = data;
    const seed = useUIDSeed();

    return (
        <Grid container className={`${classes.container} ${classes.marginTopAndBottom}`}>
            <Grid item xs={12}>
                <Grid container>
                    { funeralFlowersColor.map((block) => (
                        <FlowersColors data={block} key={seed(block)} />
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Link className={classes.hopeBanner} to={hopeBanner.link}>
                            <img src={`${hopeBanner.img}`} alt="Give Love. Inspire Hope" />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.serviceBlock}>
                <Grid item xs={12}>
                    <h3>{ReactHtmlParser(serviceBanner.heading)}</h3>
                </Grid>
                <Grid container>
                    <Grid item xs={8}>
                        <p>{ReactHtmlParser(serviceBanner.content)}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <img src={serviceBanner.img} alt="Customer Service" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MobileSympathyFlowers.propTypes = {
    classes: object.isRequired,
    data: shape({
        arrowImage: string,
        disclaimer: shape({
            copy: arrayOf(
                shape({
                    description: string,
                    heading: string,
                }),
            ),
            mainHeading: string,
            subCopy: arrayOf(
                shape({
                    description: string,
                    heading: string,
                }),
            ),
        }),
        leftSidebar: shape({
            advice: shape({
                img: string.isRequired,
                link: string.isRequired,
            }),
            funeralFlowers: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }),
            ),
            funeralFlowersColor: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
        rightSidebar: shape({
            hopeBanner: shape({
                img: string.isRequired,
                link: string.isRequired,
            }).isRequired,
            serviceBanner: shape({
                content: string.isRequired,
                heading: string.isRequired,
                img: string.isRequired,
            }),
            sympathyFlowers: arrayOf(
                shape({
                    img: string.isRequired,
                    label: string.isRequired,
                    link: string.isRequired,
                }),
            ),
        }).isRequired,
    }).isRequired,
};

export default MobileSympathyFlowers;
