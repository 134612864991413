/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import * as Joi from 'joi-browser';
import {
    string,
} from 'prop-types';
import axios from 'axios';
import Form from './Form';

class CustomerServiceFormBlock extends Form {
    state = {
        data: {
            orderNumber: '',
            fullName: '',
            recipientName: '',
            email: '',
            comments: '',
            recaptcha: '',
        },
        errors: {},
        replaceMask: {
            orderNumber: '*',
        },
        replaceMaskWith: {
            orderNumber: 'toUpperCase',
        },
    };

    schema = {
        orderNumber: Joi.string()
            .trim()
            .regex(/^(W00\d{12}|\d{10})$/i, 'order number format')
            .min(10)
            .max(15)
            .required()
            .label('Order Number'),
        fullName: Joi.string()
            .trim()
            .max(20)
            .required()
            .label('Your Full Name'),
        recipientName: Joi.string()
            .trim()
            .max(20)
            .required()
            .label(`Recipient${"'"}s Name`),
        email: Joi.string()
            .trim()
            .max(20)
            .required()
            .label('Your E-mail Address'),
        comments: Joi.string()
            .trim()
            .required()
            .label('Comments'),
        recaptcha: Joi.string()
            .required()
            .label('Recaptcha'),
    };

    doSubmit = (event) => {
        event.preventDefault();
        const {
            data: {
                orderNumber, fullName, recipientName, recaptcha, email, comments,
            },
        } = this.state;
        const form = {
            env_report: 'REMOTE_HOST,REMOTE_ADDR,HTTP_USER_AGENT,AUTH_TYPE,REMOTE_USER',
            recipients: 'orderissue@1800flowers.com',
            mail_options: 'Exclude=x;y;email;realname',
            subject: '18F Form Submission',
            replyto: email || '',
            good_url: '//pwa.www.1800flowers.com/customer-service-confirm',
            bad_url: '//pwa.www.1800flowers.com/customer-service-cancel',
            derive_fields: 'imgverify=g-recaptcha-response',
            'Full Name': fullName || '',
            'Customer Email': email || '',
            'Order Number': orderNumber || '',
            'Recipients Name': recipientName || '',
            Comments: comments || '',
            'g-recaptcha-response': recaptcha || '',
        };
        axios.post('https://blog.1800baskets.com/custservice/issue-formmail.php', form)
            .then((response) => {
                if (response) {
                    if (typeof window !== 'undefined') {
                        window.location.href = '/customer-service-confirm';
                    }
                }
            });
    };

    render() {
        const { commentLabel } = this.props;
        return (

            <form onSubmit={this.handleSubmit}>
                {this.renderInput({
                    name: 'orderNumber',
                    label: 'Order Number',
                    type: 'text',
                    pattern: '^[wW0-9]*$',
                    length: 15,
                })}
                {this.renderInput({
                    name: 'recipientName',
                    label: `Recipient${"'"}s Name`,
                    type: 'text',
                })}
                {this.renderInput({
                    name: 'fullName',
                    label: 'Your Full Name',
                    type: 'text',
                })}
                {this.renderInput({
                    name: 'email',
                    label: 'Your E-mail Address',
                    type: 'email',
                })}
                {this.renderInput({
                    name: 'comments',
                    label: commentLabel,
                    type: 'text',
                    multiline: true,
                })}
                <div className="captchaContainer">
                    {this.renderRecaptcha('recaptcha')}
                </div>
                <div className="margin-top-2x opacity1">
                    {this.renderButton({
                        label: 'Continue',
                        className: 'submitBtn',
                        type: 'submit',
                        isValidated: false,
                    })}
                </div>
            </form>
        );
    }
}
CustomerServiceFormBlock.propTypes = {
    commentLabel: string.isRequired,
};
export default (CustomerServiceFormBlock);
