/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string } from 'prop-types';

const SnipeRender = ({
    styleProp, src, altText, fallbackSrc,
}) => (
    <img
        className={styleProp}
        src={src}
        alt={altText}
        onError={(e) => {
            if (e.target.src.indexOf(fallbackSrc) < 0) { // to avoid infinite if both Url invalid
                e.target.src = fallbackSrc;
            }
        }}
    />
);

SnipeRender.propTypes = {
    styleProp: string,
    src: string.isRequired,
    fallbackSrc: string.isRequired,
    altText: string,
};

SnipeRender.defaultProps = {
    styleProp: '',
    altText: 'snipe',
};

export default SnipeRender;
