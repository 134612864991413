/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

/* eslint-disable react/jsx-props-no-spreading */
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    successBackColor: {
        backgroundColor: '#87AA35',
    },
    errorBackColor: {
        backgroundColor: '#dc3545',
    },
}));

const CustomizedSnackbar = ({
    message, severity, setToastError, alertType = 'nonDeliverable',
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastError(false);
        setOpen(false);
    };

    useEffect(() => {
        if (open && alertType === 'nonDeliverable') {
            setTimeout(() => {
                setToastError(false);
            }, 6000);
        }
    }, [open]);

    return (
        <div className={classes.root}>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={() => handleClose()}>
                <Alert onClose={() => handleClose()} severity={severity || 'sucess'} className={severity === 'success' ? classes.successBackColor : classes.errorBackColor}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

CustomizedSnackbar.propTypes = {
    message: string.isRequired,
    severity: string.isRequired,
    setToastError: func.isRequired,
    alertType: string,
};
CustomizedSnackbar.defaultProps = {
    alertType: 'nonDeliverable',
};

export default CustomizedSnackbar;
