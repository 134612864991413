/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { withRouter } from 'react-router';
import mbpLogger from 'mbp-logger';
import {
    array, bool,
    object, string, func,
} from 'prop-types';
import jwtDecode from 'jwt-decode';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { getContactId, getAccessToken } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getUsersProductIdFromWishlist } from '../../../../state/ducks/App/App-Selectors';
import { addAndRemoveProductAPI } from '../../../../apis/wishlist-apis/wishlist';
import { getUsersWishlist } from '../../../../state/ducks/App/App-Actions';
import { useAuth } from '../../../../state/ducks/Member/ducks/Auth/Plugin/Auth';
import CustomizedSnackbar from '../GraphqlProductPage/GraphqlPlantsProductPageBuilder/Partials/CustomizedSnackbar';

const styles = () => ({
    wishListIcon: {
        cursor: 'pointer',
        color: '#fff',
        width: '29px',
        height: '29px',
        position: 'absolute!important',
        top: '15px',
        right: '30px',
    },
});
const Favorites = ({
    classes, userId, getWishlistProduct, partNumber, getWishlist, trackEvent, jwt, hover, showError,
}) => {
    const favIcon = !!(getWishlistProduct.length && getWishlistProduct.indexOf(partNumber) > -1);
    const [inWishlist, setInWishlist] = useState(favIcon);
    const [error, setError] = useState();
    const { loginByRedirect } = useAuth();
    const [toastError, setToastError] = useState(false);

    const callWishlistApi = async (data) => {
        let isAddAndRemove = false;
        if (!jwt || !userId) {
            trackEvent({
                eventName: 'feature_interaction', eventCategory: 'Sign In', eventAction: 'Login', eventLabel: 'Logon_From_Normal|<<pageType>>',
            });
            const result = await loginByRedirect();
            isAddAndRemove = result;
        } else {
            isAddAndRemove = true;
        }
        if (isAddAndRemove) {
            const jwtToken = jwtDecode(jwt);
            const memberId = ((jwtToken.sub).split('|')[1]).trim();

            const header = {
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${jwt}`,
                },
            };
            const payload = { ...data, userId: memberId };
            const apiResponse = await addAndRemoveProductAPI(payload, header);
            if (apiResponse?.status === 201) {
                setInWishlist(true);
                getWishlist({ userId: payload.userId });
                setError({ type: 'success', message: 'Product successfully added to Wishlist!' });
                setToastError(true);
            } else if (apiResponse?.status === 204) {
                setInWishlist(false);
                getWishlist({ userId: payload.userId });
                setError({ type: 'error', message: 'Product removed from your Wishlist!' });
                setToastError(true);
            } else {
                setError({ type: 'error', message: 'Something Went Wrong!' });
                mbpLogger.logError({ message: 'error in wishlist API' });
                setToastError(true);
            }
        }
    };
    const addAndRemoveProductFromWishlist = (data) => {
        callWishlistApi(data);
        trackEvent({
            eventName: 'feature_interaction', eventCategory: '<<pageType>>', eventAction: 'Favorite', eventLabel: 'Favorite Click|<<pageType>>',
        });
    };
    let button;
    if (inWishlist) {
        button = <FavoriteIcon className={classes.wishListIcon} onClick={(e) => { e.preventDefault(); addAndRemoveProductFromWishlist({ productId: partNumber, isDelete: true }); }} />;
    } else if (hover) {
        button = <FavoriteBorderIcon className={classes.wishListIcon} onClick={(e) => { e.preventDefault(); addAndRemoveProductFromWishlist({ productId: partNumber, isDelete: false }); }} />;
    } else {
        button = '';
    }
    return (
        <div>
            {button}
            {error && toastError && showError && <CustomizedSnackbar severity={error.type} message={error.message} setToastError={setToastError} />}
        </div>
    );
};

Favorites.propTypes = {
    classes: object.isRequired,
    userId: string.isRequired,
    partNumber: string.isRequired,
    getWishlistProduct: array.isRequired,
    getWishlist: func.isRequired,
    trackEvent: func.isRequired,
    jwt: string.isRequired,
    hover: bool,
    showError: bool,
};
Favorites.defaultProps = {
    showError: true,
    hover: true,
};
const mapStateToProps = (state) => ({
    userId: getContactId(state),
    getWishlistProduct: getUsersProductIdFromWishlist(state),
    jwt: getAccessToken(state),

});
const mapDispatchToProps = (dispatch) => ({
    getWishlist: bindActionCreators(getUsersWishlist, dispatch),
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});
const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Favorites);
