/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme)  => ({
    contentInfo: {
        margin: '20px auto',
        color: theme.palette.black,
        maxWidth: '1024px',
        marginBottom: '45px',
        '& h1': {
            textAlign: 'center',
        },
        '& p': {
            fontSize: '16px',
            margin: '10px 0px',
            textAlign: 'justify',
        },
        '& ul': {
            textAlign: 'justify',
        },
    },
});
const CitiPointsTermsOfUse = ({
    classes,
}) => (
    <div className={classes.contentInfo}>
        <h1>THANKYOU CITI REWARDS TERMS OF USE</h1>
        <p><strong>TERMS AND CONDITIONS (in partner redemption flow) Pay With Points.</strong> You can redeem available ThankYou Points (“Points”) for a statement credit to your eligible consumer or small business Citi® credit card (“Card Account”) that participates in ThankYou Rewards (the “Program”) when making purchases at participating Pay with Points merchants’ websites or mobile applications. In order to use Pay with Points, you must be a member of the Program with a ThankYou member account (“ThankYou Account”) and Card Account in good standing.</p>
        <ul>
            <li>When you choose to view your Points balance or use your Points at checkout on a website or mobile application of a participating merchant, you agree Citibank, N.A. or its affiliate (“Citi”) may share your available Points balance with the participating merchant. If you do not want to have access to use Pay with Points when using your Card Account on the websites or mobile applications of all or any of the participating merchants, please call the ThankYou Service Center at 1-800-ThankYou (1-800-842-6596).</li>
            <li>You can use Pay with Points when making a purchase at the participating merchant’s website or mobile application by following the merchant’s instructions at checkout.</li>
            <li>
                When you use Pay with Points the following will occur:
                <ul>
                    <li>Your Card Account will be charged for the full amount of the purchase.</li>
                    <li>The amount of Points you choose to use will be redeemed for a corresponding statement credit to your Card Account to cover all or part of your purchase. </li>
                    <li>The amount of Points you chose to redeem for a statement credit will be deducted from your ThankYou account Points balance at the time of purchase.</li>
                    <li>Pay with Points statement credits will post to your Card Account within 24-48 hours of redemption.</li>
                </ul>
            </li>
            <li>The purchase(s) will be subject to interest, as are any other purchase(s) on your Card Account.</li>
            <li>If there are no Points available for redemption at time of purchase, a statement credit will not be issued.</li>
            <li>Pay with Points statement credits are applied toward your Card Account statement balance, and not toward your minimum payment due. The required minimum payment reflected on your Card Account statement must be paid pursuant to the terms of your Card Agreement.</li>
            <li>The purchase and corresponding statement credit may appear in different statement cycles.</li>
            <li>If a statement credit is not issued or is delayed, and a purchase made using Pay with Points incurs any interest or fees, you agree that Citi. has no liability to you.</li>
            <li>If you return a purchase made using Pay with Points, any associated statement credit will remain on your Card Account and your Points will not be returned.</li>
            <li>Citi may set minimum and/or maximum Points redemption requirements for Pay with Points at any time.</li>
            <li>Citi has the right to change the Points to dollar conversion rate for Pay with Points statement credits at any time.</li>
            <li>Citi may modify or terminate Pay with Points at any time without notice.</li>
        </ul>
    </div>
);

CitiPointsTermsOfUse.propTypes = {
    classes: object.isRequired,
};

export default  withStyles(styles)(CitiPointsTermsOfUse);
