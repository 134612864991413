/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import { useUIDSeed } from 'react-uid';
import { object } from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import Data from './ViewData';
import ContactUsBannerContainer from '../../CustomerService/ContactUsBanner/ContactUsBannerContainer';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
    },
    dialogMain: {
        '& div': {
            '& div': {
                borderRadius: 'initial',
                maxWidth: '960px',
                padding: '7px 17px 11px',
            },
        },
    },
    closeIcon: {
        top: 8,
        right: 5,
        cursor: 'pointer',
        position: 'absolute',
        fontSize: 24,
        color: theme.palette.contentColor,
    },

    serviceBlock: {
        width: '55%',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('md')]: {
            width: '85%',
        },
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '55%',
        },
    },
    header: {
        display: 'block',
        textAlign: 'center',
        '& h1': {
            fontSize: 30,
            margin: '15px 0',
        },
        '& span': {
            color: theme.palette.contentColor,
            fontSize: 17,
        },
    },
    content: {
        color: theme.palette.colorIconHeader,
        width: '45%',
        border: `1px solid ${theme.palette.contentColor}`,
        margin: 16,
        flexBasis: '45%',
        borderRadius: 2,
        textDecoration: 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '37%',
            flexBasis: '45%',
            margin: '16 auto',
        },
        '& p': {
            padding: 16,
            textAlign: 'center',
            borderTop: `1px solid ${theme.palette.contentColor}`,
            fontWeight: 700,
            margin: 0,
            fontSize: 14,
        },
    },
    imageBlock: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
        },
    },
});

const CustomerServiceHelpVideos = ({ classes, page, brand }) => {
    const seed = useUIDSeed();
    const { title, subTitle, list } = Data;
    const [dialogData, openClick] = useState({
        trigger: false,
        data: {},
    });
    const { trigger, data } = dialogData;
    const closeClick = () => {
        openClick({
            trigger: false,
            data: {},
        });
    };
    return (
        <div className={classes.container}>

            <div className={classes.header}><h1>{title}</h1><span>{subTitle}</span></div>
            <div className={classes.serviceBlock}>
                {list.map((item) => (
                    <div
                        key={seed(item)}
                        className={classes.content}
                        onClick={() => openClick({
                            trigger: true,
                            data: item,
                        })}
                        role="presentation"
                    >
                        <div className={classes.imageBlock}>
                            <img src={item.imageLink} alt={item.name} />
                        </div>
                        <p>{ReactHTMLParser(item.name)}</p>

                    </div>
                ))}
            </div>
            <Dialog className={classes.dialogMain} open={trigger} aria-labelledby="form-dialog-title">
                <DialogTitle className={classes.title} id="dialog-title">
                    <span role="presentation" onClick={() => { closeClick(); }}>
                        <CloseIcon className={classes.closeIcon} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        frameBorder="0"
                        width="560"
                        height="315"
                        scrolling="no"
                        className={classes.iFrame}
                        title={data?.name}
                        src={data?.videoLink}
                    />
                </DialogContent>
            </Dialog>
            <ContactUsBannerContainer page={page} brand={brand} />
        </div>
    );
};

CustomerServiceHelpVideos.propTypes = {
    classes: object.isRequired,
    page: object.isRequired,
    brand: object.isRequired,
};
export default withStyles(styles)(CustomerServiceHelpVideos);
