/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import {
    object, func, string, arrayOf, shape,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortAndFilterFacets from './SortAndFilterFacets';
import { getDataDictionary } from '../../../../../../../state/ducks/App/App-Selectors';

const styles = (theme) => ({
    sortAndFilterContainer: {
        marginBottom: '10px',
    },
    filterBtn: {
        whiteSpace: 'nowrap',
        border: `1px solid ${theme.palette.grey9}`,
        padding: '8px 0px 8px 8px',
        display: 'inline-block',
        cursor: 'pointer',
        color: theme.palette.colorPrimary,
        background: theme.palette.colorNeutral20,
        fontWeight: '700',
        fontSize: '14px',
    },
    filterBtnMobile: {
        border: `1px solid ${theme.palette.grey9}`,
        padding: '8px 0px 8px 8px',
        display: 'inline-block',
        cursor: 'pointer',
        color: theme.palette.colorPrimary,
        background: theme.palette.colorNeutral20,
        fontWeight: '700',
        fontSize: '14px',
    },
    filterContainerDialog: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 3,
        maxWidth: '400px',
        width: '400px',
        padding: '10px',
        boxShadow: '0px 2px 3px -1px rgba(0,0,0,0.35)',
        background: theme.palette.colorPrimaryContrast,
        [theme.breakpoints.down(600)]: {
            maxWidth: '100%',
            width: '100%',
            top: 'auto',
        },
    },
    filterContainerDialogMobile: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 3,
        padding: '10px',
        boxShadow: '0px 2px 3px -1px rgba(0,0,0,0.35)',
        background: theme.palette.colorPrimaryContrast,
    },
    filterTitle: {
        color: theme.palette.colorNeutral70,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
        margin: '16px 0',
    },
    filterOptions: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterSerachBtn: {
        padding: '13px 25px',
        borderRadius: '0px',
        color: theme.palette.colorNeutral70,
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            background: theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
        '&:active': {
            background: theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '8px 10px',
        },
    },
    textRight: {
        textAlign: 'right',
    },
    content: {
        padding: '15px 0px',
        fontSize: '14px',
        fontWeight: 700,
        color: theme.palette.colorNeutral70,
    },
    applyBtn: {
        color: theme.palette.colorPrimary,
        width: '100%',
        border: '2px solid',
        display: 'block',
        padding: '8px 0px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    btnBackground: {
        background: theme.palette.colorPrimary,
        color: theme.palette.colorPrimaryContrast,
        '&:hover': {
            background: theme.palette.colorPrimary,
            color: theme.palette.colorPrimaryContrast,
        },
    },
    switch: {
        '& span': {
            '& span': {
                color: theme.palette.colorPrimary,
            },
        },
    },
    filterModelBackground: {
        height: '100vh',
        zIndex: 1,
        position: 'fixed',
        background: theme.palette.common.black,
        top: 0,
        right: 0,
        width: '100%',
        opacity: 0.5,
    },
    ArrowDropdownIcon: {
        color: theme.palette.colorPrimary,
        marginTop: '-8px',
        marginBottom: '-8px',
        marginLeft: '-8px',
    },
});

class MobileFilterDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggleFilter: false,
            sellingBtn: true,
            highToLowBtn: false,
            lowToHighBtn: false,
            localFlorist: true,
            giftBox: true,
        };
        this.containerRef = createRef();
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside = (event) => {
        if (this.containerRef && event?.target && this.containerRef?.current && !this.containerRef.current.contains(event.target)) {
            this.setState({
                toggleFilter: false,
            });
        }
    }

    toggleFilter = () => {
        const { toggleFilter } = this.state;
        this.setState({
            toggleFilter: !toggleFilter,
        });
    };

    /*
     * @description Applies the sort values obtained from the filter component and sets the data to category builder
     * @param {string} first value
     * @return null
     */
    applyFilter = (filter) => {
        const filterState = {};
        switch (filter) {
            case 'sellingBtn':
                filterState.sellingBtn = true;
                filterState.highToLowBtn = false;
                filterState.lowToHighBtn = false;
                break;
            case 'highToLowBtn':
                filterState.sellingBtn = false;
                filterState.highToLowBtn = true;
                filterState.lowToHighBtn = false;
                break;
            case 'lowToHighBtn':
                filterState.sellingBtn = false;
                filterState.highToLowBtn = false;
                filterState.lowToHighBtn = true;
                break;
            default:
                return null;
        }
        this.setState({
            sellingBtn: filterState.sellingBtn,
            highToLowBtn: filterState.highToLowBtn,
            lowToHighBtn: filterState.lowToHighBtn,
        });
        const { setFilterOptions } = this.props;
        return setFilterOptions(filterState);
    };

    applyAndToggleFilter = () => {
        const { setFilterOptions } = this.props;
        setFilterOptions(this.state);
        const {
            toggleFilter, giftBox, highToLowBtn, sellingBtn, localFlorist, lowToHighBtn,
        } = this.state;
        this.setState({
            sellingBtn,
            highToLowBtn,
            lowToHighBtn,
            localFlorist,
            giftBox,
            toggleFilter: !toggleFilter,
        });
    }

    handleChangeLocalFlorist = () => {
        const { localFlorist } = this.state;
        this.setState({
            localFlorist: !localFlorist,
        });
    };

    handleChangeGiftBox = () => {
        const { giftBox } = this.state;
        this.setState({
            giftBox: !giftBox,
        });
    };

    /*
     * @description sets the local filter state based on input, does not work if passed to children
     * @param {string} first value
     * @return null
     */
    setFilterData = (filter) => {
        switch (filter) {
            case 'sellingBtn':
                this.setState({
                    sellingBtn: true,
                    highToLowBtn: false,
                    lowToHighBtn: false,
                }, () => { this.applyAndToggleFilter(); });
                break;
            case 'highToLowBtn':
                this.setState({
                    sellingBtn: false,
                    highToLowBtn: true,
                    lowToHighBtn: false,
                }, () => { this.applyAndToggleFilter(); });
                break;
            case 'lowToHighBtn':
                this.setState({
                    sellingBtn: false,
                    highToLowBtn: false,
                    lowToHighBtn: true,
                }, () => { this.applyAndToggleFilter(); });
                break;
            default:
                return null;
        }
        return Promise;
    }

    render() {
        const {
            classes, presentation_family, productFacets, setFacetsOptions, dataDictionary, language,
        } = this.props;
        const {
            toggleFilter, sellingBtn, highToLowBtn, lowToHighBtn,
        } = this.state;

        const sortBy = 'Sort By';
        const sortProductsBy = 'Sort Products by';
        const bestSelling = 'Best Selling';
        const highToLow = 'High - Low';
        const lowToHigh = 'Low - High';

        return (presentation_family !== 'food' ? (
            <div
                ref={this.containerRef}
                className={classes.sortAndFilterContainer}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <span role="presentation" onClick={() => this.toggleFilter()} className={classes.filterBtn}>{ language === '/en' ? sortBy : dataDictionary[sortBy]} <ArrowDropDownIcon className={classes.ArrowDropdownIcon} /></span>
                    </Grid>
                </Grid>
                {
                    toggleFilter
                        ? (
                            <>
                                <Grid container className={classes.filterContainerDialog}>
                                    <Grid item xs={12}>
                                        <div className={classes.filterTitle}>{language === '/en' ? `${sortProductsBy}:` : `${dataDictionary[sortProductsBy]}:` }</div>
                                        <div className={classes.filterOptions}>
                                            <Button onClick={() => this.setFilterData('sellingBtn')} className={sellingBtn ? `${classes.filterSerachBtn} ${classes.btnBackground}` : classes.filterSerachBtn}>{ language === '/en' ? bestSelling : dataDictionary[bestSelling]}</Button>
                                            <Button onClick={() => this.setFilterData('highToLowBtn')} className={highToLowBtn ? `${classes.filterSerachBtn} ${classes.btnBackground}` : classes.filterSerachBtn}>{ language === '/en' ? highToLow : dataDictionary[highToLow]}</Button>
                                            <Button onClick={() => this.setFilterData('lowToHighBtn')} className={lowToHighBtn ? `${classes.filterSerachBtn} ${classes.btnBackground}` : classes.filterSerachBtn}>{ language === '/en' ? lowToHigh : dataDictionary[lowToHigh]}</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        )
                        : ''
                }
            </div>
        ) : (
            <SortAndFilterFacets
                toggleFilter={toggleFilter}
                toggleFilterFunc={this.toggleFilter}
                setFilterData={this.setFilterData}
                applyFilter={this.applyFilter}
                productFacets={productFacets}
                setFacetsOptions={setFacetsOptions}
            />
        ));
    }
}

MobileFilterDialog.propTypes = {
    classes: object.isRequired,
    setFilterOptions: func.isRequired,
    setFacetsOptions: func.isRequired,
    presentation_family: string.isRequired,
    productFacets: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    dataDictionary: object.isRequired,
    language: string.isRequired,
};

const mapStateToProps = (state) => ({
    dataDictionary: getDataDictionary(state),
});

export default connect(mapStateToProps)(withStyles(styles)(MobileFilterDialog));
