/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */
const data = {
    banner_title: 'Orchids Flower Delivery',
    banner_description: 'Learn secrets about orchids while you shop.',

    top_image_banner: {
        image_url: '//cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchid-holds-special-place-IMOC.jpg',
        content: '<p>The orchid holds a special place as one of the<br>'
        + 'most alluring and captivating flowers. They have<br>'
        + 'been known to symbolize luxury, love and beauty.</p>',
        popup_data: {
            pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchid-holds-special-place-IMOC-popup.jpg',
            pop_description: '<p class="description">The orchid holds a special place as one of the most alluring and captivating flowers. They have been known to symbolize luxury, love and beauty. <br/><br/>'
        + 'The mystique surrounding orchids throughout history has positioned them at the top of luxurious gift lists, making them an exquisite choice as a thank you gift, congratulations gift or to simply say I’m thinking of you. Their charm delights those who receives them, for few other flowers have the ability to impress their recipient in the same way that orchids do.<br/><br/>'
       + ' These affordable blooming machines make meaningful “living gifts” that last beyond any holiday or occasion. They’re great for today’s budget-conscious shopper and they keep blooming for months with little to no effort. Plus, with a variety of show-stopping colors and shapes, orchids add beauty and style to any space, making them a perfect gift for new home owners.</p>',
        },
        top_banner_alt_text: 'Top Banner',
    },
    section_one: [
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/146802mdcv1x.jpg?height=378&width=345',
            product_link: '/farm-fresh-orchids-146802',
            product_name: 'Exotic Breeze™ Orchids',
            product_price: '$44.99 - $83.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',
        },
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchids-are-excotic-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchids Are Exotic</p>',
            product_desc: '<p>Coming from faraway places<br>such as Hawaii and South<br> America, orchids are tropical<br>plants by nature...</p>',
            product_popup_data: {
                pop_header: '<p>Orchids Are Exotic</p>',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchids-are-excotic-IC-banner-popup.jpg',
                pop_description: '<p class="description">Coming from faraway places such as Hawaii and South America, orchids are tropical plants by nature. In traditional Chinese medicine, the orchid was used to help cure coughs and lung illnesses. The Aztecs were known to drink a mixture of vanilla, orchid flowers and chocolate to give them strength. Their exotic look sets them apart from more traditional flowers and sends a special message to any loved one.</p>',
            },
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/140953mdcv1x.jpg?height=378&width=345',
            product_link: '/farm-fresh-dyed-dendrobium-orchids-140953',
            product_name: 'Ocean Breeze Orchids',
            product_price: '$44.99 - $83.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',

        },
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchid-hunting-in-victorian-times-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchid Hunting In<br> Victorian Times<br> Was No Joke</p>',
            product_desc: '<p>In Victorian times people were<br>struck with Orchidelirium,<br>as orchid collecting reached<br>its peak.</p>',
            product_popup_data: {
                pop_header: 'Orchid Hunting In Victorian Times Was No Joke',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchid-hunting-in-victorian-times-IC-banner-popup.jpg?height=571&width=440',
                pop_description: '<p>In Victorian times people were struck with Orchidelirium, as orchid collecting reached its peak. Wealthy fanatics dispatched explorers to exotic locations in search of new orchid colors, shapes and textures. Victorians were so mystified by the orchid’s irresistible beauty that they became a status symbol and were displayed like treasures in the home.</p>',
            },
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/101828lx.jpg?height=378&width=345',
            product_link: '/blue-orchid-101828',
            product_name: 'Beautiful Blue Phalaenopsis Orchid',
            product_price: '$69.99 - $89.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',

        },
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchids-are-one-of-the-most-popular-houseplants-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchids Are One Of<br> The Most Popular<br> Houseplants</p>',
            product_desc: '<p>Orchids are still popular today!<br>They are rewarding indoor<br>plants to grow because their<br>flowers last a long time.</p>',
            product_popup_data: {
                pop_header: 'Orchids Are One Of The Most Popular Houseplants',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchids-are-one-of-the-most-popular-houseplants-IC-banner-popup.jpg?height=571&width=440',
                pop_description: '<p>Orchids are still popular today! They are rewarding indoor plants to grow because their flowers last a long time. Plus, after you learn a few care tricks, they are reblooming machines. Once a plant-lover has bought or been gifted their first orchid, a collector is usually born.</p>',
            },
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/18436puncx.jpg?height=378&width=345',
            product_link: '/elegant-orchid-18436',
            product_name: 'Elegant Orchid',
            product_price: '$49.99 - $64.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',

        },
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchids-are-used-in-feng-shui-decor-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchids Are Used In<br> Feng Shui Décor</p>',
            product_desc: '<p>The orchid is one of the<br>celebrated four noble plants<br>in Chinese culture.</p>',
            product_popup_data: {
                pop_header: 'Orchids Are Used In Feng Shui Décor',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchids-are-used-in-feng-shui-decor-IC-banner-popup.jpg?height=571&width=440',
                pop_description: '<p>The orchid is one of the celebrated four noble plants in Chinese culture. In Feng Shui, the orchid is used to enhance family and fertility. The color of the orchid plays a vital role in determining its potency. Pink can help mend or maintain harmonious relationships while white brings harmony to the household by filling the space with calm, clarity, innocence, and peace. When placed in the relationship corner of the house it can help singles get hitched by drawing romance luck. No matter what you believe, orchids are fun plants to add to indoor décor.</p>',
            },
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/101695lx.jpg?height=378&width=345',
            product_link: '/phalaenopsis-orchid-plant-101695',
            product_name: 'Asian Dance Orchid',
            product_price: '$59.99 - $109.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',

        },
    ],
    made_me_smile: {
        made_me_smile_title: '#MADEMESMILE',
        made_me_smile_content: [
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image1.jpg?height=281&width=267',
                username: '@captainnlex',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image2.jpg?height=281&width=267',
                username: '@m.carroll',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image3.jpg?height=281&width=267',
                username: '@joannamhui',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image4.jpg?height=281&width=267',
                username: '@t.tomczyk',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image5.jpg?height=281&width=267',
                username: '@kitkat32887',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image6.jpg?height=281&width=267',
                username: '@j.tkachuk',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image7.jpg?height=281&width=267',
                username: '@I.rinaman',
            },
            {
                image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/made-me-smile-image8.jpg?height=281&width=267',
                username: '@vanessabarbosa8697',
            },
        ],
    },
    section_two: [
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchid-blooms-last-for-months-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchid Blooms Last<br> For Months</p>',
            product_desc: '<p>An orchid can bloom for<br>several months at a time and<br>continue to bloom year after <br>year when cared for properly.</p>',
            product_popup_data: {
                pop_header: '<p>Orchid Blooms Last For Months</p>',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchid-blooms-last-for-months-IC-banner-popup.jpg?height=571&width=440',
                pop_description: '<p class="description">An orchid can bloom for several months at a time and continue to bloom year after year when cared for properly. Seeing the flowers also serve as a daily reminder of beauty and love. An orchid’s lifespan combined with its beauty makes it a great way to add an affordable splash of color without spending time and money redecorating.</p>',
            },
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/18948x.jpg?height=378&width=345',
            product_link: '/classic-white-dendrobium-dish-garden-18948',
            product_name: 'Exotic Breeze™ Orchids',
            product_price: '$69.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',
        },
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchids-are-easy-to-care-for-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchids Are<br> Easy To Care For</p>',
            product_desc: '<p>Even in the winter, orchids<br>are low-maintenance.</p>',
            product_popup_data: {
                pop_header: '<p>Orchids Are Easy To Care For</p>',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchids-are-easy-to-care-for-IC-banner-popup.jpg?height=571&width=440',
                pop_description: '<p class="description">Even in the winter, orchids are low-maintenance. Give them indirect light, don’t water them too much, and keep them away from vents and they’ll last for years to come. Pay attention to these key factors to encourage orchids to rebloom.</p>',
            },
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/18953lx.jpg?height=378&width=345',
            product_link: '/silver-celebration-orchid-18953',
            product_name: 'Silver Celebration Orchids',
            product_price: '$109.99 - $119.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',
        },
        {
            product_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchid-come-in-almost-30000-varieties-IC-banner.jpg?height=571&width=440',
            product_link: '',
            product_name: '',
            product_price: '',
            product_Shipping: '',
            product_header: '<p>Orchids Come In<br> Almost 30,000<br> Varieties</p>',
            product_desc: '<p>Phalaenopsis, Moth Orchid, is<br>the most popular and easiest to<br>grow, and an especially good<br>choice for beginners.</p>',
            product_popup_data: {
                pop_header: '<p>Orchids Come In Almost 30,000 Varieties</p>',
                pop_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Popup/orchid-come-in-almost-30000-varieties-IC-banner-popup.jpg?height=571&width=440',
                pop_description: '<p class="description">Phalaenopsis, Moth Orchid, is the most popular and easiest to grow, and an especially good choice for beginners. Dendrobium orchids are also popular and like bright sunny windows. Cymbidium orchids are the showiest, displaying many large, colorful flowers in the darkest months of winter. No matter which you choose, you’ll be rewarded with a plant that blooms for months and lasts for years.</p>',
            },
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/40066x.jpg?height=378&width=345',
            product_link: '/lavendermistorchid-40066',
            product_name: 'Lovely Lavender Orchid',
            product_price: '$69.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_header: '',
            product_desc: '',
            product_popup_data: '',
        },
    ],
    orchid_care_banner: {
        orchid_care_image: 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2019/orchid/orchidLP/Desktop/orchids-care.jpg?height=571&width=440',
        orchid_care_content: '<p>There are so many benefits that come from having these plants<br/>in the home, so why not add an orchid to your collection?<br/>'
        + 'The hardest part will be choosing which one! </p>',
        orchid_care_link: '/orchidboutique/orchidcare-7914',
        orchid_care_link_text: 'Brown thumbs can easily turn green. Learn more about caring for orchids here.',
        orchid_care_alt_text: 'Orchid Care',
    },
    section_three: [
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/101697lnx.jpg?height=378&width=345',
            product_link: '/just-add-ice-phalaenopsis-orchid-101697',
            product_name: 'Violet Opulence Orchid',
            product_price: '$59.99 - $109.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/101696nlx.jpg?height=378&width=345',
            product_link: '/phalaenopsis-orchid-101696',
            product_name: 'Sunset Blooms Orchid™',
            product_price: '$59.99 - $109.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/18954lb_snipex.jpg?height=378&width=345',
            product_link: '/alluring-magenta-orchid-garden-18954',
            product_name: 'Alluring Magenta Orchid Garden',
            product_price: '$99.99 - $119.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/18946x.jpg?height=378&width=345',
            product_link: '/white-orchid-bamboo-garden-18946',
            product_name: 'White Orchid Bamboo Garden',
            product_price: '$144.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_background_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/snipes/save20p_z.gif?height=378&width=345',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/157179sx.jpg?height=378&width=345',
            product_link: '/plants-tranquil-succulent-orchid-garden-157179',
            product_name: 'Tranquil Succulent Orchid Garden',
            product_price: '$99.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/40062x.jpg?height=378&width=345',
            product_link: '/simple-elegant-orchid-garden-40062',
            product_name: 'English Orchid Garden',
            product_price: '$129.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/101700x.jpg?height=378&width=345',
            product_link: '/phalaenopsis-orchid-plant-101700',
            product_name: 'Artistic Splendor Orchid',
            product_price: '$69.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/101715x.jpg?height=378&width=345',
            product_link: '/isaac-mizrahi-phalaenopsis-orchid-101715',
            product_name: 'Simply Elegant Orchid Garden',
            product_price: '$129.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/157488lx.jpg?height=378&width=345',
            product_link: '/sunshine-blooms-orchid-157488',
            product_name: 'Sunshine Blooms Orchid',
            product_price: '$129.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/99200x.jpg?height=378&width=345',
            product_link: '/white-orchid-99200',
            product_name: 'White Phalaenopsis Orchid',
            product_price: '$49.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/101006mx.jpg?height=378&width=345',
            product_link: '/orchids/purple-mini-101006',
            product_name: 'Mini Purple Phalaenopsis Orchid in Tin',
            product_price: '$34.99 - $114.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/101173x.jpg?height=378&width=345',
            product_link: '/luxurious-orchid-garden-101173',
            product_name: 'Luxurious Orchid Garden',
            product_price: '$349.99',
            product_sale_price: 'SALE $244.99',
            product_Shipping: 'Shipped in a Gift Box',
            product_background_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/snipes/save30p_z.gif?height=378&width=345',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/157527lx.jpg?height=378&width=345',
            product_link: '/autumn-majesty-orchid-157527',
            product_name: 'Grand Majesty Orchid',
            product_price: '$129.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/139835nx.jpg?height=378&width=345',
            product_link: '/orchid-yankee-candle-scarf-139835',
            product_name: 'Orchid Candle with Pashmina Scarf',
            product_price: '$34.99',
            product_Shipping: 'Shipped in a Gift Box',
        },
        {
            product_image: 'https://cdn2.1800flowers.com/wcsstore/Flowers/images/catalog/105103x.jpg?height=378&width=345',
            product_link: '/hawaiian-hugs-kisses-105103',
            product_name: 'Hawaiian Hugs and Kisses',
            product_price: '$89.99',
            product_Shipping: 'Same-Day Local Florist Delivery',
        },
    ],
};

export default data;
